import cn from 'clsx';
import css from './product-ui-skeleton-text.module.css';
import type { ProductUISkeletonWidth } from '../product-ui-types';
type ProductUISkeletonTextProps = {
  width: ProductUISkeletonWidth;
  height?: '11' | '15';
  strikethrough?: boolean;
  context?: 'onLight' | 'onColor';
};
export function ProductUISkeletonText({
  height = '11',
  width,
  strikethrough,
  context = 'onLight'
}: ProductUISkeletonTextProps) {
  const customProps = {
    '--inlineSize': `${width}%`,
    '--blockSize': `${height}px`
  };
  return <span data-productui="skeleton-text" className={cn(css.skeletonText, css[context], {
    [css.striken]: strikethrough
  })} style={customProps} data-sentry-component="ProductUISkeletonText" data-sentry-source-file="product-ui-skeleton-text.tsx" />;
}