import { ActivityComplete } from '@doist/icons/product-ui';
import { Avatar } from '@doist/marketist';
import css from './product-ui-avatar.module.css';
const characters = {
  ana: `/static/product-ui/user-avatars/ana.png`,
  anais: `/static/product-ui/user-avatars/anais.png`,
  denise: `/static/product-ui/user-avatars/denise.png`,
  luis: `/static/product-ui/user-avatars/luis.png`,
  naomi: `/static/product-ui/user-avatars/naomi.png`,
  pawel: `/static/product-ui/user-avatars/pawel.png`,
  paul: `/static/product-ui/user-avatars/paul.png`,
  stephen: `/static/product-ui/user-avatars/stephen.png`,
  stijn: `/static/product-ui/user-avatars/stijn.png`,
  summer: `/static/product-ui/user-avatars/summer.png`,
  evert: `/static/product-ui/user-avatars/evert.png`,
  heather: `/static/product-ui/user-avatars/heather.png`,
  henning: `/static/product-ui/user-avatars/henning.png`,
  rain: `/static/product-ui/user-avatars/rain.png`
};
export type ProductUIAvatarProps = {
  src?: string;
  character?: keyof typeof characters;
  checked?: boolean;
  size?: number;
};
function ProductUIAvatar({
  src,
  character = 'denise',
  checked = false,
  size = 42
}: ProductUIAvatarProps) {
  const imgURL = src || `${process.env.NEXT_PUBLIC_BASE_URL}${characters[character]}`;
  return <div className={css.container} data-sentry-component="ProductUIAvatar" data-sentry-source-file="product-ui-avatar.tsx">
            <Avatar size={size} src={imgURL} alt="" data-sentry-element="Avatar" data-sentry-source-file="product-ui-avatar.tsx" />
            {checked ? <ActivityComplete className={css.checkmark} color="var(--display-onlight-color-green)" width="16" height="16" /> : null}
        </div>;
}
export { ProductUIAvatar };