import {
    type Currency,
    getCurrencyForCountry,
    isSupportedCurrency,
} from '@doist/currency-notations'

import { GetServerSidePropsContext } from 'next'
import { parseCookies, setCookie } from 'nookies'

import { BillingCycles } from '@/types/pricing'

export const COOKIE_KEY_CURRENCY = 'td_currency'
export const COOKIE_KEY_BILLING_CYCLE = 'td_desired_billing_cycle'
const COOKIE_KEY_FREE_PLAN = 'td_free_plan'

/*
 * @param {ctx} the context object receive from
 *              nextjs getInitialProps()
 */
export function getSessionCurrency(nextCtx: GetServerSidePropsContext): Currency {
    const cookies = parseCookies(nextCtx)
    const cookieCurrency = cookies[COOKIE_KEY_CURRENCY]?.toLowerCase()
    if (cookieCurrency && isSupportedCurrency(cookieCurrency)) {
        return cookieCurrency
    }

    const country = nextCtx?.req.headers?.[process.env.CLOUDFRONT_VIEWER_COUNTRY_HEADER] as string
    const currency = country ? getCurrencyForCountry(country) : 'usd'

    setSessionCurrency(currency)
    return currency
}

export function getSessionBillingCycle(nextCtx: GetServerSidePropsContext) {
    const cookies = parseCookies(nextCtx)
    if (cookies[COOKIE_KEY_BILLING_CYCLE]) {
        return cookies[COOKIE_KEY_BILLING_CYCLE]
    }
    return 'yearly'
}

export function setSessionBillingCycle(billingCycle: BillingCycles) {
    const isClientSide = typeof document === 'object' && typeof document.cookie === 'string'
    if (!isClientSide) {
        return false
    }

    setCookie(null, COOKIE_KEY_BILLING_CYCLE, billingCycle, { path: '/', sameSite: 'lax' })
}

/*
 * note: it curently only support calling on thext client-side context
 * as there seem to be some issue with nookies.setCookie() function
 * when used in the server side context
 */
export function setSessionCurrency(currency: Currency) {
    const isClientSide = typeof document === 'object' && typeof document.cookie === 'string'
    if (!isClientSide || !isSupportedCurrency(currency)) {
        return false
    }

    setCookie(null, COOKIE_KEY_CURRENCY, currency, { path: '/', sameSite: 'lax' })

    return true
}

/*
 * @param {ctx} the context object receive from
 *              nextjs getInitialProps()
 */
export function getSessionFreePlan(nextCtx: GetServerSidePropsContext) {
    const queryValue = nextCtx.query[COOKIE_KEY_FREE_PLAN] as string
    if (queryValue) {
        setCookie(nextCtx, COOKIE_KEY_FREE_PLAN, queryValue, {
            path: '/',
        })
        return queryValue
    }
    const cookies = parseCookies(nextCtx)
    if (cookies[COOKIE_KEY_FREE_PLAN]) {
        return cookies[COOKIE_KEY_FREE_PLAN]
    }
    return 'free_old'
}
