import { Children, type ReactNode } from 'react';
import { Box } from '@doist/marketist';
import cn from 'clsx';
import { ProductUIBoxShadow } from '../product-ui-box-shadow/product-ui-box-shadow';
import { ProductUIOverlay, type ProductUIOverlayProps } from '../product-ui-overlay/product-ui-overlay';
import css from './product-ui-app-window.module.css';
type ProductUIAppFrameProps = {
  view?: ReactNode;
  sidebar?: ReactNode;
  modal?: ReactNode;
  rightSidePanel?: ReactNode;
  showSidebar?: boolean;
  showOverlay?: boolean;
  className?: string;
  inlineSize?: string | number;
  blockSize?: string | number;
};
function ProductUIAppWindow({
  view,
  sidebar,
  modal,
  showSidebar = true,
  showOverlay = false,
  className,
  inlineSize = '100%',
  blockSize = '100%',
  rightSidePanel
}: ProductUIAppFrameProps) {
  const customProps = {
    '--window-inline-size': typeof inlineSize === 'number' ? `${inlineSize}px` : inlineSize,
    '--window-block-size': typeof blockSize === 'number' ? `${blockSize}px` : blockSize
  };
  return <OverlayWrapper condition={showOverlay} style={customProps} data-sentry-element="OverlayWrapper" data-sentry-component="ProductUIAppWindow" data-sentry-source-file="product-ui-app-window.tsx">
            <Box className={css.windowWrapper} data-sentry-element="Box" data-sentry-source-file="product-ui-app-window.tsx">
                <ProductUIBoxShadow data-sentry-element="ProductUIBoxShadow" data-sentry-source-file="product-ui-app-window.tsx" />

                <div className={cn(css.windowFrame, {
        [css.hideSidebar]: !showSidebar
      }, className)} style={customProps}>
                    {sidebar ? <div className={css.sidebar}>{sidebar}</div> : null}
                    {view}
                    {rightSidePanel ? <div className={css.rightSidePanel}>{rightSidePanel}</div> : null}
                    {modal ? <div className={css.modal}>{modal}</div> : null}
                </div>
            </Box>
        </OverlayWrapper>;
}
ProductUIAppWindow.UIViews = function ({
  children
}: {
  children: ReactNode[] | ReactNode;
}) {
  return <div className={css.views}>
            {Children.count(children) > 1 ? <>
                    {Children.map(children, (child, index) => <div key={index} className={css.view}>
                            {child}
                        </div>)}
                </> : <div className={css.view}>{children}</div>}
        </div>;
};
type OverlayWrapperProps = {
  condition: boolean;
  style?: React.CSSProperties;
} & ProductUIOverlayProps;
function OverlayWrapper({
  condition = false,
  children,
  ...rest
}: OverlayWrapperProps) {
  if (condition) {
    return <ProductUIOverlay className={css.overlay} {...rest}>
                {children}
            </ProductUIOverlay>;
  } else {
    return <>{children}</>;
  }
}
export { ProductUIAppWindow };