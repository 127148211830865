import gsap from 'gsap'
import { MotionPathPlugin } from 'gsap/dist/MotionPathPlugin'

import { EaseBase } from './ease'

import type { RefObject } from 'react'

type MoveElementToOptions = {
    fromElSelector?: string
    duration?: number
    opacity?: number
}

export const TweenTiming = {
    cursorMove: 0.6,
    typingSpeed: 0.04, // per character
    intentionHighlight: 0.3,
}

export function moveElementsTo(
    timeline: gsap.core.Timeline,
    scope: RefObject<HTMLElement>,
    elements: { element: string; target: string; options?: MoveElementToOptions }[],
) {
    for (const element of elements) {
        moveElementTo(timeline, scope, element.element, element.target, element.options)
    }
}

export function moveElementTo(
    timeline: gsap.core.Timeline,
    scope: RefObject<HTMLElement>,
    elementSelector: string,
    toElementSelector: string,
    options: MoveElementToOptions = {},
) {
    const selector = gsap.utils.selector(scope)
    const movingEl = selector<Element>(elementSelector)[0]
    const targetEl = selector<Element>(toElementSelector)[0]

    if (options.fromElSelector) {
        const fromEl = selector<HTMLElement>(options.fromElSelector)[0]
        gsap.set(movingEl, { x: fromEl?.offsetLeft ?? 0, y: fromEl?.offsetTop ?? 0 })
    }

    if (movingEl && targetEl) {
        // Calculate how for our element is from the target
        const elementDelta = MotionPathPlugin.getRelativePosition(
            movingEl,
            targetEl,
            [0.5, 0.5],
            [0.5, 0.5],
        )

        // Pause the timeline because we need to move the element in a Tween that the main timeline doesn't know about
        timeline.pause()

        gsap.to(movingEl, {
            opacity: options.opacity ?? 1,
            x: `+=${elementDelta.x}`,
            y: `+=${elementDelta.y}`,
            ease: EaseBase,
            duration: options.duration ?? 0.6,
            onComplete() {
                // Resume the main timeline now that the tween is complete
                timeline.resume()
            },
        })
    }
}

export function cursorClick(cursorSelector: string, fadeOut = false) {
    const timeline = gsap
        .timeline()
        .to(cursorSelector, { scale: 0.9, ease: EaseBase, duration: 0.05 })
        .to(cursorSelector, {
            delay: 0.2,
            ease: EaseBase,
            duration: 0.35,
            onComplete() {
                const cursorCompleteTimeline = gsap
                    .timeline()
                    .to(cursorSelector, { scale: 1, ease: EaseBase, duration: 0.15 })

                if (fadeOut) {
                    cursorCompleteTimeline.to(cursorSelector, {
                        opacity: 0,
                        ease: EaseBase,
                        duration: 0.15,
                    })
                }
            },
        })

    return timeline
}

export function cursorDragStart(cursorSelector: string) {
    return gsap.timeline().to(cursorSelector, { scale: 0.9, ease: EaseBase, duration: 0.05 })
}

export function cursorDragEnd(cursorSelector: string) {
    return gsap.timeline().to(cursorSelector, { scale: 1, ease: EaseBase, duration: 0.15 })
}
