'use client'
// eslint-disable-next-line no-restricted-imports
import { initReactI18next } from 'react-i18next'

import { defaultLocale, locales } from 'i18n/navigation'
import { use as i18next } from 'i18next'
import ICU from 'i18next-icu'
import resourcesToBackend from 'i18next-resources-to-backend'
import { useTranslation as useTranslationOrg } from 'next-i18next'

const runsOnServerSide = typeof window === 'undefined'

// `next-i18next` doesn't work in the App Router, however, they provide instructions on how
// to implement translations in the App Router: https://locize.com/blog/next-app-dir-i18n/,
// and this file follows them.

function initI18n(locale: string) {
    i18next(initReactI18next)
        .use(
            resourcesToBackend(
                (language: string, namespace: string) =>
                    import(`../../public/static/locales/${language}/${namespace}.json`),
            ),
        )
        .use(ICU)
        .init({
            lng: locale,
            fallbackLng: defaultLocale,

            ns: 'common',
            defaultNS: 'common',

            interpolation: {
                escapeValue: false,
            },
            preload: runsOnServerSide ? locales : [],
        })
}

export function useTranslation(lng: string, ns: string | string[]) {
    const ret = useTranslationOrg(ns)
    const { i18n } = ret

    // initialize within the `useTranslation` hook so access
    // to the language is available, but only if not already initialized
    if (!ret.ready) {
        initI18n(lng)
        // eslint-disable-next-line react-hooks/rules-of-hooks
        return useTranslationOrg(ns)
    }

    if (i18n && i18n.resolvedLanguage !== lng) {
        i18n.changeLanguage(lng)
    }

    return ret
}
