import { ChevronDown } from '@doist/icons/brand';
import { Button, Text } from '@doist/marketist';
import cn from 'clsx';
import { useTranslation } from 'next-i18next';
import { ProductUIAvatar } from '../product-ui-avatar/product-ui-avatar';
import { ProductUISkeletonText } from '../product-ui-skeleton-text/product-ui-skeleton-text';
import css from './product-ui-list-item.module.css';
import type { ProductUISkeletonWidth } from '../product-ui-types';
type PermissionListItemProps = {
  id?: string;
  label?: string;
  avatar: {
    imgSrc: string;
    checked?: boolean;
  };
  skeletonWidth?: ProductUISkeletonWidth;
};
function ProductUIPermissionListItem({
  id,
  label = '',
  avatar = {
    imgSrc: '',
    checked: false
  },
  skeletonWidth = '20'
}: PermissionListItemProps) {
  const {
    t
  } = useTranslation('productui');
  return <div id={id} className={cn(css.container, css.permissionType)} data-sentry-component="ProductUIPermissionListItem" data-sentry-source-file="product-ui-permission-list-item.tsx">
            <div className={css.left}>
                <ProductUIAvatar src={avatar.imgSrc} checked={avatar.checked} data-sentry-element="ProductUIAvatar" data-sentry-source-file="product-ui-permission-list-item.tsx" />
                <div className={css.stack}>
                    <Text size="minor-base" data-sentry-element="Text" data-sentry-source-file="product-ui-permission-list-item.tsx">{label}</Text>
                    <ProductUISkeletonText width={skeletonWidth} data-sentry-element="ProductUISkeletonText" data-sentry-source-file="product-ui-permission-list-item.tsx" />
                </div>
            </div>
            <div className={css.right}>
                <Button variant="quinary" endIcon={ChevronDown} data-sentry-element="Button" data-sentry-source-file="product-ui-permission-list-item.tsx">
                    {t('permissionListItem.member')}
                </Button>
            </div>
        </div>;
}
export { ProductUIPermissionListItem };