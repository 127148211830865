import { Assign, MoreOutline, Options } from '@doist/icons/product-ui';
import { useTranslation } from 'next-i18next';
import { ProductUIButton } from '../product-ui-button/product-ui-button';
import { ProductUIIconButton } from '../product-ui-icon-button/product-ui-icon-button';
import { ProductUIViewHeaderBase, type ProductUIViewHeaderBaseProps } from './product-ui-view-header-base';
export function ProductUIViewHeaderList({
  ...rest
}: ProductUIViewHeaderBaseProps) {
  const {
    t
  } = useTranslation('productui');
  return <ProductUIViewHeaderBase actions={<>
                    <ProductUIButton variant="quinary" startIcon={<Assign />}>
                        {t('share')}
                    </ProductUIButton>
                    <ProductUIButton variant="quinary" startIcon={<Options />}>
                        {t('view.view')}
                    </ProductUIButton>
                    <ProductUIIconButton icon={<MoreOutline />} />
                </>} {...rest} data-sentry-element="ProductUIViewHeaderBase" data-sentry-component="ProductUIViewHeaderList" data-sentry-source-file="product-ui-view-header-list.tsx" />;
}