import React, { type ReactNode } from 'react';
import cn from 'clsx';
import { ProductUIViewHeader, type ProductUIViewHeaderBaseProps } from '../product-ui-view-header/product-ui-view-header';
import { ProductUViewContext } from '../utils';
import { ProductUIViewBoardView, type ProductUIViewBoardViewProps } from './product-ui-view-board-view';
import { ProductUIViewCalendarView, type ProductUIViewCalendarViewProps } from './product-ui-view-calendar-view';
import { ProductUIViewSection, type ProductUIViewSectionProps } from './product-ui-view-section';
import { ProductUIViewSectionTitle, type ProductUIViewSectionTitleProps } from './product-ui-view-section-title';
import { ProductUIUpcomingViewDate, type ProductUIUpcomingViewDateProps, ProductUIUpcomingViewSubheader, type ProductUIUpcomingViewSubheaderProps } from './product-ui-view-upcoming-view';
import { ProductUIViewWeeklyCalendarView, type ProductUIViewWeeklyCalendarViewProps } from './product-ui-view-weekly-calendar-view';
import css from './product-ui-view.module.css';
import type { ProductUIViewVariant } from '../product-ui-types';
type ProductUIViewProps = {
  children: ReactNode;
  variant?: ProductUIViewVariant;
  className?: string;
};
export function ProductUIView({
  children,
  variant = 'list',
  className,
  ...rest
}: ProductUIViewProps) {
  return <ProductUViewContext.Provider value={variant} data-sentry-element="unknown" data-sentry-component="ProductUIView" data-sentry-source-file="product-ui-view.tsx">
            <div className={cn(css.viewContainer, className)} {...rest}>
                {children}
            </div>
        </ProductUViewContext.Provider>;
}
ProductUIView.Header = function (props: ProductUIViewHeaderBaseProps) {
  return <ProductUIViewHeader {...props} />;
};
ProductUIView.Section = function (props: ProductUIViewSectionProps) {
  return <ProductUIViewSection {...props} />;
};
ProductUIView.SectionTitle = function (props: ProductUIViewSectionTitleProps) {
  return <ProductUIViewSectionTitle {...props} />;
};
ProductUIView.BoardView = function (props: ProductUIViewBoardViewProps) {
  return <ProductUIViewBoardView {...props} />;
};
ProductUIView.UpcomingViewDate = function (props: ProductUIUpcomingViewDateProps) {
  return <ProductUIUpcomingViewDate {...props} />;
};
ProductUIView.UpcomingViewSubheader = function (props: ProductUIUpcomingViewSubheaderProps) {
  return <ProductUIUpcomingViewSubheader {...props} />;
};
ProductUIView.CalendarView = function (props: ProductUIViewCalendarViewProps) {
  return <ProductUIViewCalendarView {...props} />;
};
ProductUIView.WeeklyCalendarView = function (props: ProductUIViewWeeklyCalendarViewProps) {
  return <ProductUIViewWeeklyCalendarView {...props} />;
};