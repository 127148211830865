import { analyticsEvent } from 'services/analytics'

import type { Clarity } from 'types'

export function flagsTracking(
    flags: Record<string, boolean>,
    clarity: Clarity | undefined = window.clarity,
) {
    const entries = Object.entries(flags)

    if (entries.length === 0) {
        return
    }

    // Set custom Data Layer Variables for flags beginning with `test_`
    const abEntries = entries.filter(([key]) => key.startsWith('test_') && key !== 'test_ab')
    // Send event with all flag values
    const variantPairs = abEntries.map(([key, value]) => `${key}_${value ? 'b' : 'a'}`)

    variantPairs.forEach((variant) => {
        analyticsEvent('user_test', { Variant: variant })
        clarity?.('set', 'experiment', variant)
    })
}
