import { cloneElement, type HTMLAttributes } from 'react';
import { ChevronLeft, ChevronRight } from '@doist/icons/product-ui';
import { ProductUIIconButton, type ProductUIIconButtonProps } from '../product-ui-icon-button/product-ui-icon-button';
import css from './product-ui-outline-button-group.module.css';
export type ProductUIOutlineButtonGroupProps = {
  startIcon: React.ReactNode;
  button: React.ReactElement;
  endIcon: React.ReactNode;
} & HTMLAttributes<HTMLDivElement>;
function ProductUIOutlineButtonGroup({
  startIcon,
  button,
  endIcon,
  ...rest
}: ProductUIOutlineButtonGroupProps) {
  const Button = cloneElement(button, {
    ...button.props,
    variant: 'quinary'
  });
  return <div className={css.outlineButtonGroup} {...rest} data-sentry-component="ProductUIOutlineButtonGroup" data-sentry-source-file="product-ui-outline-button-group.tsx">
            {startIcon}
            <div className={css.separator} />
            <div className={css.buttonWrapper}>{Button}</div>
            <div className={css.separator} />
            {endIcon}
        </div>;
}
ProductUIOutlineButtonGroup.IconButton = function ({
  direction,
  ...rest
}: {
  direction: 'left' | 'right';
} & Omit<ProductUIIconButtonProps, 'icon'>) {
  const Icon = direction === 'left' ? ChevronLeft : ChevronRight;
  return <ProductUIIconButton size="base" icon={<Icon />} {...rest} />;
};
export { ProductUIOutlineButtonGroup };