import Script from 'next/script';
import { useTranslation } from 'next-i18next';
declare global {
  interface Window {
    onYouTubeIframeAPIReady: () => void;
  }
}
type YouTubeVideoProps = {
  videoID: string;
  className?: string;
  onReady?: (yt: YT.PlayerEvent) => void;
  // https://developers.google.com/youtube/player_parameters#Parameters
  playerVars?: YT.PlayerVars;
};
function generatePlayerParams(playerVars: YT.PlayerVars) {
  const allPlayerVars = Object.keys(playerVars).reduce((combinedParams, key) => {
    const value = (playerVars as Record<string, unknown>)[key];
    return Object.assign(combinedParams, {
      [key]: value?.toString()
    });
  }, {} as Record<string, string>);
  return new URLSearchParams(allPlayerVars);
}
export function YouTubeVideo({
  videoID,
  className,
  onReady = () => {
    /* noop */
  },
  playerVars = {}
}: YouTubeVideoProps) {
  const {
    i18n
  } = useTranslation('common');
  const defaultPlayerVars = {
    enablejsapi: 1,
    // enabled
    origin: process.env.NEXT_PUBLIC_BASE_URL,
    hl: i18n.language,
    playsinline: 1,
    // inline
    modestbranding: 1,
    // modest,
    rel: 0,
    // Show same channel videos
    mute: 1,
    // muted
    autoplay: 1,
    // true
    loop: 1,
    // true
    playlist: videoID,
    cc_lang_pref: i18n.language,
    cc_load_policy: 1 // enable closed captions
  };
  const playerParams = generatePlayerParams({
    ...defaultPlayerVars,
    ...playerVars
  });
  const ytUrl = `https://www.youtube.com/embed/${videoID}?${playerParams}`;
  function onLoadHandler() {
    new Promise<YT.Player>(resolve => {
      if (window.YT?.Player && window.YT.Player instanceof Function) {
        resolve(window.YT.Player);
        return;
      }
      const previous = window.onYouTubeIframeAPIReady;
      window.onYouTubeIframeAPIReady = () => {
        if (previous) {
          previous();
        }
        resolve(window.YT.Player);
      };
    }).then(Player => {
      // Reason: the types aren't matching what I'm seeing in the browser
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      new Player(videoID, {
        events: {
          onReady(e: YT.PlayerEvent) {
            onReady(e);
          }
        }
      });
    });
  }
  return <>
            <Script id="youtube-iframe-api" src="https://www.youtube.com/iframe_api" strategy="afterInteractive" onLoad={onLoadHandler} data-sentry-element="Script" data-sentry-source-file="YouTubeVideo.tsx" />

            <iframe title="YouTube Video" id={videoID} src={ytUrl} allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" frameBorder="0" allowFullScreen className={className} />
        </>;
}