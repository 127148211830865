import { createContext } from 'react'

import type { ProjectColors } from './product-ui-list-item/types'
import type { ProductUIViewVariant } from './product-ui-types'

type ColorMap = { [color in ProjectColors]: string }

export function mapColorNameToCustomProp(name: ProjectColors, overrides: Partial<ColorMap> = {}) {
    const colorMap: ColorMap = {
        yellow: 'var(--display-onlight-color-yellow)',
        limeGreen: 'var(--productui-meta-lime-green)',
        green: 'var(--display-onlight-color-green)',
        red: 'var(--productui-meta-red)',
        blue: 'var(--productui-meta-blue)',
        orange: 'var(--productui-meta-orange)',
        purple: 'var(--productui-meta-purple)',
        lavender: 'var(--productui-meta-lavender)',
        grey: 'var(--display-onlight-secodary)',
        greyTertiary: 'var(--display-onlight-tertiary)',
        theme: 'var(--productui-primary-theme-color)',
        seaSalt: 'var(--display-onlight-color-sea-salt)',
        fuschia: 'var(--display-onlight-color-fuschia)',
        ...overrides,
    }

    return colorMap[name]
}

export const ProductUViewContext = createContext<ProductUIViewVariant>('list')
