import { createContext, useContext } from 'react';
type IconRadioTilecontext = {
  name: string;
  currentValue: string;
  onChange: (value: string) => void;
};
export const IconRadioTileContext = createContext<IconRadioTilecontext | undefined>(undefined);
export function useIconRadioTileContext() {
  const ctx = useContext(IconRadioTileContext);
  if (!ctx) {
    throw new Error('useIconRadioTileContext must be used within a IconRadioTileGroup');
  }
  return ctx;
}