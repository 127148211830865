import cn from 'clsx';
import css from './product-ui-overlay.module.css';
export type ProductUIOverlayProps = {
  children: React.ReactNode;
  className?: string;
};
export function ProductUIOverlay({
  children,
  className,
  ...rest
}: ProductUIOverlayProps) {
  return <div className={cn(css.overlay, className)} {...rest} data-sentry-component="ProductUIOverlay" data-sentry-source-file="product-ui-overlay.tsx">
            {children}
        </div>;
}