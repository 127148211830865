import { Image, Text } from '@doist/marketist';
import cn from 'clsx';
import { getDefaultAvatar } from '../consts';
import { useSidebarContext } from '../product-ui-sidebar/product-ui-sidebar';
import { ProductUISkeletonText } from '../product-ui-skeleton-text/product-ui-skeleton-text';
import css from './product-ui-list-item.module.css';
import type { ProductUISkeletonWidth } from '../product-ui-types';
type SideBarHeaderItemProps = {
  id?: string;
  label?: string;
  hovered?: boolean;
  active?: boolean;
  skeletonWidth?: ProductUISkeletonWidth;
  abstracted?: boolean;
  icon?: 'personal' | 'team';
  size?: 'base' | 'sm';
};
function ProductUISidebarHeaderItem({
  id,
  label = '',
  hovered = false,
  active = false,
  abstracted = false,
  skeletonWidth = '100',
  icon,
  size = 'base',
  ...rest
}: SideBarHeaderItemProps) {
  const withinSidebar = useSidebarContext();
  const avatarSrc = getDefaultAvatar(icon !== 'personal');
  const avatarSize = size === 'base' ? 22 : 18;
  const textSize = size === 'base' ? 'minor-base' : 'minor-xs';
  return <div id={id} className={cn(css.container, css.sideBarType, {
    [css.sidebarListItem]: withinSidebar,
    [css.hovered]: hovered,
    [css.active]: active
  })} {...rest} data-sentry-component="ProductUISidebarHeaderItem" data-sentry-source-file="product-ui-sidebar-header-item.tsx">
            <div className={css.left}>
                {icon ? <Image src={avatarSrc} width={avatarSize} height={avatarSize} alt="" /> : null}
                {abstracted ? <div className={css.abstract}>
                        <ProductUISkeletonText width={skeletonWidth} />
                    </div> : <Text weight="semibold" size={textSize}>
                        {label}
                    </Text>}
            </div>
        </div>;
}
export { ProductUISidebarHeaderItem };