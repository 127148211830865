'use client';

import { createContext, useState } from 'react';
import type { Currency } from '@doist/currency-notations';
import type { BillingCycles, PricingModel } from 'types/pricing';
import { setSessionBillingCycle, setSessionCurrency } from '@/services/payment/session';
export type PricingContextProviderProps = {
  model: PricingModel;
  currency: Currency;
  billingCycle: BillingCycles;
  children: React.ReactNode;
};

/** PricingContext is a context where all the information needed to render the prices. */
export const PricingContext = createContext({} as Omit<PricingContextProviderProps, 'children'> & {
  update: (keyval: Partial<PricingContextProviderProps>) => void;
});
export function PricingContextProvider({
  model,
  children,
  currency,
  billingCycle
}: PricingContextProviderProps) {
  const [context, setContext] = useState({
    model,
    currency,
    billingCycle
  });
  function update(keyval: Partial<PricingContextProviderProps>) {
    if (keyval.billingCycle) {
      setSessionBillingCycle(keyval.billingCycle);
    }
    if (keyval.currency) {
      setSessionCurrency(keyval.currency);
    }
    setContext({
      ...context,
      ...keyval
    });
  }
  return <PricingContext.Provider value={{
    ...context,
    update
  }} data-sentry-element="unknown" data-sentry-component="PricingContextProvider" data-sentry-source-file="pricing-context.tsx">{children}</PricingContext.Provider>;
}