import { type ReactNode, useEffect, useId, useMemo, useState } from 'react';
import { ChevronDown } from '@doist/icons/brand';
import { Text } from '@doist/marketist';
import cn from 'clsx';
import { ProductUISkeletonText } from 'components/product-ui/product-ui-skeleton-text/product-ui-skeleton-text';
import { useTranslation } from 'next-i18next';
import css from './product-ui-weekly-calendar-day.module.css';
const hours = Array.from({
  length: 24
}, (_, i) => i);
const weekDays = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
export type ProductUIWeeklyCalendarDayProps = {
  children?: ReactNode;
};
export function ProductUIWeeklyCalendarDay({
  children,
  ...rest
}: ProductUIWeeklyCalendarDayProps) {
  return <div className={css.weeklyCalendarDay} {...rest} data-sentry-component="ProductUIWeeklyCalendarDay" data-sentry-source-file="product-ui-weekly-calendar-day.tsx">
            {hours.map(hour => <div key={`hour-${hour}`} data-productui={`hour-${hour}`} className={css.hour} />)}
            {children}
        </div>;
}
export function ProductUIWeeklyCalendarDayTimes() {
  const {
    i18n
  } = useTranslation('product-ui');
  const id = useId();
  const amPmFormat = i18n.language === 'en';
  /** Compute times on client side only to avoid hydration errors */
  const [times, setTimes] = useState<string[]>([]);
  useEffect(() => {
    const newTimes = [];
    for (const hour of hours) {
      newTimes.push(new Date(0, 0, 0, hour, 0, 0).toLocaleTimeString(i18n.language, {
        hour: 'numeric',
        minute: amPmFormat ? undefined : '2-digit'
      }));
    }
    setTimes(newTimes);
  }, [i18n.language, amPmFormat]);
  return <div className={css.weeklyCalendarDayTimes} data-sentry-component="ProductUIWeeklyCalendarDayTimes" data-sentry-source-file="product-ui-weekly-calendar-day.tsx">
            {times.map((time, index) => <div key={`time-${index}-${id}`} className={css.hour}>
                    <Text key={`hour-${index}`} size="body-2xs" className={css.legend}>
                        {time}
                    </Text>
                </div>)}
        </div>;
}
export function ProductUIWeeklyCalendarDayDates({
  dayCount,
  currentDayPosition,
  abstractedDate
}: {
  dayCount: number;
  currentDayPosition: number;
  abstractedDate?: boolean;
}) {
  const {
    t
  } = useTranslation('productui');
  const id = useId();
  const days = useMemo(() => {
    const today = new Date();
    return Array.from({
      length: dayCount
    }, (_, i) => {
      const date = new Date();
      date.setDate(today.getDate() - currentDayPosition + i);
      return date;
    });
  }, [currentDayPosition, dayCount]);
  return <div className={css.weeklyCalendarDayDates} data-sentry-component="ProductUIWeeklyCalendarDayDates" data-sentry-source-file="product-ui-weekly-calendar-day.tsx">
            {days.map(date => <Text key={`date-${id}-${date.getTime()}`} size="body-2xs" className={cn(css.date, css.legend, {
      [css.selected]: isSameDay(date, new Date())
    })}>
                    {abstractedDate ? <ProductUISkeletonText width={25} /> : <>
                            {t(`dates.${weekDays[date.getDay() % weekDays.length]}`)}
                            <span> {date.getDate()}</span>
                        </>}
                </Text>)}
        </div>;
}
export function ProductUIWeeklyCalendarFold({
  foldHour
}: {
  foldHour: number;
}) {
  const {
    i18n
  } = useTranslation('productui');
  const minutes = (foldHour - Math.floor(foldHour)) * 60;
  const [time, setTime] = useState('');
  useEffect(() => setTime(new Date(0, 0, 0, foldHour, minutes, 0).toLocaleTimeString(i18n.language, {
    hour: 'numeric',
    minute: '2-digit'
  })), [foldHour, minutes, i18n.language]);
  return <div className={css.weeklyCalendarViewFold} style={{
    '--fold-hour': foldHour
  }} data-sentry-component="ProductUIWeeklyCalendarFold" data-sentry-source-file="product-ui-weekly-calendar-day.tsx">
            <div className={css.time}>
                <Text size="body-2xs" weight="semibold" data-sentry-element="Text" data-sentry-source-file="product-ui-weekly-calendar-day.tsx">
                    {time}
                </Text>
            </div>
            <div className={css.fold}>
                <div className={css.highlightedFold} />
                <div className={css.highlightedCircle} />
            </div>
        </div>;
}
export function ProductUIWeeklyCalendarAllDays({
  children
}: {
  children: ReactNode;
}) {
  const {
    t
  } = useTranslation('productui');
  return <div className={css.weeklyCalendarAllDays} data-sentry-component="ProductUIWeeklyCalendarAllDays" data-sentry-source-file="product-ui-weekly-calendar-day.tsx">
            <div className={cn(css.allDayLegend, css.legend)}>
                <Text size="body-2xs" data-sentry-element="Text" data-sentry-source-file="product-ui-weekly-calendar-day.tsx">{t('allDay')}</Text>
                <ChevronDown width={16} height={16} data-sentry-element="ChevronDown" data-sentry-source-file="product-ui-weekly-calendar-day.tsx" />
            </div>
            {children}
        </div>;
}
function isSameDay(date1: Date, date2: Date) {
  return date1.getFullYear() === date2.getFullYear() && date1.getMonth() === date2.getMonth() && date1.getDate() === date2.getDate();
}