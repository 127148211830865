import { type Dispatch, useReducer } from 'react';
import { Assign, Comment, DateDefault, DateMove, DateNone, Edit, MoreOutline, Options, Sofa, Sun } from '@doist/icons/product-ui';
import { Box, Divider, Stack } from '@doist/marketist';
import { useGSAP } from '@gsap/react';
import { ProductUIAppWindow } from 'components/product-ui/product-ui-app-window/product-ui-app-window';
import { ProductUIButton } from 'components/product-ui/product-ui-button/product-ui-button';
import { ProductUIContextProvider, useProductUIContext } from 'components/product-ui/product-ui-context';
import { ProductUIContextMenu } from 'components/product-ui/product-ui-context-menu/product-ui-context-menu';
import { ProductUICursor } from 'components/product-ui/product-ui-cursor/product-ui-cursor';
import { ProductUIFrame } from 'components/product-ui/product-ui-frame/product-ui-frame';
import { ProductUIIconButton } from 'components/product-ui/product-ui-icon-button/product-ui-icon-button';
import { ProductUIBasicListItem } from 'components/product-ui/product-ui-list-item';
import { ProductUISceneContainer, ProductUISceneLayer } from 'components/product-ui/product-ui-scene-container/product-ui-scene-container';
import { TemplateSidebarStandard } from 'components/product-ui/product-ui-sidebar/product-ui-sidebar-templates';
import { ProductUITask } from 'components/product-ui/product-ui-task/product-ui-task';
import { ProductUITaskAttributeButton } from 'components/product-ui/product-ui-task-attribute-button/product-ui-task-attribute-button';
import { ProductUITaskCircle } from 'components/product-ui/product-ui-task-circle/product-ui-task-circle';
import { ProductUIView } from 'components/product-ui/product-ui-view/product-ui-view';
import gsap from 'gsap';
import { Flip } from 'gsap/dist/Flip';
import { useTranslation } from 'next-i18next';
import { EaseBase, EaseInOut } from '../ease';
import { cursorClick, moveElementTo } from '../tweens';
import css from './today-view-sequence.module.css';
export function TodayViewSequence() {
  return <ProductUIContextProvider data-sentry-element="ProductUIContextProvider" data-sentry-component="TodayViewSequence" data-sentry-source-file="today-view-sequence.tsx">
            <ProductUIFrame data-sentry-element="ProductUIFrame" data-sentry-source-file="today-view-sequence.tsx">
                <TodayViewSequenceAnimation data-sentry-element="TodayViewSequenceAnimation" data-sentry-source-file="today-view-sequence.tsx" />
            </ProductUIFrame>
        </ProductUIContextProvider>;
}
type TaskState = 'normal' | 'hovered' | 'checked';
type Task = 'dentist' | 'shortcut' | 'yoga' | 'bread';
type ReducerState = {
  viewButtonHovered: boolean;
  viewButtonVariant: 'default' | 'priority';
  dentistTaskState: TaskState;
  dentistCircleState: TaskState;
  shortcutTaskState: TaskState;
  shortcutCircleState: TaskState;
  yogaTaskState: TaskState;
  yogaCircleState: TaskState;
  breadTaskState: TaskState;
  breadCircleState: TaskState;
  taskAttributeHover: boolean;
  todayHover: boolean;
};
const animationState: ReducerState = {
  viewButtonHovered: false,
  viewButtonVariant: 'default',
  dentistTaskState: 'normal',
  dentistCircleState: 'normal',
  shortcutTaskState: 'normal',
  shortcutCircleState: 'normal',
  yogaTaskState: 'normal',
  yogaCircleState: 'normal',
  breadTaskState: 'normal',
  breadCircleState: 'normal',
  taskAttributeHover: false,
  todayHover: false
};
type AnimationAction = {
  type: 'viewButtonHovered';
  hover: boolean;
} | {
  type: 'viewButtonPriority';
} | {
  type: 'taskState';
  task: Task;
  state: TaskState;
} | {
  type: 'taskCircleState';
  task: Task;
  state: TaskState;
} | {
  type: 'taskAttributeHover';
  hover: boolean;
} | {
  type: 'todayHovered';
  hover: boolean;
};
function animationReducer(state: typeof animationState, action: AnimationAction): ReducerState {
  switch (action.type) {
    case 'viewButtonHovered':
      return {
        ...state,
        viewButtonHovered: action.hover
      };
    case 'viewButtonPriority':
      return {
        ...state,
        viewButtonVariant: 'priority'
      };
    case 'taskState':
      if (action.task === 'dentist') {
        return {
          ...state,
          dentistTaskState: action.state
        };
      } else if (action.task === 'shortcut') {
        return {
          ...state,
          shortcutTaskState: action.state
        };
      } else if (action.task === 'yoga') {
        return {
          ...state,
          yogaTaskState: action.state
        };
      } else if (action.task === 'bread') {
        return {
          ...state,
          breadTaskState: action.state
        };
      }
      return state;
    case 'taskCircleState':
      if (action.task === 'dentist') {
        return {
          ...state,
          dentistCircleState: action.state
        };
      } else if (action.task === 'shortcut') {
        return {
          ...state,
          shortcutCircleState: action.state
        };
      } else if (action.task === 'yoga') {
        return {
          ...state,
          yogaCircleState: action.state
        };
      } else if (action.task === 'bread') {
        return {
          ...state,
          breadCircleState: action.state
        };
      }
      return state;
    case 'taskAttributeHover':
      return {
        ...state,
        taskAttributeHover: action.hover
      };
    case 'todayHovered':
      return {
        ...state,
        todayHover: action.hover
      };
    default:
      return state;
  }
}
const cursorSelector = '[data-productui=cursor]';
function TodayViewSequenceAnimation() {
  const {
    timeline,
    scope
  } = useProductUIContext();
  const {
    t
  } = useTranslation('productui');
  const [state, dispatch] = useReducer(animationReducer, animationState);
  useGSAP(() => {
    if (!timeline) {
      return;
    }
    const viewButtonSelector = '[data-button=view]';
    const taskCircleSelector = '[data-productui=taskcircle]';
    const dateAttributeSelector = '[data-iconbutton=date]';
    const contextMenuSelector = '[data-productui=context-menu]';
    const contextMenuTodaySelector = '[data-contextmenu=today]';
    const taskSectionSelector = '[data-productui=task-section]';
    const inboxZeroSectionSelector = '[data-productui=inbox-zero-section]';
    const inboxZeroTextSelector = '[data-productui=empty-section-text]';
    const dentistTaskSelector = '[data-productui-task=dentist]';
    const shortcutTaskSelector = '[data-productui-task=shortcut]';
    const yogaTaskSelector = '[data-productui-task=yoga]';
    const breadTaskSelector = '[data-productui-task="bread"]';
    timeline.set(cursorSelector, {
      inset: '50%'
    }).call(moveElementTo, [timeline, scope, cursorSelector, viewButtonSelector]).call(() => {
      dispatch({
        type: 'viewButtonHovered',
        hover: true
      });
    }).add(cursorClick(cursorSelector)).call(() => {
      dispatch({
        type: 'viewButtonPriority'
      });
    }).call(() => {
      const selector = gsap.utils.selector(scope);
      // Pause the main timeline while we transition
      timeline.pause();

      // Store the initial state of the task containers
      const startingState = Flip.getState('[data-productui="task-container"]');

      // Have them update to where they'll end up by adding the ordered class
      selector(taskSectionSelector)[0]?.classList.add(css.taskSectionOrdered);

      // Flip will move them back immediately to their starting state
      // then transition them to their new state
      Flip.from(startingState, {
        absolute: true,
        duration: 1.1,
        ease: EaseBase,
        onComplete: () => {
          // Continue the timeline
          timeline.resume();
        }
      });
    }).call(() => {
      dispatch({
        type: 'viewButtonHovered',
        hover: false
      });
    }).call(moveElementTo, [timeline, scope, cursorSelector, `${dentistTaskSelector} ${taskCircleSelector}`], '<').add(gsap.timeline().delay(1)).add(completeTask(dentistTaskSelector, 'dentist', dispatch)).add(completeTask(shortcutTaskSelector, 'shortcut', dispatch)).add(completeTask(yogaTaskSelector, 'yoga', dispatch)).call(() => {
      dispatch({
        type: 'taskState',
        task: 'bread',
        state: 'hovered'
      });
      dispatch({
        type: 'taskCircleState',
        task: 'bread',
        state: 'hovered'
      });
    }).add(gsap.timeline().delay(0.5)).call(() => {
      dispatch({
        type: 'taskCircleState',
        task: 'bread',
        state: 'normal'
      });
    }).call(moveElementTo, [timeline, scope, cursorSelector, `${breadTaskSelector} ${dateAttributeSelector}`], '<').call(() => {
      dispatch({
        type: 'taskAttributeHover',
        hover: true
      });
    }).add(function visible() {
      gsap.timeline().set(contextMenuSelector, {
        visibility: 'visible'
      });
    }, '<').add(cursorClick(cursorSelector)).call(moveElementTo, [timeline, scope, cursorSelector, contextMenuTodaySelector]).call(() => {
      dispatch({
        type: 'todayHovered',
        hover: true
      });
    }).add(cursorClick(cursorSelector, true)).to(contextMenuSelector, {
      opacity: 0,
      ease: EaseBase,
      duration: 0.5
    }).add(animateTaskOut(breadTaskSelector, true)).set(taskSectionSelector, {
      display: 'none'
    }, '>').set(inboxZeroSectionSelector, {
      display: 'flex',
      opacity: 0
    }).delay(0.5).from([inboxZeroSectionSelector, inboxZeroTextSelector], {
      opacity: 0,
      y: '+=20',
      stagger: 0.2,
      ease: EaseBase,
      duration: 2
    });
  }, {
    dependencies: [timeline],
    scope
  });
  return <ProductUISceneContainer className={css.sequenceContainer} data-productui="container" backgroundVariant="wave-one" data-sentry-element="ProductUISceneContainer" data-sentry-component="TodayViewSequenceAnimation" data-sentry-source-file="today-view-sequence.tsx">
            <ProductUISceneLayer position="bottomLeft" data-sentry-element="ProductUISceneLayer" data-sentry-source-file="today-view-sequence.tsx">
                <ProductUIAppWindow className={css.appWindow} inlineSize={800} showSidebar sidebar={<TemplateSidebarStandard />} view={<ProductUIAppWindow.UIViews>
                            <ProductUIView>
                                <ProductUIView.Header title={t('taskAttributePill.today')} breadcrumbs={[]} actions={<>
                                            <ProductUIButton variant="quinary" startIcon={<Assign />}>
                                                {t('share')}
                                            </ProductUIButton>
                                            <ProductUIButton variant="quinary" startIcon={<Options />} data-button="view" hovered={state.viewButtonHovered}>
                                                {state.viewButtonVariant === 'priority' ? t('view.sortedPriority') : t('view.view')}
                                            </ProductUIButton>
                                            <ProductUIIconButton icon={<MoreOutline />} />
                                        </>} />
                                <ProductUIView.Section data-productui="task-section">
                                    <div data-productui-task="yoga" data-productui="task-container">
                                        <ProductUITask taskCircleSection={<ProductUITaskCircle priority="p3" data-productui="taskcircle" checked={state.yogaCircleState === 'checked'} hovered={state.yogaCircleState === 'hovered'} />} projectSection={<ProductUITask.Project width="20" iconColor="red" />} hoveredSection={<TaskHoverSection />} hovered={state.yogaTaskState === 'hovered'} data-productui="task">
                                            {t('sequence.todayView.yoga')}
                                        </ProductUITask>
                                        <Divider />
                                    </div>
                                    <div data-productui-task="shortcut" data-productui="task-container">
                                        <ProductUITask taskCircleSection={<ProductUITaskCircle priority="p2" data-productui="taskcircle" checked={state.shortcutCircleState === 'checked'} hovered={state.shortcutCircleState === 'hovered'} />} projectSection={<ProductUITask.Project iconColor="limeGreen" />} hoveredSection={<TaskHoverSection />} hovered={state.shortcutTaskState === 'hovered'} data-productui="task">
                                            {t('sequence.todayView.updateMLS')}
                                        </ProductUITask>
                                        <Divider />
                                    </div>
                                    <div data-productui-task="bread" data-productui="task-container">
                                        <ProductUITask taskCircleSection={<ProductUITaskCircle priority="p4" data-productui="taskcircle" checked={state.breadCircleState === 'checked'} hovered={state.breadCircleState === 'hovered'} />} projectSection={<ProductUITask.Project width="20" iconColor="yellow" />} hoveredSection={<TaskHoverSection hovered={state.taskAttributeHover} contextMenuHovered={state.todayHover} contextMenu />} hovered={state.breadTaskState === 'hovered'} data-productui="task">
                                            {t('sequence.todayView.buyBread')}
                                        </ProductUITask>
                                        <Divider />
                                    </div>
                                    <div data-productui-task="dentist" data-productui="task-container">
                                        <ProductUITask taskCircleSection={<ProductUITaskCircle priority="p1" data-productui="taskcircle" checked={state.dentistCircleState === 'checked'} hovered={state.dentistCircleState === 'hovered'} />} projectSection={<ProductUITask.Project width="20" iconColor="blue" />} hoveredSection={<TaskHoverSection />} hovered={state.dentistTaskState === 'hovered'} data-productui="task">
                                            {t('sequence.todayView.dentist')}
                                        </ProductUITask>
                                        <Divider />
                                    </div>
                                </ProductUIView.Section>
                                <ProductUIView.Section variant="empty" data-hide data-productui="inbox-zero-section">
                                    {t('sequence.todayView.todoistZero')}
                                </ProductUIView.Section>
                            </ProductUIView>
                        </ProductUIAppWindow.UIViews>} data-sentry-element="ProductUIAppWindow" data-sentry-source-file="today-view-sequence.tsx" />
            </ProductUISceneLayer>
            <ProductUICursor id="productUICursor" data-productui="cursor" data-sentry-element="ProductUICursor" data-sentry-source-file="today-view-sequence.tsx" />
        </ProductUISceneContainer>;
}
function TaskHoverSection({
  hovered,
  contextMenu,
  contextMenuHovered
}: {
  hovered?: boolean;
  contextMenu?: boolean;
  contextMenuHovered?: boolean;
}) {
  return <ProductUITask.AttributeButtonContainer data-productui="task-attributes" data-sentry-element="unknown" data-sentry-component="TaskHoverSection" data-sentry-source-file="today-view-sequence.tsx">
            <ProductUITaskAttributeButton size="lg" data-sentry-element="ProductUITaskAttributeButton" data-sentry-source-file="today-view-sequence.tsx">
                <Edit data-sentry-element="Edit" data-sentry-source-file="today-view-sequence.tsx" />
            </ProductUITaskAttributeButton>
            <Box className={css.contextContainer} data-sentry-element="Box" data-sentry-source-file="today-view-sequence.tsx">
                <ProductUITaskAttributeButton size="lg" data-iconbutton="date" hovered={hovered} data-sentry-element="ProductUITaskAttributeButton" data-sentry-source-file="today-view-sequence.tsx">
                    <DateDefault data-sentry-element="DateDefault" data-sentry-source-file="today-view-sequence.tsx" />
                </ProductUITaskAttributeButton>
                {contextMenu ? <ContextMenu hovered={contextMenuHovered} /> : null}
            </Box>
            <ProductUITaskAttributeButton size="lg" data-sentry-element="ProductUITaskAttributeButton" data-sentry-source-file="today-view-sequence.tsx">
                <Comment data-sentry-element="Comment" data-sentry-source-file="today-view-sequence.tsx" />
            </ProductUITaskAttributeButton>
        </ProductUITask.AttributeButtonContainer>;
}
function ContextMenu({
  hovered
}: {
  hovered?: boolean;
}) {
  const {
    t
  } = useTranslation('productui');
  return <ProductUIContextMenu data-productui="context-menu" className={css.contextMenu} title={t('taskAttributePill.dueDate')} data-sentry-element="ProductUIContextMenu" data-sentry-component="ContextMenu" data-sentry-source-file="today-view-sequence.tsx">
            <Stack gap="2" data-sentry-element="Stack" data-sentry-source-file="today-view-sequence.tsx">
                <ProductUIBasicListItem icon={Sun} iconColor="orange" label={t('taskAttributePill.tomorrow')} data-contextmenu="today" hovered={hovered} data-sentry-element="ProductUIBasicListItem" data-sentry-source-file="today-view-sequence.tsx" />
                <ProductUIBasicListItem icon={Sofa} iconColor="blue" label={t('taskAttributePill.thisWeekend')} data-sentry-element="ProductUIBasicListItem" data-sentry-source-file="today-view-sequence.tsx" />
                <ProductUIBasicListItem icon={DateMove} iconColor="purple" label={t('taskAttributePill.nextWeek')} data-sentry-element="ProductUIBasicListItem" data-sentry-source-file="today-view-sequence.tsx" />
                <ProductUIBasicListItem icon={DateNone} iconColor="grey" label={t('taskAttributePill.noDate')} data-sentry-element="ProductUIBasicListItem" data-sentry-source-file="today-view-sequence.tsx" />
            </Stack>
        </ProductUIContextMenu>;
}
function completeTask(taskSelector: string, task: Task, dispatch: Dispatch<AnimationAction>) {
  const timeline = gsap.timeline();
  return timeline.call(() => {
    dispatch({
      type: 'taskState',
      task,
      state: 'hovered'
    });
    dispatch({
      type: 'taskCircleState',
      task,
      state: 'hovered'
    });
  }).add(cursorClick(cursorSelector)).call(() => {
    dispatch({
      type: 'taskCircleState',
      task,
      state: 'checked'
    });
  }).add(animateTaskOut(taskSelector));
}
function animateTaskOut(taskSelector: string, slideOut = false) {
  return gsap.timeline().to(taskSelector, {
    opacity: 0,
    height: !slideOut ? 0 : undefined,
    x: slideOut ? '+=50' : undefined,
    ease: slideOut ? EaseInOut : undefined,
    onComplete() {
      gsap.set(taskSelector, {
        display: 'none'
      });
    }
  });
}