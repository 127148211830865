import { useEffect, useReducer, useState } from 'react';
import { Options } from '@doist/icons/product-ui';
import { useGSAP } from '@gsap/react';
import { ProductUIButton } from 'components/product-ui/product-ui-button/product-ui-button';
import { ProductUIContextProvider, useProductUIContext } from 'components/product-ui/product-ui-context';
import { ProductUICursor } from 'components/product-ui/product-ui-cursor/product-ui-cursor';
import { ProductUIFrame } from 'components/product-ui/product-ui-frame/product-ui-frame';
import { ProductUISkeletonText } from 'components/product-ui/product-ui-skeleton-text/product-ui-skeleton-text';
import { ProductUIWeeklyCalendarDay } from 'components/product-ui/product-ui-weekly-calendar-day/product-ui-weekly-calendar-day';
import { ProductUIWeeklyCalendarTask } from 'components/product-ui/product-ui-weekly-calendar-task/product-ui-weekly-calendar-task';
import { cursorDragEnd, cursorDragStart, moveElementsTo, moveElementTo } from 'components/product-ui/sequences/tweens';
import gsap from 'gsap';
import { useTranslation } from 'next-i18next';
import { ProductUIAppWindow } from '../../product-ui-app-window/product-ui-app-window';
import { ProductUISceneContainer, ProductUISceneLayer } from '../../product-ui-scene-container/product-ui-scene-container';
import { TemplateSidebarStandard } from '../../product-ui-sidebar/product-ui-sidebar-templates';
import { ProductUIView } from '../../product-ui-view/product-ui-view';
import css from './simplify-planning-sequence.module.css';
export function SimplifyPlanningSequence() {
  return <ProductUIContextProvider data-sentry-element="ProductUIContextProvider" data-sentry-component="SimplifyPlanningSequence" data-sentry-source-file="simplify-planning-sequence.tsx">
            <ProductUIFrame data-sentry-element="ProductUIFrame" data-sentry-source-file="simplify-planning-sequence.tsx">
                <SimplifyPlanningAnimation data-sentry-element="SimplifyPlanningAnimation" data-sentry-source-file="simplify-planning-sequence.tsx" />
            </ProductUIFrame>
        </ProductUIContextProvider>;
}
type AnimationAction = {
  type: 'taskDragging';
  dragging: boolean;
};
const animationState = {
  taskDragging: false
};
function animationReducer(state: typeof animationState, action: AnimationAction) {
  switch (action.type) {
    case 'taskDragging':
      return {
        ...state,
        taskDragging: action.dragging
      };
    default:
      return state;
  }
}
function SimplifyPlanningAnimation() {
  const {
    timeline,
    scope
  } = useProductUIContext();
  const {
    t
  } = useTranslation('productui');
  const [state, dispatch] = useReducer(animationReducer, animationState);
  const [foldHour, setFoldHour] = useState(0);
  useEffect(() => {
    const now = new Date();
    setFoldHour(now.getHours() + now.getMinutes() / 60);
  }, []);
  function taskTranslate(key: string) {
    return t(`sequence.simplifyPlanning.tasks.${key}`);
  }
  useGSAP(() => {
    if (!timeline) {
      return;
    }
    const cursorSelector = '[data-productui=cursor]';
    const targetTaskSelector = '[data-task=inbox-zero]';
    const dayFiveHourElevenSelector = '[data-productui=day-5] [data-productui=hour-11]';
    timeline.call(moveElementTo, [timeline, scope, cursorSelector, targetTaskSelector]).add(cursorDragStart(cursorSelector)).call(() => dispatch({
      type: 'taskDragging',
      dragging: true
    })).add(gsap.timeline().delay(1)).call(moveElementsTo, [timeline, scope, [{
      element: cursorSelector,
      target: dayFiveHourElevenSelector
    }, {
      element: targetTaskSelector,
      target: dayFiveHourElevenSelector,
      options: {
        opacity: 0.7
      }
    }]]).add(gsap.timeline().delay(1)).add(cursorDragEnd(cursorSelector)).to(targetTaskSelector, {
      opacity: 1
    }).to(cursorSelector, {
      opacity: 0
    });
  }, {
    dependencies: [timeline],
    scope
  });
  return <ProductUISceneContainer className={css.sequenceContainer} backgroundVariant="wave-one" data-sentry-element="ProductUISceneContainer" data-sentry-component="SimplifyPlanningAnimation" data-sentry-source-file="simplify-planning-sequence.tsx">
            <ProductUISceneLayer position="bottomLeft" data-sentry-element="ProductUISceneLayer" data-sentry-source-file="simplify-planning-sequence.tsx">
                <ProductUIAppWindow inlineSize={800} showSidebar sidebar={<TemplateSidebarStandard />} view={<ProductUIAppWindow.UIViews>
                            <ProductUIView variant="calendar">
                                <ProductUIView.Header title={t('sequence.simplifyPlanning.title')} breadcrumbs={[]} actions={[<ProductUIButton key="view-action" variant="quinary" startIcon={<Options />}>
                                            {t('view.view')}
                                        </ProductUIButton>]} />
                                <ProductUIView.WeeklyCalendarView hourOffset={8} foldHour={foldHour} allDayTasks={[[], [<ProductUIWeeklyCalendarTask key="all-day-alex-birthday" priority="p1" text={<ProductUIWeeklyCalendarTask.Text>
                                                        {taskTranslate('alexBirthday')}
                                                    </ProductUIWeeklyCalendarTask.Text>} />, <ProductUIWeeklyCalendarTask key="all-day-pick-up-cake" priority="p4" taskCircle={<ProductUIWeeklyCalendarTask.TaskCircle />} text={<ProductUIWeeklyCalendarTask.Text>
                                                        {taskTranslate('pickUpCake')}
                                                    </ProductUIWeeklyCalendarTask.Text>} />], [<ProductUIWeeklyCalendarTask key="all-day-book-vet-appointment" priority="p4" taskCircle={<ProductUIWeeklyCalendarTask.TaskCircle />} text={<ProductUIWeeklyCalendarTask.Text>
                                                        {taskTranslate('bookVetAppointment')}
                                                    </ProductUIWeeklyCalendarTask.Text>} />], [<ProductUIWeeklyCalendarTask key="all-day-td-monthly-newsletter" priority="p2" taskCircle={<ProductUIWeeklyCalendarTask.TaskCircle />} text={<ProductUIWeeklyCalendarTask.Text>
                                                        {taskTranslate('tdMonthlyNewsletter')}
                                                    </ProductUIWeeklyCalendarTask.Text>} repeat />, <ProductUIWeeklyCalendarTask key="all-day-weekly-review" priority="p4" taskCircle={<ProductUIWeeklyCalendarTask.TaskCircle />} text={<ProductUIWeeklyCalendarTask.Text>
                                                        {taskTranslate('weeklyReview')}
                                                    </ProductUIWeeklyCalendarTask.Text>} />]]}>
                                    <ProductUIWeeklyCalendarDay>
                                        <ProductUIWeeklyCalendarTask taskCircle={<ProductUIWeeklyCalendarTask.TaskCircle />} text={<ProductUIWeeklyCalendarTask.Text>
                                                    {taskTranslate('planForTheWeek')}
                                                </ProductUIWeeklyCalendarTask.Text>} description={<ProductUISkeletonText width={40} />} startTime={ProductUIWeeklyCalendarTask.StartTime(8, 15)} durationMinutes={90} />
                                        <ProductUIWeeklyCalendarTask taskCircle={<ProductUIWeeklyCalendarTask.TaskCircle />} text={<ProductUIWeeklyCalendarTask.Text>
                                                    {taskTranslate('deepWork')}
                                                </ProductUIWeeklyCalendarTask.Text>} description={<ProductUISkeletonText width={40} />} startTime={ProductUIWeeklyCalendarTask.StartTime(12, 15)} durationMinutes={172} />
                                    </ProductUIWeeklyCalendarDay>
                                    <ProductUIWeeklyCalendarDay>
                                        <ProductUIWeeklyCalendarTask key="Inbox Zero" data-task="inbox-zero" priority="p1" taskCircle={<ProductUIWeeklyCalendarTask.TaskCircle />} text={<ProductUIWeeklyCalendarTask.Text>
                                                    {taskTranslate('inboxZero')}
                                                </ProductUIWeeklyCalendarTask.Text>} description={<ProductUISkeletonText width={40} />} startTime={ProductUIWeeklyCalendarTask.StartTime(9, 0)} durationMinutes={60} dragging={state.taskDragging} />
                                        <ProductUIWeeklyCalendarTask priority="p2" taskCircle={<ProductUIWeeklyCalendarTask.TaskCircle />} text={<ProductUIWeeklyCalendarTask.Text>
                                                    {taskTranslate('recordIntroVideo')}
                                                </ProductUIWeeklyCalendarTask.Text>} description={<ProductUISkeletonText width={40} />} startTime={ProductUIWeeklyCalendarTask.StartTime(10, 0)} durationMinutes={240} />
                                        <ProductUIWeeklyCalendarTask priority="p3" taskCircle={<ProductUIWeeklyCalendarTask.TaskCircle />} text={<ProductUIWeeklyCalendarTask.Text>
                                                    {taskTranslate('bookCustomerInterview')}
                                                </ProductUIWeeklyCalendarTask.Text>} startTime={ProductUIWeeklyCalendarTask.StartTime(15, 15)} durationMinutes={60} />
                                    </ProductUIWeeklyCalendarDay>
                                    <ProductUIWeeklyCalendarDay>
                                        <ProductUIWeeklyCalendarTask taskCircle={<ProductUIWeeklyCalendarTask.TaskCircle />} text={<ProductUIWeeklyCalendarTask.Text>
                                                    {taskTranslate('publishVideoToYoutube')}
                                                </ProductUIWeeklyCalendarTask.Text>} startTime={ProductUIWeeklyCalendarTask.StartTime(8, 50)} durationMinutes={90} />
                                        <ProductUIWeeklyCalendarTask priority="p1" taskCircle={<ProductUIWeeklyCalendarTask.TaskCircle />} text={<ProductUIWeeklyCalendarTask.Text>
                                                    {taskTranslate('customerInterviews')}
                                                </ProductUIWeeklyCalendarTask.Text>} startTime={ProductUIWeeklyCalendarTask.StartTime(11, 0)} durationMinutes={240} repeat />
                                    </ProductUIWeeklyCalendarDay>
                                    <ProductUIWeeklyCalendarDay>
                                        <ProductUIWeeklyCalendarTask priority="p1" text={<ProductUIWeeklyCalendarTask.Text>
                                                    {taskTranslate('breakfastElaine')}
                                                </ProductUIWeeklyCalendarTask.Text>} description={<ProductUISkeletonText width={40} />} startTime={ProductUIWeeklyCalendarTask.StartTime(8, 50)} durationMinutes={68} />
                                        <ProductUIWeeklyCalendarTask priority="p3" taskCircle={<ProductUIWeeklyCalendarTask.TaskCircle />} text={<ProductUIWeeklyCalendarTask.Text>
                                                    {taskTranslate('designTdMonthlyNewsletter')}
                                                </ProductUIWeeklyCalendarTask.Text>} description={<ProductUISkeletonText width={40} />} startTime={ProductUIWeeklyCalendarTask.StartTime(10, 0)} durationMinutes={330} />
                                    </ProductUIWeeklyCalendarDay>
                                    <ProductUIWeeklyCalendarDay data-productui="day-5" />
                                </ProductUIView.WeeklyCalendarView>
                            </ProductUIView>
                        </ProductUIAppWindow.UIViews>} data-sentry-element="ProductUIAppWindow" data-sentry-source-file="simplify-planning-sequence.tsx" />
            </ProductUISceneLayer>
            <ProductUICursor id="productUICursor" data-productui="cursor" data-sentry-element="ProductUICursor" data-sentry-source-file="simplify-planning-sequence.tsx" />
        </ProductUISceneContainer>;
}