import React, { type ReactNode, useContext } from 'react';
import { Image, Text } from '@doist/marketist';
import cn from 'clsx';
import { ProductUViewContext } from '../utils';
import css from './product-ui-view.module.css';
import type { ProductUIViewVariant } from '../product-ui-types';
type ProductUIViewSectionEmptyProps = {
  variant?: never;
};
type ProductUIViewSectionNonEmptyProps = {
  variant?: ProductUIViewVariant;
};
type ProductUIViewSectionCommonProps = {
  children: ReactNode;
  className?: string;
  variant?: ProductUIViewVariant;
};
export type ProductUIViewSectionProps = ProductUIViewSectionCommonProps & (ProductUIViewSectionEmptyProps | ProductUIViewSectionNonEmptyProps);
export function ProductUIViewSection({
  children,
  variant,
  ...rest
}: ProductUIViewSectionProps) {
  const variantFromParent = useContext(ProductUViewContext);
  variant = variant ?? variantFromParent;
  switch (variant) {
    case 'empty':
      return <ProductUIViewEmptySection {...rest}>{children}</ProductUIViewEmptySection>;
    default:
      return <ProductUIViewBaseSection variant={variant} {...rest}>
                    {children}
                </ProductUIViewBaseSection>;
  }
}
function ProductUIViewEmptySection({
  children,
  className,
  ...rest
}: ProductUIViewSectionProps) {
  return <div className={cn(css.viewSection, css.empty, className)} {...rest} data-sentry-component="ProductUIViewEmptySection" data-sentry-source-file="product-ui-view-section.tsx">
            <Image width={344} height={270} src={`${process.env.NEXT_PUBLIC_BASE_URL}/static/product-ui/empty-illustration.jpg`} className={css.emptyStateImage} alt="" data-sentry-element="Image" data-sentry-source-file="product-ui-view-section.tsx" />
            <div data-productui="empty-section-text">
                <Text size="minor-base" weight="medium" data-sentry-element="Text" data-sentry-source-file="product-ui-view-section.tsx">
                    {children}
                </Text>
            </div>
        </div>;
}
function ProductUIViewBaseSection({
  children,
  variant = 'list',
  className,
  ...rest
}: ProductUIViewSectionProps) {
  return <div className={cn(css.viewSection, css[variant], className)} {...rest} data-sentry-component="ProductUIViewBaseSection" data-sentry-source-file="product-ui-view-section.tsx">
            {children}
        </div>;
}