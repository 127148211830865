import React, { type ReactNode } from 'react';
import { Text } from '@doist/marketist';
import { ProductUISkeletonText } from '../product-ui-skeleton-text/product-ui-skeleton-text';
import css from './product-ui-view.module.css';
import type { ProductUISkeletonWidth } from '../product-ui-types';
type ProductUIViewSectionTitleChildrenProps = {
  children: ReactNode;
  skeletonWidth?: never;
};
type ProductUIViewSectionTitleSkeletonProps = {
  children?: never;
  skeletonWidth?: ProductUISkeletonWidth;
};
export type ProductUIViewSectionTitleProps = ProductUIViewSectionTitleSkeletonProps | ProductUIViewSectionTitleChildrenProps;
export function ProductUIViewSectionTitle({
  children,
  skeletonWidth = '20'
}: ProductUIViewSectionTitleProps) {
  return <div className={css.sectionHeader} data-sentry-component="ProductUIViewSectionTitle" data-sentry-source-file="product-ui-view-section-title.tsx">
            {children ? <Text tag="span" size="minor-base" weight="semibold">
                    {children}
                </Text> : <ProductUISkeletonText width={skeletonWidth} />}
        </div>;
}