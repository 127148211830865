import { useId } from 'react';
import { QuickAdd } from '@doist/icons/product-ui';
import { Box } from '@doist/marketist';
import { useTranslation } from 'next-i18next';
import { ProductUIBasicListItem } from '../product-ui-list-item';
import css from './product-ui-quick-add-button.module.css';
import type { ListItemProps } from '../product-ui-list-item/product-ui-list-item';
type ProductUIQuickAddButtonProps = ListItemProps;
export function ProductUIQuickAddButton({
  id,
  hovered,
  abstracted
}: ProductUIQuickAddButtonProps) {
  const {
    t
  } = useTranslation('productui');
  const reactId = useId();
  return <Box className={css.quickAddWrapper} data-sentry-element="Box" data-sentry-component="ProductUIQuickAddButton" data-sentry-source-file="product-ui-quick-add-button.tsx">
            <ProductUIBasicListItem id={id ?? reactId} hovered={hovered} abstracted={abstracted} label={!abstracted ? t('sideBar.addTask') : undefined} icon={<QuickAdd width={22} height={22} className={css.quickAddIcon} />} skeletonWidth="40" data-sentry-element="ProductUIBasicListItem" data-sentry-source-file="product-ui-quick-add-button.tsx" />
        </Box>;
}