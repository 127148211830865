import { useReducer } from 'react';
import { useGSAP } from '@gsap/react';
import { ProductUIAppWindow } from 'components/product-ui/product-ui-app-window/product-ui-app-window';
import { ProductUIContextProvider, useProductUIContext } from 'components/product-ui/product-ui-context';
import { ProductUICursor } from 'components/product-ui/product-ui-cursor/product-ui-cursor';
import { ProductUIFrame } from 'components/product-ui/product-ui-frame/product-ui-frame';
import { ProductUIBasicListItem, ProductUIProjectListItem, ProductUISidebarHeaderItem } from 'components/product-ui/product-ui-list-item';
import { ProductUIQuickAddButton } from 'components/product-ui/product-ui-quick-add-button/product-ui-quick-add-button';
import { ProductUISceneContainer, ProductUISceneLayer } from 'components/product-ui/product-ui-scene-container/product-ui-scene-container';
import { ProductUISidebar, ProfileMenu, SideBarSection } from 'components/product-ui/product-ui-sidebar/product-ui-sidebar';
import { ProductUIView } from 'components/product-ui/product-ui-view/product-ui-view';
import gsap from 'gsap';
import { useTranslation } from 'next-i18next';
import { cursorClick, moveElementTo } from '../tweens';
import css from './get-it-all-done-sequence.module.css';
export function GetItAllDoneSequence() {
  return <ProductUIContextProvider data-sentry-element="ProductUIContextProvider" data-sentry-component="GetItAllDoneSequence" data-sentry-source-file="get-it-all-done-sequence.tsx">
            <ProductUIFrame data-sentry-element="ProductUIFrame" data-sentry-source-file="get-it-all-done-sequence.tsx">
                <GetItAllDoneAnimation data-sentry-element="GetItAllDoneAnimation" data-sentry-source-file="get-it-all-done-sequence.tsx" />
            </ProductUIFrame>
        </ProductUIContextProvider>;
}
type ActiveSection = 'today' | 'personal' | 'team';
const animationInitialState = {
  personalWorkspaceOpen: false,
  personalWorkspaceHover: false,
  teamWorkspaceOpen: false,
  teamWorkspaceHover: false,
  activeSidebarSection: 'today' as ActiveSection
};
type AnimationState = typeof animationInitialState & {
  activeSidebarSection: ActiveSection;
};
type AnimationAction = {
  type: 'openTeamWorkspace';
} | {
  type: 'openPersonalWorkspace';
} | {
  type: 'personalWorkspaceHover';
  hover: boolean;
} | {
  type: 'teamWorkspaceHover';
  hover: boolean;
} | {
  type: 'setActiveSection';
  section: ActiveSection;
};
function animationReducer(state: AnimationState, action: AnimationAction) {
  switch (action.type) {
    case 'openPersonalWorkspace':
      return {
        ...state,
        personalWorkspaceOpen: true
      };
    case 'openTeamWorkspace':
      return {
        ...state,
        teamWorkspaceOpen: true
      };
    case 'personalWorkspaceHover':
      return {
        ...state,
        personalWorkspaceHover: action.hover
      };
    case 'teamWorkspaceHover':
      return {
        ...state,
        teamWorkspaceHover: action.hover
      };
    case 'setActiveSection':
      return {
        ...state,
        activeSidebarSection: action.section
      };
    default:
      return state;
  }
}
function GetItAllDoneAnimation() {
  const {
    timeline,
    scope
  } = useProductUIContext();
  const {
    t
  } = useTranslation('productui');
  const [state, dispatch] = useReducer(animationReducer, animationInitialState);
  useGSAP(() => {
    if (!timeline) {
      return;
    }
    const cursorSelector = '[data-productui="cursor"]';
    const personalSidebarSelector = '[data-sidebarsection="personal"]';
    const personalWorkspaceHeaderSelector = '[data-productuisidebarheaderitem="personal"]';
    const teamWorkspaceHeaderSelector = '[data-productuisidebarheaderitem="team"]';
    const teamSidebarSelector = '[data-sidebarsection="team"]';
    const uiViewInboxZeroSelector = '[data-productuiview="inbox-zero"]';
    const uiViewPersonalSelector = '[data-productuiview="personal-workspace"]';
    const uiViewTeamSelector = '[data-productuiview="team-workspace"]';
    timeline.add('Open the personal worksapce').to(personalSidebarSelector, {
      onStart() {
        dispatch({
          type: 'openPersonalWorkspace'
        });
      },
      height: 'auto'
    }).to(teamSidebarSelector, {
      onStart() {
        dispatch({
          type: 'openTeamWorkspace'
        });
      },
      height: 'auto'
    }).add('Bring the mouse in').set(cursorSelector, {
      inset: '50%',
      xPercent: -50,
      yPercent: -50
    }).to(cursorSelector, {
      opacity: 1
    }).call(moveElementTo, [timeline, scope, cursorSelector, personalWorkspaceHeaderSelector]).call(() => {
      dispatch({
        type: 'personalWorkspaceHover',
        hover: true
      });
    }, [], '<').add(gsap.timeline().delay(0.5)).add(cursorClick(cursorSelector)).call(() => {
      dispatch({
        type: 'setActiveSection',
        section: 'personal'
      });
    }).to(uiViewInboxZeroSelector, {
      display: 'none'
    }, '<').to(uiViewPersonalSelector, {
      display: 'block'
    }, '<').call(moveElementTo, [timeline, scope, cursorSelector, teamWorkspaceHeaderSelector]).call(() => {
      dispatch({
        type: 'personalWorkspaceHover',
        hover: false
      });
      dispatch({
        type: 'teamWorkspaceHover',
        hover: true
      });
    }).add(gsap.timeline().delay(0.5)).add(cursorClick(cursorSelector)).call(() => {
      dispatch({
        type: 'setActiveSection',
        section: 'team'
      });
    }).to(uiViewPersonalSelector, {
      display: 'none'
    }, '<').to(uiViewTeamSelector, {
      display: 'block'
    }, '<').to(cursorSelector, {
      opacity: 0
    });
  }, {
    dependencies: [timeline],
    scope
  });
  return <ProductUISceneContainer className={css.sequenceContainer} backgroundVariant="wave-two" data-sentry-element="ProductUISceneContainer" data-sentry-component="GetItAllDoneAnimation" data-sentry-source-file="get-it-all-done-sequence.tsx">
            <ProductUISceneLayer position="bottomRight" data-sentry-element="ProductUISceneLayer" data-sentry-source-file="get-it-all-done-sequence.tsx">
                <ProductUIAppWindow inlineSize={808} showSidebar sidebar={<ProductUISidebar profileMenu={<ProfileMenu dailyProgress={0.75} />} views={<SideBarSection>
                                    <ProductUIQuickAddButton abstracted />
                                    <ProductUIBasicListItem icon="search" skeletonWidth="20" abstracted />
                                    <ProductUIBasicListItem icon="inbox" skeletonWidth="20" abstracted />
                                    <ProductUIBasicListItem icon="dateToday" iconColor={state.activeSidebarSection === 'today' ? 'theme' : undefined} label={t('taskAttributePill.today')} active={state.activeSidebarSection === 'today'} />
                                </SideBarSection>} workspaces={<>
                                    <SideBarSection>
                                        <ProductUISidebarHeaderItem icon="personal" label={t('sequence.getItAllDone.myProjects')} hovered={state.personalWorkspaceHover} data-productuisidebarheaderitem="personal" active={state.activeSidebarSection === 'personal'} />
                                        <div data-productui="sidebarsection" data-sidebarsection="personal">
                                            <ProductUIBasicListItem icon="project" iconColor="red" abstracted skeletonWidth="20" />
                                            <ProductUIBasicListItem icon="project" iconColor="yellow" abstracted skeletonWidth="40" />
                                            <ProductUIBasicListItem icon="project" iconColor="blue" abstracted skeletonWidth="60" />
                                        </div>
                                    </SideBarSection>
                                    <SideBarSection>
                                        <ProductUISidebarHeaderItem icon="team" label={t('sequence.getItAllDone.team')} hovered={state.teamWorkspaceHover} data-productuisidebarheaderitem="team" active={state.activeSidebarSection === 'team'} />
                                        <div data-productui="sidebarsection" data-sidebarsection="team">
                                            <ProductUIBasicListItem icon="project" iconColor="orange" abstracted skeletonWidth="60" />
                                            <ProductUIBasicListItem icon="project" iconColor="purple" abstracted skeletonWidth="80" />
                                            <ProductUIBasicListItem icon="project" iconColor="limeGreen" abstracted skeletonWidth="40" />
                                        </div>
                                    </SideBarSection>
                                </>} />} view={<ProductUIAppWindow.UIViews>
                            <ProductUIView variant="list" data-productui="ui-view" data-productuiview="inbox-zero">
                                <ProductUIView.Header title={t('taskAttributePill.today')} breadcrumbs={[]} />

                                <ProductUIView.Section variant="empty">
                                    {t('sequence.todayView.todoistZero')}
                                </ProductUIView.Section>
                            </ProductUIView>
                            <ProductUIView variant="workspace" data-productui="ui-view" data-productuiview="personal-workspace">
                                <ProductUIView.Header title={t('sequence.getItAllDone.personal')} titleAvatar="personal" />
                                <ProductUIView.Section>
                                    <ProductUIProjectListItem iconColor="red" label={t('sequence.getItAllDone.fitness')} />
                                    <ProductUIProjectListItem iconColor="yellow" label={t('sequence.getItAllDone.groceries')} />
                                    <ProductUIProjectListItem iconColor="blue" label={t('sequence.getItAllDone.appointments')} />
                                </ProductUIView.Section>
                            </ProductUIView>
                            <ProductUIView data-productui="ui-view" data-productuiview="team-workspace" variant="workspace">
                                <ProductUIView.Header title={t('sequence.getItAllDone.team')} titleAvatar="team" />
                                <ProductUIView.Section>
                                    <ProductUIProjectListItem iconColor="orange" label={t('sequence.getItAllDone.newBrand')} joined />
                                    <ProductUIProjectListItem iconColor="purple" label={t('sequence.getItAllDone.websiteUpdate')} />
                                    <ProductUIProjectListItem iconColor="limeGreen" label={t('sequence.getItAllDone.productRoadmap')} joined />
                                    <ProductUIProjectListItem iconColor="lavender" label={t('sequence.getItAllDone.meetingAgenda')} />
                                </ProductUIView.Section>
                            </ProductUIView>
                        </ProductUIAppWindow.UIViews>} data-sentry-element="ProductUIAppWindow" data-sentry-source-file="get-it-all-done-sequence.tsx" />
            </ProductUISceneLayer>
            <ProductUICursor data-productui="cursor" data-sentry-element="ProductUICursor" data-sentry-source-file="get-it-all-done-sequence.tsx" />
        </ProductUISceneContainer>;
}