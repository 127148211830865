type PrefooterVariant = 'yellow' | 'white' | 'red' | 'green' | 'seaSalt' | 'neutral'

function getPrefooterColors(variant?: string) {
    switch (variant) {
        case 'yellow':
            return {
                backgroundColor: 'var(--composition-background-yellow)',
                bottomBarBackgroundColor: 'var(--composition-background-yellow)',
                hairlineBackgroundColor: 'var(--ui-border)',
            }
        case 'white':
            return {
                backgroundColor: 'var(--composition-background-white)',
                bottomBarBackgroundColor: 'var(--composition-background-white)',
                hairlineBackgroundColor: 'var(--ui-border)',
            }
        case 'red':
            return {
                backgroundColor: 'var(--composition-background-red)',
                bottomBarBackgroundColor: 'var(--composition-background-red)',
                hairlineBackgroundColor: 'var(--ui-border)',
            }
        case 'green':
            return {
                backgroundColor: 'var(--composition-background-green)',
                bottomBarBackgroundColor: 'var(--composition-background-green)',
                hairlineBackgroundColor: 'var(--ui-border)',
            }
        case 'seaSalt':
            return {
                backgroundColor: 'var(--composition-background-sea-salt)',
                bottomBarBackgroundColor: 'var(--composition-background-sea-salt)',
                hairlineBackgroundColor: 'var(--ui-border)',
            }
        default:
            return {
                backgroundColor: 'var(--composition-background-neutral)',
                bottomBarBackgroundColor: 'var(--composition-background-neutral)',
                hairlineBackgroundColor: 'var(--ui-border)',
            }
    }
}

export { getPrefooterColors }

export type { PrefooterVariant }
