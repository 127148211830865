import React, { useEffect, useRef } from 'react';
import { Box } from '@doist/marketist';
import cn from 'clsx';
import css from './product-ui-frame.module.css';
type ProductFrameProps = {
  children: React.ReactNode;
  className?: string;
  size?: 'base' | 'lg';
};
function ProductFrame({
  children,
  className,
  size = 'base'
}: ProductFrameProps) {
  return <div className={cn(css.productFrame, className, css[size])} aria-hidden data-sentry-component="ProductFrame" data-sentry-source-file="product-ui-frame.tsx">
            {children}
        </div>;
}
type ProductUIFrameProps = {
  children: React.ReactNode;
  className?: string;
  size?: 'base' | 'lg';
};
export function ProductUIFrame({
  children,
  size = 'base'
}: ProductUIFrameProps) {
  const scope = useRef<HTMLDivElement>(null);
  const innerRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    const parentElement = scope.current;
    const childElement = innerRef.current;
    if (!parentElement || typeof document === 'undefined') {
      return;
    }
    function setScaleFactor() {
      if (!parentElement || !childElement) {
        return;
      }
      const originalWidth = childElement.scrollWidth;
      const originalHeight = childElement.clientHeight;
      const parentWidth = parentElement.clientWidth;
      const scaleFactor = parentWidth / originalWidth;
      const scaledHeight = originalHeight * scaleFactor;
      childElement.style.transform = `scale(${scaleFactor})`;
      childElement.style.transformOrigin = 'top left';
      parentElement.style.height = `${scaledHeight}px`;
    }
    const resizeObserver = new ResizeObserver(() => {
      setScaleFactor();
    });
    if (parentElement) {
      resizeObserver.observe(parentElement);
    }
    setScaleFactor();
    return function scaleFactorCleanup() {
      resizeObserver.disconnect();
    };
  }, []);
  return <Box ref={scope} data-sentry-element="Box" data-sentry-component="ProductUIFrame" data-sentry-source-file="product-ui-frame.tsx">
            <Box ref={innerRef} data-sentry-element="Box" data-sentry-source-file="product-ui-frame.tsx">
                <ProductFrame className={css.productUIFrame} size={size} data-sentry-element="ProductFrame" data-sentry-source-file="product-ui-frame.tsx">
                    {children}
                </ProductFrame>
            </Box>
        </Box>;
}
type ProductUIAppFrameProps = {
  children: React.ReactNode;
  borderRadius?: 'base' | 'none';
  className?: string;
};
export function ProductUIAppFrame({
  children,
  borderRadius = 'base',
  className
}: ProductUIAppFrameProps) {
  return <ProductFrame className={cn(css.productUIAppFrame, className, {
    [css.baseRadius]: borderRadius === 'base'
  })} data-sentry-element="ProductFrame" data-sentry-component="ProductUIAppFrame" data-sentry-source-file="product-ui-frame.tsx">
            {children}
        </ProductFrame>;
}