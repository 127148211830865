import cn from 'clsx';
import css from './product-ui-view.module.css';
import type { ReactNode } from 'react';
export type ProductUIViewBoardViewProps = {
  children: ReactNode;
  contentXPosition?: number;
  contentYPosition?: number;
  columnWidth?: string;
};
export function ProductUIViewBoardView({
  children,
  contentXPosition = 0,
  contentYPosition = 0,
  columnWidth
}: ProductUIViewBoardViewProps) {
  const customProps: React.CSSProperties = {
    '--content-x': `${contentXPosition}px`,
    '--content-y': `${contentYPosition}px`
  };
  if (columnWidth) {
    customProps['--column-width'] = columnWidth;
  }
  return <div data-sentry-component="ProductUIViewBoardView" data-sentry-source-file="product-ui-view-board-view.tsx">
            <div className={cn(css.boardView, {
      [css.customPosition]: Boolean(contentXPosition) || Boolean(contentYPosition)
    })} style={customProps}>
                {children}
            </div>
        </div>;
}