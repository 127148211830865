import { useEffect, useState } from 'react';
import HtmlToReact from 'html-react-parser';
import * as BannerStatus from 'services/bannersStatus';
import type { PageBanner } from 'types/contentful';
function useBannerStatus(pageBanner?: PageBanner) {
  const [status, setBannerStatus] = useState(BannerStatus.Status.Unknown);
  useEffect(() => {
    if (pageBanner?.message) {
      const status = BannerStatus.getStatus(pageBanner.message);
      setBannerStatus(status);
    }
  }, [pageBanner]);
  function onCloseBanner() {
    if (pageBanner) {
      BannerStatus.markAsViewed(pageBanner.message);
      setBannerStatus(BannerStatus.Status.Viewed);
    }
  }
  function bannerNotSeen(): boolean {
    return Boolean(pageBanner) && status === BannerStatus.Status.NotSeen;
  }
  function renderBanner(): string | JSX.Element | JSX.Element[] | undefined {
    if (pageBanner) {
      return HtmlToReact(pageBanner.message);
    }
  }
  return {
    onCloseBanner,
    bannerNotSeen,
    renderBanner,
    status
  };
}
export { useBannerStatus };