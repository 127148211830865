import { Checkmark } from '@doist/icons/product-ui';
import cn from 'clsx';
import css from './product-ui-task-circle.module.css';
import type { Priority } from '../product-ui-types';
export type ProductUITaskCircleProps = {
  id?: string;
  priority?: Priority;
  variant?: 'list' | 'calendar' | 'subtitle';
  hovered?: boolean;
  checked?: boolean;
  hasFutureOccurrence?: boolean;
  className?: string;
};
export function ProductUITaskCircle({
  id,
  priority = 'p4',
  hovered,
  checked,
  variant = 'list',
  hasFutureOccurrence,
  className,
  ...rest
}: ProductUITaskCircleProps) {
  const showCheckmark = checked || hovered;
  return <div id={id} className={cn(css.taskCircle, css[priority], css[variant], {
    [css.checked]: checked,
    [css.hovered]: hovered,
    [css.futureOccurrence]: Boolean(hasFutureOccurrence)
  }, className)} {...rest} data-sentry-component="ProductUITaskCircle" data-sentry-source-file="product-ui-task-circle.tsx">
            {showCheckmark ? <Checkmark width={24} height={24} /> : null}
        </div>;
}