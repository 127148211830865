import { ProductUIBasicListItem, ProductUISidebarHeaderItem } from '../product-ui-list-item';
import { ProductUIQuickAddButton } from '../product-ui-quick-add-button/product-ui-quick-add-button';
import { ProductUISidebar, ProfileMenu, SideBarSection } from './product-ui-sidebar';
export function TemplateSidebarMainFilters() {
  return <SideBarSection data-sentry-element="SideBarSection" data-sentry-component="TemplateSidebarMainFilters" data-sentry-source-file="product-ui-sidebar-templates.tsx">
            <ProductUIQuickAddButton abstracted data-sentry-element="ProductUIQuickAddButton" data-sentry-source-file="product-ui-sidebar-templates.tsx" />
            <ProductUIBasicListItem icon="search" skeletonWidth="20" abstracted data-sentry-element="ProductUIBasicListItem" data-sentry-source-file="product-ui-sidebar-templates.tsx" />
            <ProductUIBasicListItem icon="inbox" skeletonWidth="20" abstracted data-sentry-element="ProductUIBasicListItem" data-sentry-source-file="product-ui-sidebar-templates.tsx" />
            <ProductUIBasicListItem icon="dateToday" skeletonWidth="20" abstracted data-sentry-element="ProductUIBasicListItem" data-sentry-source-file="product-ui-sidebar-templates.tsx" />
        </SideBarSection>;
}
export function TemplateSidebarStandard() {
  return <ProductUISidebar profileMenu={<ProfileMenu dailyProgress={0.7} />} views={<TemplateSidebarMainFilters />} workspaces={<>
                    <SideBarSection>
                        <ProductUISidebarHeaderItem icon="personal" abstracted skeletonWidth="60" />
                    </SideBarSection>
                    <SideBarSection>
                        <ProductUISidebarHeaderItem icon="team" abstracted skeletonWidth="60" />
                    </SideBarSection>
                </>} data-sentry-element="ProductUISidebar" data-sentry-component="TemplateSidebarStandard" data-sentry-source-file="product-ui-sidebar-templates.tsx" />;
}