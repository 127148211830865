'use client';

import { createContext, useEffect, useState } from 'react';
import { useTranslation } from 'next-i18next';
import { push, setupPropertiesFromContext } from 'services/analytics';
import { getUserPlan } from 'services/get-user-plan';
import { isUserLikelyLoggedIn } from 'services/is-likely-logged-in';
import { CSRFHEADER } from 'services/withCSRFValidation';
import type { UserAPIContext } from 'pages/api/context';
import type { BackendContext } from 'services/get-backend-context';
import type { Plan, User } from 'types/user';
import { Workspace } from '@/types/workspace';
const allowedDomains = ['@doist.com', '@doist.test'];
function isUserAdmin(user: User) {
  if (!['verified', 'legacy'].includes(user.verification_status)) return false;
  if (!allowedDomains.some(domain => user.email.endsWith(domain))) return false;
  return true;
}
export type UserContextV2Props = Omit<BackendContext, 'user'> & {
  // Indicates if the user is undefined because the context is not loaded yet or if the user is not logged in
  isUserInitialized: boolean;
  user?: User;
  userPlan?: Plan;
  isAdmin: boolean;
};
export const UserContextV2 = createContext<UserContextV2Props>({
  isUserInitialized: false,
  isAdmin: false,
  workspaces: []
});
export type UserContextV2ProviderProps = {
  user?: User;
  workspaces?: Workspace[];
  children: React.ReactNode;
};
export function UserContextV2Provider({
  user,
  workspaces = [],
  children
}: UserContextV2ProviderProps) {
  const {
    i18n: {
      language = 'en'
    }
  } = useTranslation();
  const [context, setContext] = useState<UserContextV2Props>({
    isUserInitialized: Boolean(user),
    user,
    workspaces,
    userPlan: getUserPlan(user),
    isAdmin: user ? isUserAdmin(user) : false
  });
  const [isFetchingContext, setIsFetchingContext] = useState(false);
  async function fetchUser() {
    if (isFetchingContext) {
      return;
    }
    setIsFetchingContext(true);
    try {
      const res = await fetch(`${process.env.NEXT_PUBLIC_BASE_URL}/nextapi/context`, {
        method: 'POST',
        headers: CSRFHEADER
      });
      if (!res.ok) {
        setContext(context => ({
          ...context,
          contextInitialized: true
        }));
        throw new Error('Unable to retrieve user context. Status: ' + res.status);
      }
      const context: UserAPIContext = await res.json();
      setContext({
        isUserInitialized: true,
        user: context.user ? context.user : undefined,
        workspaces: context.workspaces,
        userPlan: getUserPlan(context.user),
        isAdmin: context.user ? isUserAdmin(context.user) : false
      });
    } catch (error) {
      console.error(error);
    }
    setIsFetchingContext(false);
  }
  async function fetchMockUserAndSetContext(state: string) {
    try {
      const res = await fetch(`/nextapi/mock/user-state?state=${state}`, {
        headers: {
          ...CSRFHEADER
        }
      });
      const {
        userState
      } = await res.json();
      setContext({
        isUserInitialized: true,
        user: userState.user || undefined,
        workspaces: userState.workspaces,
        userPlan: getUserPlan(userState.user),
        isAdmin: userState.user ? isUserAdmin(userState.user) : false
      });
    } catch (error) {
      console.error(error);
    }
  }
  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const mockUserStateInQuery = searchParams.get('user_state');
    if (mockUserStateInQuery) {
      fetchMockUserAndSetContext(mockUserStateInQuery);
    } else if (!user) {
      if (isUserLikelyLoggedIn()) {
        if (!context.user) {
          fetchUser();
        }
      } else {
        setContext({
          isUserInitialized: true,
          userPlan: undefined,
          isAdmin: false,
          workspaces: []
        });
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => setupPropertiesFromContext({
    user: context.user ?? null,
    workspaces: context.workspaces
  }), [context]);
  useEffect(() => push({
    language
  }), [language]);
  return <UserContextV2.Provider value={context} data-sentry-element="unknown" data-sentry-component="UserContextV2Provider" data-sentry-source-file="user-context.tsx">{children}</UserContextV2.Provider>;
}