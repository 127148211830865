import React from 'react';
import cn from 'clsx';
import { useSidebarContext } from '../product-ui-sidebar/product-ui-sidebar';
import css from './product-ui-task-attribute-button.module.css';
type ProductUIIconButtonProps = {
  children: React.ReactNode;
  size?: 'lg' | 'base';
  hovered?: boolean;
};
export function ProductUITaskAttributeButton({
  children,
  size = 'base',
  hovered,
  ...rest
}: ProductUIIconButtonProps) {
  const withinSidebar = useSidebarContext();
  return <div className={cn(css.iconButton, css[size], {
    [css.hovered]: hovered,
    [css.sidebar]: withinSidebar
  })} {...rest} data-sentry-component="ProductUITaskAttributeButton" data-sentry-source-file="product-ui-task-attribute-button.tsx">
            {children}
        </div>;
}