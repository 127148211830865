import { useContext } from 'react';
import { ProductUViewContext } from '../utils';
import { ProductUIViewHeaderActivity } from './product-ui-view-header-activity';
import { ProductUIViewHeaderBoard } from './product-ui-view-header-board';
import { ProductUIViewHeaderCalendar } from './product-ui-view-header-calendar';
import { ProductUIViewHeaderList } from './product-ui-view-header-list';
import { ProductUIViewHeaderUpcoming } from './product-ui-view-header-upcoming';
import { ProductUIViewHeaderWorkspace } from './product-ui-view-header-workspace';
import type { ProductUIViewVariant } from '../product-ui-types';
import type { ProductUIViewHeaderBaseProps } from './product-ui-view-header-base';
const mapVariantToComponent: Map<ProductUIViewVariant, (args: ProductUIViewHeaderBaseProps) => JSX.Element> = new Map([['list', ProductUIViewHeaderList], ['board', ProductUIViewHeaderBoard], ['upcoming', ProductUIViewHeaderUpcoming], ['workspace', ProductUIViewHeaderWorkspace], ['activity', ProductUIViewHeaderActivity], ['calendar', ProductUIViewHeaderCalendar]]);
function ProductUIViewHeader(props: ProductUIViewHeaderBaseProps) {
  const variant = useContext(ProductUViewContext);
  const Component = mapVariantToComponent.get(variant);
  if (Component) {
    return <Component {...props} />;
  } else {
    return null;
  }
}
export { ProductUIViewHeader };
export type { ProductUIViewHeaderBaseProps };