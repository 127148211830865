import { cloneElement, isValidElement, type ReactElement, type ReactNode } from 'react';
import { ChevronDown, ChevronRight, Complete, CompleteFilled, DateToday, DateTodayFilled, DateUpcoming, DateUpcomingFilled, FilterOutline, FiltersLabelsFilled, FiltersLabelsOutline, FolderClosed, FolderOpen, Group, InboxOutline, LabelOutline, MoreFilled, MoreOutline, Padlock, PriorityFlagOutline, Projects, Search } from '@doist/icons/product-ui';
import { Box, type Icon, Stack, Text, type TextSize } from '@doist/marketist';
import cn from 'clsx';
import { useTranslation } from 'next-i18next';
import { useSidebarContext } from '../product-ui-sidebar/product-ui-sidebar';
import { ProductUISkeletonText } from '../product-ui-skeleton-text/product-ui-skeleton-text';
import { ProductUITaskAttributeButton } from '../product-ui-task-attribute-button/product-ui-task-attribute-button';
import { mapColorNameToCustomProp } from '../utils';
import css from './product-ui-list-item.module.css';
import type { ProductUISkeletonWidth } from '../product-ui-types';
import type { IconNames, ProjectColors } from './types';
export type ListItemProps = {
  id?: string;
  hovered?: boolean;
  active?: boolean;
  abstracted?: boolean;
  skeletonWidth?: ProductUISkeletonWidth;
  label?: string;
  icon?: IconNames | Icon | ReactElement;
  iconColor?: ProjectColors;
  showMore?: boolean | ReactElement;
  showMoreHovered?: boolean;
  size?: 'base' | 'sm';
  contextMenu?: ReactElement;
};
function getIcon(icon: IconNames | Icon | ReactElement, size: 'base' | 'sm', color: ProjectColors, active?: boolean) {
  const iconSize = size === 'base' ? 24 : 20;
  const iconColor = mapColorNameToCustomProp(color);
  const props = {
    width: iconSize,
    height: iconSize,
    color: iconColor
  };
  if (isValidElement(icon)) {
    return icon;
  }
  if (typeof icon === 'function') {
    const Icon: Icon = icon;
    return <Icon {...props} />;
  }
  switch (icon) {
    case 'inbox':
      return <InboxOutline {...props} />;
    case 'views':
      return active ? <FiltersLabelsFilled {...props} /> : <FiltersLabelsOutline {...props} />;
    case 'complete':
      return active ? <CompleteFilled {...props} /> : <Complete {...props} />;
    case 'dateToday':
      return active ? <DateTodayFilled {...props} /> : <DateToday {...props} />;
    case 'dateUpcoming':
      return active ? <DateUpcomingFilled {...props} /> : <DateUpcoming {...props} />;
    case 'priorityFlag':
      return <PriorityFlagOutline {...props} />;
    case 'search':
      return <Search {...props} />;
    case 'project':
      return <Projects {...props} />;
    case 'filter':
      return <FilterOutline {...props} />;
    case 'folderClosed':
      return <FolderClosed {...props} />;
    case 'folderOpen':
      return <FolderOpen {...props} />;
    case 'label':
      return <LabelOutline {...props} />;
    case 'group':
      return <Group {...props} />;
    default:
      return null;
  }
}
function ProductUIBasicListItem({
  id,
  hovered = false,
  active = false,
  abstracted = false,
  skeletonWidth = '100',
  label = '',
  icon,
  iconColor = 'grey',
  showMore = false,
  showMoreHovered = false,
  size = 'base',
  contextMenu,
  ...rest
}: ListItemProps) {
  const withinSidebar = useSidebarContext();
  const textSize = size === 'base' ? 'body-sm' : 'body-xs';
  const MoreIcon = hovered ? MoreFilled : MoreOutline;
  return <div className={css.listItemWrapper} data-sentry-component="ProductUIBasicListItem" data-sentry-source-file="product-ui-list-item.tsx">
            <div id={id} className={cn(css.container, css.basicType, {
      [css.hovered]: hovered,
      [css.active]: active,
      [css.sidebarListItem]: withinSidebar
    })} {...rest}>
                <div className={css.left}>
                    {icon ? getIcon(icon, size, iconColor, active) : null}
                    {abstracted ? <div className={css.abstract}>
                            <ProductUISkeletonText width={skeletonWidth} />
                        </div> : <Text size={textSize}>{label}</Text>}
                </div>
                {showMore ? <div className={css.right}>
                        {showMore === true ? <ProductUITaskAttributeButton hovered={showMoreHovered}>
                                <MoreIcon width="20" height="20" color="var(--display-onlight-tertiary)" />
                            </ProductUITaskAttributeButton> : <>{showMore}</>}
                    </div> : null}
            </div>
            {contextMenu}
        </div>;
}
type ProductUIListItemRightIconType = {
  variant: 'icon';
  icon: ReactElement;
};
type ProductUIListItemRightDropdownType = {
  variant: 'dropdown';
  label: string;
};
type ProductUIListItemRightProps = ProductUIListItemRightIconType | ProductUIListItemRightDropdownType;
export function ProductUIListItemRight(props: ProductUIListItemRightProps) {
  if (props.variant === 'dropdown') {
    return <Box className={cn(css.contextMenuListItemRight, css[props.variant])}>
                {props.label} <ChevronDown />
            </Box>;
  }
  if (props.variant === 'icon') {
    return <Box className={cn(css.contextMenuListItemRight, css[props.variant])}>
                {cloneElement(props.icon, {
        width: 20,
        height: 20
      })}
            </Box>;
  }
  return null;
}
type ProjectUIProjectListItemProps = {
  joined?: boolean;
  privateProject?: boolean;
  subtitle?: string;
};
function ProductUIProjectListItem({
  id,
  label = '',
  hovered = false,
  abstracted = false,
  skeletonWidth = '100',
  icon,
  iconColor = 'red',
  joined = false,
  privateProject = false,
  subtitle,
  contextMenu
}: ListItemProps & ProjectUIProjectListItemProps) {
  const Icon = (icon ? icon : Projects) as Icon;
  const {
    t
  } = useTranslation('productui');
  return <div id={id} className={cn(css.container, css.projectType, {
    [css.hovered]: hovered
  })} data-sentry-component="ProductUIProjectListItem" data-sentry-source-file="product-ui-list-item.tsx">
            <div className={css.left}>
                {Icon ? <Icon width="24" height="24" color={mapColorNameToCustomProp(iconColor)} /> : null}
                <Stack data-sentry-element="Stack" data-sentry-source-file="product-ui-list-item.tsx">
                    <Box className={css.label} data-sentry-element="Box" data-sentry-source-file="product-ui-list-item.tsx">
                        {abstracted ? <div className={css.abstract}>
                                <ProductUISkeletonText width={skeletonWidth} />
                            </div> : <Text size="minor-base" weight="semibold">
                                {label}
                            </Text>}
                        {privateProject ? <Box className={css.locked}>
                                <Padlock />
                            </Box> : null}
                    </Box>
                    {joined ? <Text size="body-2xs" className={css.joinLabel}>
                            {t('joined')}
                        </Text> : null}
                    {subtitle ? <Text size="body-2xs" className={css.subtitle}>
                            {subtitle}
                        </Text> : null}
                </Stack>
            </div>
            {hovered ? <Box className={css.right}>
                    <ProductUITaskAttributeButton size="lg" hovered>
                        <MoreOutline />
                    </ProductUITaskAttributeButton>
                </Box> : null}
            {contextMenu}
        </div>;
}
type ProjectUIFolderListItemProps = {
  label: string;
  open?: boolean;
  children?: ReactNode;
  buttonHovered?: boolean;
  buttonContainer?: (children: ReactNode) => ReactNode;
  subtitle: string;
};
export function ProjectUIFolderListItem({
  children,
  label,
  open,
  buttonHovered,
  buttonContainer = children => children,
  subtitle
}: ProjectUIFolderListItemProps) {
  return <Box className={css.folderListItem} data-sentry-element="Box" data-sentry-component="ProjectUIFolderListItem" data-sentry-source-file="product-ui-list-item.tsx">
            {buttonContainer(<ProductUITaskAttributeButton hovered={buttonHovered}>
                    {open ? <ChevronDown /> : <ChevronRight />}
                </ProductUITaskAttributeButton>)}
            <Box data-sentry-element="Box" data-sentry-source-file="product-ui-list-item.tsx">
                <Box className={css.folderListItemHeader} data-sentry-element="Box" data-sentry-source-file="product-ui-list-item.tsx">
                    <ProductUIProjectListItem iconColor="greyTertiary" icon={open ? FolderOpen : FolderClosed} label={label}
        // TODO: I've setup a translation string to make this dynamic
        // Once it's translated use the following and remove the subtitle property:
        // t('projectFolder.project, {count: React.Children.count(children)}')
        subtitle={subtitle} data-sentry-element="ProductUIProjectListItem" data-sentry-source-file="product-ui-list-item.tsx" />
                </Box>
                {open && children ? <Box className={css.folderListItemChildren}>{children}</Box> : null}
            </Box>
        </Box>;
}
type PermissionListItemProps = {
  id?: string;
  avatar: ReactElement;
  name: React.ReactNode;
  nameSize?: TextSize;
  label?: string;
  buttonSection?: ReactElement;
  skeletonWidth?: ProductUISkeletonWidth;
  abstracted?: boolean;
  contextMenu?: ReactElement;
};
function ProductUIPermissionListItem({
  id,
  label = '',
  name,
  nameSize = 'minor-sm',
  abstracted = false,
  skeletonWidth = '100',
  buttonSection,
  avatar,
  contextMenu
}: PermissionListItemProps) {
  return <div id={id} className={cn(css.container, css.permissionType)} data-sentry-component="ProductUIPermissionListItem" data-sentry-source-file="product-ui-list-item.tsx">
            <div className={css.left}>{avatar}</div>
            <div className={css.middle}>
                <Text size={nameSize} weight="semibold" data-sentry-element="Text" data-sentry-source-file="product-ui-list-item.tsx">
                    {name}
                </Text>
                {abstracted ? <div className={css.abstract}>
                        <ProductUISkeletonText width={skeletonWidth} />
                    </div> : <Text size="minor-base">{label}</Text>}
            </div>
            <div className={css.right}>{buttonSection}</div>
            {contextMenu}
        </div>;
}
type RoleListItemProps = {
  buttonSection?: ReactElement;
  icon?: never;
  iconColor?: never;
  abstracted?: never;
  skeletonWidth?: never;
} & ListItemProps;
function ProductUIRoleListItem({
  id,
  label = '',
  buttonSection,
  hovered = false
}: RoleListItemProps) {
  return <div id={id} className={cn(css.container, css.roleType, {
    [css.hovered]: hovered
  })} data-sentry-component="ProductUIRoleListItem" data-sentry-source-file="product-ui-list-item.tsx">
            <div className={css.left}>
                <Text size="minor-xs" weight="semibold" data-sentry-element="Text" data-sentry-source-file="product-ui-list-item.tsx">
                    {label}
                </Text>
                <ProductUISkeletonText width="100" data-sentry-element="ProductUISkeletonText" data-sentry-source-file="product-ui-list-item.tsx" />
                <ProductUISkeletonText width="80" data-sentry-element="ProductUISkeletonText" data-sentry-source-file="product-ui-list-item.tsx" />
            </div>
            <div className={css.right}>{buttonSection}</div>
        </div>;
}
export { ProductUIBasicListItem, ProductUIPermissionListItem, ProductUIProjectListItem, ProductUIRoleListItem };