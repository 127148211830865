import cn from 'clsx';
import css from './product-ui-icon-button.module.css';
export type ProductUIIconButtonProps = {
  icon: React.ReactElement;
  state?: 'hovered' | 'active';
  size?: 'base' | 'lg';
};
function ProductUIIconButton({
  icon,
  size = 'lg',
  state
}: ProductUIIconButtonProps) {
  return <div className={cn(css.iconButton, css[size], state ? css[state] : null)} data-sentry-component="ProductUIIconButton" data-sentry-source-file="product-ui-icon-button.tsx">{icon}</div>;
}
export { ProductUIIconButton };