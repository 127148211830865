import { Children, isValidElement, useId } from 'react';
import { Box } from '@doist/marketist';
import cn from 'clsx';
import { IconRadioTile, type IconRadioTileProps } from './icon-radio-tile';
import { IconRadioTileContext } from './icon-radio-tile-context';
import css from './icon-radio-tile.module.css';
type IconRadioTileGroupProps = {
  children: React.ReactNode;
  value: string;
  className?: string;
  onChange: (value: string) => void;
};
export function IconRadioTileGroup({
  children,
  value,
  className,
  onChange
}: IconRadioTileGroupProps) {
  const id = useId();
  return <IconRadioTileContext.Provider value={{
    currentValue: value,
    name: id,
    onChange
  }} data-sentry-element="unknown" data-sentry-component="IconRadioTileGroup" data-sentry-source-file="icon-radio-tile-group.tsx">
            <Box tag="ul" className={cn(css.radioTileGroup, className)} data-sentry-element="Box" data-sentry-source-file="icon-radio-tile-group.tsx">
                {Children.map(children, (child, n) => {
        let key = n.toString();
        if (isValidElement<IconRadioTileProps>(child) && child.type === IconRadioTile) {
          key = child.props.value;
        }
        return <Box tag="li" key={key}>
                            {child}
                        </Box>;
      })}
            </Box>
        </IconRadioTileContext.Provider>;
}