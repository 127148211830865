import { cloneElement, useContext, useEffect, useRef } from 'react';
import { Text } from '@doist/marketist';
import cn from 'clsx';
import { ProductUIThemeContext } from 'components/product-ui/product-ui-theme-context';
import { elementIsVisibleInViewport } from 'services/element-is-visible-in-viewport';
import { useFeatureRadioTileContext } from './feature-radio-tile-context';
import css from './feature-radio-tile.module.css';
export type FeatureRadioTileProps = {
  icon?: React.ReactElement;
  title: string;
  children?: React.ReactNode;
  value: string;
  scene?: React.ReactNode;
  copySpacing?: boolean;
  variant?: 'seaSalt' | 'green' | 'red' | 'yellow';
};
const animationOptions = {
  duration: 150,
  ease: 'cubic-bezier(0.2, 0.8, 0.2, 1)'
};
export function FeatureRadioTile({
  icon,
  title,
  children,
  value,
  scene,
  variant,
  copySpacing = true
}: FeatureRadioTileProps) {
  const theme = useContext(ProductUIThemeContext);
  const {
    currentValue,
    onChange,
    name
  } = useFeatureRadioTileContext();
  const isChecked = currentValue === value;
  const renderCount = useRef(0);
  variant = variant ?? theme ?? 'red';
  const clonedIcon = icon ? cloneElement(icon, {
    width: 20,
    height: 20
  }) : null;
  useEffect(() => {
    if (!document) {
      return;
    }

    // We need to add forwardRef to the Text component
    const containerEl = document.querySelector<HTMLDivElement>(`[data-radio-tile="${value}"]`);
    let animation: Animation | null = null;
    if (!containerEl) {
      return;
    }
    if (isChecked) {
      containerEl.style.whiteSpace = 'normal';
      const startingHeight = containerEl.offsetHeight;

      // 24px is the scene container margin hidden by overflow hidden,
      // but still used to calculate the height scrollHeight.
      const desiredHeight = containerEl.scrollHeight - 24;
      animation = containerEl.animate({
        blockSize: [`${startingHeight}px`, `${desiredHeight > 0 ? desiredHeight : 0}px`]
      }, {
        ...animationOptions,
        duration: renderCount.current === 0 ? 0 : animationOptions.duration
      });
      animation.addEventListener('finish', () => {
        containerEl.style.height = 'auto';
        if (renderCount.current > 1) {
          // Only scroll into view when clicked radio tile's content will not be in the viewport.
          if (!elementIsVisibleInViewport(containerEl)) {
            containerEl.scrollIntoView({
              block: 'center'
            });
          }
        }
      });
    } else {
      const startingHeight = containerEl.offsetHeight;

      // FLIP technique
      // Set the style to where we want to end up
      // store the value
      // the reset back
      // animate to those desired values
      containerEl.style.whiteSpace = 'nowrap';
      containerEl.style.height = '';
      const desiredHeight = containerEl.offsetHeight;
      containerEl.style.whiteSpace = 'nowrap';
      containerEl.style.blockSize = `${startingHeight}px`;
      animation = containerEl.animate({
        blockSize: [`${startingHeight}px`, `${desiredHeight}px`]
      }, {
        ...animationOptions,
        duration: renderCount.current === 0 ? 0 : animationOptions.duration
      });
      animation.addEventListener('finish', () => {
        containerEl.style.whiteSpace = '';
        containerEl.style.blockSize = '';
      });
    }
    renderCount.current = renderCount.current + 1;
    return function featureRadioTileEffectCleanup() {
      if (animation) {
        animation.cancel();
      }
    };
  }, [isChecked, value]);
  return <Text data-radio-tile={value} tag="label" size="body-base" className={cn(css.featureRadioTile, css[variant], {
    [css.checked]: isChecked
  })} data-sentry-element="Text" data-sentry-component="FeatureRadioTile" data-sentry-source-file="feature-radio-tile.tsx">
            <div className={css.radioTileInner}>
                <input id={`radio-tile-${value}`} type="radio" name={`radio-group-${name}`} className={css.radioInput} onChange={() => {
        onChange(value);
      }} checked={isChecked} />
                {clonedIcon ? <div className={css.iconContainer}>{clonedIcon}</div> : null}
                <Text size="body-base" className={css.radioBody} weight="medium" data-sentry-element="Text" data-sentry-source-file="feature-radio-tile.tsx">
                    <Text size="inherit" tag="span" weight="semibold" className={css.titleText} data-sentry-element="Text" data-sentry-source-file="feature-radio-tile.tsx">
                        {title}
                    </Text>
                    {copySpacing ? ' ' : null}
                    {children ? <span className={css.descriptionText}>{children}</span> : null}
                </Text>
            </div>
            {scene ? <div className={css.scene}>{scene}</div> : null}
        </Text>;
}