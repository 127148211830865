import { createSharedPathnamesNavigation } from 'next-intl/navigation'

import { defaultLanguage, getAllLanguages } from './constants'

export const locales = getAllLanguages()
export const localePrefix = 'as-needed'
export const defaultLocale = defaultLanguage
export const {
    redirect,
    usePathname,
    useRouter,
}: {
    redirect: (path: string) => never
    usePathname: () => string
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    useRouter: () => any
} = createSharedPathnamesNavigation({
    locales,
    localePrefix,
})

export function unlocalizePathname(pathname: string, locale: string) {
    return pathname.replace(new RegExp(`^/${locale}`), '') || '/'
}
