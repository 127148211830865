import { type Languages } from '@doist/marketist'

export const CLOUDINARY_BASE_URL =
    'https://res.cloudinary.com/imagist/image/upload/h_114,e_colorize,o_48,co_rgb:17150f7a'

const companyLogoMapping: Record<string, CompanyLogoData> = {
    Adidas: { id: 'v1699459540/Adidas_kpghv9', width: 67, height: 56 },
    Adobe: { id: 'v1699459540/Adobe_mlfsq0', width: 46, height: 56 },
    Airbus: { id: 'v1699459540/Airbus_dfepp7', width: 120, height: 56 },
    Alfa: { id: 'v1699459540/Alfa_jdumm2', width: 29, height: 56 },
    Amazon: { id: 'v1699459540/Amazon_veb0zq', width: 103, height: 56 },
    AmericaMovil: { id: 'v1699459540/AmericaMovil_gdlhlk', width: 197, height: 56 },
    Avast: { id: 'v1699459541/Avast_ferlxq', width: 113, height: 56 },
    BlaBlaCar: { id: 'v1699459541/BlaBlaCar_neq6xi', width: 153, height: 56 },
    Bosch: { id: 'v1699459542/Bosch_or3c3r', width: 142, height: 56 },
    Calzedonia: { id: 'v1699459542/Calzedonia_zyzs3y', width: 199, height: 56 },
    Carlsberg: { id: 'v1699459542/Carlsberg_med0lh', width: 89, height: 56 },
    Cinkciarz: { id: 'v1699459542/Cinkciarz_yumrvz', width: 211, height: 56 },
    Disney: { id: 'v1699459543/Disney_qdwyib', width: 100, height: 56 },
    Doist: { id: 'v1708643598/Doist_Logo_x4kdnl', width: 105, height: 56 },
    EFES: { id: 'v1699459543/EFES_qdgtdb', width: 79, height: 56 },
    Equinor: { id: 'v1699459544/Equinor_gkjauj', width: 127, height: 56 },
    GrupoGlobo: { id: 'v1699459544/GrupoGlobo_uvmvna', width: 243, height: 56 },
    Gucci: { id: 'v1699459544/Gucci_ruhtur', width: 124, height: 56 },
    Handm: { id: 'v1699459545/Handm_guzs16', width: 62, height: 56 },
    Heineken: { id: 'v1699459545/Heineken_iu40jz', width: 106, height: 56 },
    Huawei: { id: 'v1699459545/Huawei_qjv6rs', width: 183, height: 56 },
    Hyundai: { id: 'v1699459545/Hyundai_ugqzwx', width: 79, height: 56 },
    Ikea: { id: 'v1699459546/Ikea_zxbu63', width: 101, height: 56 },
    Inditex: { id: 'v1699459546/Inditex_up6rmb', width: 174, height: 56 },
    JDBW: { id: 'v1699459547/JDBW_md4pex', width: 175, height: 56 },
    Kesko: { id: 'v1699459548/Kesko_isb2mi', width: 42, height: 56 },
    Kiwi: { id: 'v1699459548/Kiwi_vs8bcm', width: 61, height: 56 },
    Lego: { id: 'v1699459548/Lego_r1v8jh', width: 55, height: 56 },
    Lenovo: { id: 'v1699459548/Lenovo_suxf4y', width: 176, height: 56 },
    LOreal: { id: 'v1699459548/LOreal_nodxic', width: 139, height: 56 },
    Maildotru: { id: 'v1699459549/Maildotru_fshjya', width: 105, height: 56 },
    MercadoLivre: { id: 'v1699459549/MercadoLivre_oqiaqw', width: 159, height: 56 },
    Microsoft: { id: 'v1699459549/Microsoft_x9xttd', width: 151, height: 56 },
    Mondadori: { id: 'v1699459549/Mondadori_s1q1wp', width: 250, height: 56 },
    N26: { id: 'v1699459549/N26_tiqzl8', width: 59, height: 56 },
    Natura: { id: 'v1699459550/Natura_w3vwlz', width: 73, height: 56 },
    Netflix: { id: 'v1699459551/Neftlix_ux1ubc', width: 99, height: 56 },
    Nintendo: { id: 'v1699459551/Nintendo_ibrk0a', width: 164, height: 56 },
    Nokia: { id: 'v1699459551/Nokia_k8exmy', width: 131, height: 56 },
    NorwegianAir: { id: 'v1699459551/NorwegianAir_ek6aiv', width: 206, height: 56 },
    Nueca: { id: 'v1699459551/Nueca_gkuvzi', width: 103, height: 56 },
    NYU: { id: 'v1699459553/NYU_wxvcco', width: 119, height: 56 },
    Orange: { id: 'v1699459553/Orange_ugh5r8', width: 51, height: 56 },
    Pegatron: { id: 'v1699459553/Pegatron_kmv2bq', width: 169, height: 56 },
    Philips: { id: 'v1699459553/Philips_yxmlmj', width: 125, height: 56 },
    PlatigeImage: { id: 'v1699459553/PlatigeImage_roviqy', width: 172, height: 56 },
    Posco: { id: 'v1699459554/Posco_ounw7t', width: 128, height: 56 },
    Salesforce: { id: 'v1699459554/Salesforce_j3rouw', width: 64, height: 56 },
    Samsung: { id: 'v1699459554/Samsung_uj0nrz', width: 151, height: 56 },
    Shell: { id: 'v1699459555/Shell_o7wxhy', width: 51, height: 56 },
    Shopify: { id: 'v1699459555/Shopify_ysltjk', width: 142, height: 56 },
    Siemens: { id: 'v1699459555/Siemens_loxopk', width: 140, height: 56 },
    Skoda: { id: 'v1699459556/Skoda_kgcmif', width: 129, height: 56 },
    SoftBank: { id: 'v1699459556/SoftBank_yxdvuc', width: 191, height: 56 },
    Sony: { id: 'v1699459556/Sony_dy2tpn', width: 125, height: 56 },
    Spotify: { id: 'v1699459557/Spotify_w7snwi', width: 134, height: 56 },
    Supercell: { id: 'v1699459558/Supercell_gqcirc', width: 56, height: 56 },
    Teknosa: { id: 'v1699459558/Teknosa_htieb7', width: 146, height: 56 },
    Telenor: { id: 'v1699459558/Telenor_vecw0a', width: 164, height: 56 },
    Telefonica: { id: 'v1699459558/Telefonica_b3meih', width: 141, height: 56 },
    TikTok: { id: 'v1699459558/TikTok_h6dkz4', width: 102, height: 56 },
    TMobile: { id: 'v1699459559/TMobile_yz5mex', width: 123, height: 56 },
    Toyota: { id: 'v1699459560/Toyota_kvp7uy', width: 172, height: 56 },
    TurkishAirlines: { id: 'v1699459560/TurkishAirlines_ancpyt', width: 144, height: 56 },
    TurkTelekom: { id: 'v1699459560/TurkTelekom_zpwmch', width: 188, height: 56 },
    Vivo: { id: 'v1699459560/Vivo_vdzpaq', width: 117, height: 56 },
    VK: { id: 'v1699459561/VK_qrnlva', width: 51, height: 56 },
    Xiaomi: { id: 'v1699459562/Xiaomi_ibmwfd', width: 50, height: 56 },
    Yandex: { id: 'v1699459562/Yandex_vpdsoh', width: 147, height: 56 },
}

const defaultLogos: string[] = [
    'Microsoft',
    'Disney',
    'Amazon',
    'Netflix',
    'Adobe',
    'Salesforce',
    'Shopify',
    'NYU',
    'Orange',
]
const languageCompanyMapping: Map<Languages, string[]> = new Map([
    ['en', defaultLogos],
    ['cs', ['Skoda', 'Kiwi', 'TMobile', 'Avast']],
    ['da', ['Carlsberg', 'Lego']],
    ['de', ['Adidas', 'Bosch', 'N26', 'Siemens']],
    ['fi', ['Supercell', 'Nokia', 'Kesko']],
    ['fr', ['BlaBlaCar', 'Airbus', 'LOreal', 'Orange']],
    ['it', ['Calzedonia', 'Gucci', 'Mondadori']],
    ['ja', ['SoftBank', 'Sony', 'Nintendo', 'Toyota']],
    ['ko', ['Hyundai', 'Posco', 'Samsung']],
    ['nl', ['Philips', 'Shell', 'Heineken']],
    ['nb', ['NorwegianAir', 'Equinor', 'Telenor']],
    ['pl', ['Cinkciarz', 'Nueca', 'PlatigeImage']],
    ['pt-BR', ['Vivo', 'GrupoGlobo', 'MercadoLivre', 'Natura']],
    ['ru', []],
    ['es', ['AmericaMovil', 'Inditex', 'Telefonica']],
    ['sv', ['Handm', 'Ikea', 'Spotify']],
    ['tr', ['TurkTelekom', 'Teknosa', 'EFES', 'TurkishAirlines']],
    ['zh-CN', ['JDBW', 'Xiaomi', 'TikTok', 'Huawei']],
    ['zh-TW', ['Huawei', 'Lenovo', 'Pegatron']],
])

/** Return the Company names for a given language **/
export function getCompanyLogos(language: Languages, includeDoistLogo = false) {
    const logoCount = includeDoistLogo ? 5 : 6
    const numberOfLogos = Math.min(Math.max(logoCount, 1), defaultLogos.length)

    const companies = defaultLogos.slice()
    if (language !== 'en') {
        /**
         * Swap default logos with localized ones
         * The order should be:
         * 1. Default logos, as many as needed to fill space
         * 2. All localized logos (or `count`, if smaller)
         */
        const localizedCompanies = languageCompanyMapping.get(language)

        if (localizedCompanies?.length) {
            const startIndex = Math.max(numberOfLogos - localizedCompanies.length, 0)
            companies.splice(startIndex, localizedCompanies.length, ...localizedCompanies)
        }
    }

    const candidates = companies.slice(0, numberOfLogos)
    if (includeDoistLogo) {
        candidates.push('Doist')
    }

    return candidates.map((candidate) => companyLogoMapping[candidate])
}
