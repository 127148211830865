import { ChevronDown, FilterCircle, Person } from '@doist/icons/product-ui';
import { useTranslation } from 'next-i18next';
import { ProductUIButton } from '../product-ui-button/product-ui-button';
import { ProductUIIconButton } from '../product-ui-icon-button/product-ui-icon-button';
import { ProductUIViewHeaderBase, type ProductUIViewHeaderBaseProps } from './product-ui-view-header-base';
function ProductUIViewHeaderActivity({
  breadcrumbs = [],
  ...rest
}: ProductUIViewHeaderBaseProps) {
  const {
    t
  } = useTranslation('productui');
  return <ProductUIViewHeaderBase breadcrumbs={breadcrumbs} actions={<>
                    <ProductUIButton variant="quinary" startIcon={<Person />}>
                        {t('sequence.activityHistory.everyone')}
                    </ProductUIButton>
                    <ProductUIButton variant="quinary" startIcon={<FilterCircle />}>
                        {t('view.view')}
                    </ProductUIButton>
                </>} titleAction={<ProductUIIconButton icon={<ChevronDown />} />} {...rest} data-sentry-element="ProductUIViewHeaderBase" data-sentry-component="ProductUIViewHeaderActivity" data-sentry-source-file="product-ui-view-header-activity.tsx" />;
}
export { ProductUIViewHeaderActivity };