import { Assign, ChevronDown, MoreOutline, Options, SidebarRight } from '@doist/icons/product-ui';
import { Text } from '@doist/marketist';
import { useTranslation } from 'next-i18next';
import { ProductUIButton } from '../product-ui-button/product-ui-button';
import { ProductUIIconButton } from '../product-ui-icon-button/product-ui-icon-button';
import { ProductUIOutlineButtonGroup } from '../product-ui-outline-button-group/product-ui-outline-button-group';
import { ProductUIViewHeaderBase, type ProductUIViewHeaderBaseProps } from './product-ui-view-header-base';
import css from './product-ui-view-header.module.css';
export function ProductUIViewHeaderCalendar({
  children,
  ...rest
}: ProductUIViewHeaderBaseProps) {
  const {
    t,
    i18n
  } = useTranslation('productui');
  const today = new Date();
  return <ProductUIViewHeaderBase fullWidth={true} minPadding={true} noBottomPadding={true} actions={<>
                    <ProductUIButton variant="quinary" startIcon={<Assign />}>
                        {t('share')}
                    </ProductUIButton>
                    <ProductUIButton variant="quinary" startIcon={<Options />}>
                        {t('view.view')}
                    </ProductUIButton>
                    <ProductUIIconButton icon={<MoreOutline />} />
                </>} {...rest} data-sentry-element="ProductUIViewHeaderBase" data-sentry-component="ProductUIViewHeaderCalendar" data-sentry-source-file="product-ui-view-header-calendar.tsx">
            <div className={css.calendarControls}>
                {children ? children : <div className={css.calendarControlsDefault}>
                        <ProductUIViewHeaderCalendar.Subtitle>
                            <Text size="body-xs" weight="medium">
                                {today.toLocaleString(i18n.language, {
              month: 'long',
              year: 'numeric'
            })}
                            </Text>
                        </ProductUIViewHeaderCalendar.Subtitle>
                        <ProductUIOutlineButtonGroup startIcon={<ProductUIOutlineButtonGroup.IconButton direction="left" />} button={<ProductUIButton>{t('calendarView.today')}</ProductUIButton>} endIcon={<ProductUIOutlineButtonGroup.IconButton direction="right" />} />
                        <ProductUIButton variant="outline" endIcon={<SidebarRight />}>
                            {t('calendarView.plan')}
                        </ProductUIButton>
                    </div>}
            </div>
        </ProductUIViewHeaderBase>;
}
ProductUIViewHeaderCalendar.Subtitle = function ({
  children
}: {
  children: React.ReactNode;
}) {
  return <div className={css.calendarControlsSubtitle}>
            {children}
            <ChevronDown />
        </div>;
};