import { Box } from '@doist/marketist';
import cn from 'clsx';
import { ProductUIFrame } from '../product-ui-frame/product-ui-frame';
import { ProductUIFrameBackground, type ProductUIFrameBackgroundProps } from '../product-ui-frame-background/product-ui-frame-background';
import css from './product-ui-scene-container.module.css';
import type { ReactNode } from 'react';
export type ProductUISceneContainerProps = {
  appWindowSize?: 'base' | 'lg';
  backgroundVariant?: ProductUIFrameBackgroundProps['variant'];
  children?: ReactNode;
  className?: string;
  style?: React.CSSProperties;
};
function ProductUISceneContainer({
  appWindowSize = 'base',
  children,
  backgroundVariant,
  className,
  style
}: ProductUISceneContainerProps) {
  return <ProductUIFrame size={appWindowSize} data-sentry-element="ProductUIFrame" data-sentry-component="ProductUISceneContainer" data-sentry-source-file="product-ui-scene-container.tsx">
            <Box className={cn(css.sceneContainer, className)} style={style} data-sentry-element="Box" data-sentry-source-file="product-ui-scene-container.tsx">
                {backgroundVariant ? <ProductUIFrameBackground className={css.frameBackground} variant={backgroundVariant} /> : null}

                {children}
            </Box>
        </ProductUIFrame>;
}
type ProductUISceneViewProps = {
  children: ReactNode;
  position?: 'base' | 'topRight' | 'topLeft' | 'bottomCenter' | 'bottomLeft' | 'bottomRight' | 'bottomOverlay' | 'bottom' | 'center' | 'quickAdd' | 'modalOpenRight' | 'modalOpenTopRight' | 'modalOpenLeft' | 'modalOpenTopLeft' | 'modalOpenBottomRight';
};
export function ProductUISceneLayer({
  children,
  position = 'base'
}: ProductUISceneViewProps) {
  return <Box className={cn(css.sceneLayer, css[position])} data-sentry-element="Box" data-sentry-component="ProductUISceneLayer" data-sentry-source-file="product-ui-scene-container.tsx">{children}</Box>;
}
export { ProductUISceneContainer };