const defaultLanguage = 'en'

const otherLanguages = [
    'ru',
    'fr',
    'nl',
    'pt-BR',
    'zh-CN',
    'ko',
    'nb',
    'de',
    'sv',
    'tr',
    'it',
    'da',
    'pl',
    'fi',
    'zh-TW',
    'ja',
    'es',
    'cs',
]

const contentfulLanguageMap = new Map([
    ['cs', 'Czech'],
    ['da', 'Danish'],
    ['en', 'English'],
    ['de', 'German'],
    ['es', 'Spanish'],
    ['fi', 'Finnish'],
    ['fr', 'French'],
    ['it', 'Italian'],
    ['nl', 'Dutch'],
    ['nb', 'Norwegian'],
    ['pl', 'Polish'],
    ['sv', 'Swedish'],
    ['pt-BR', 'Portuguese'],
    ['ru', 'Russian'],
    ['tr', 'Turkish'],
    ['zh-TW', 'Traditional Chinese'],
    ['zh-CN', 'Simplified Chinese'],
    ['ko', 'Korean'],
    ['ja', 'Japanese'],
])

const localeToContentfulLangCode = new Map([
    ['en', 'en'],
    ['ja', 'ja'],
    ['pt-BR', 'pt'],
    ['ru', 'ru'],
    ['es', 'es'],
    ['nl', 'nl'],
    ['de', 'de'],
    ['it', 'it'],
    ['fr', 'fr'],
    ['pl', 'pl'],
    ['cs', 'cs'],
    ['ko', 'ko'],
    ['tr', 'tr'],
    ['zh-CN', 'zh-CN'],
    ['zh-TW', 'zh-Hant-TW'],
    ['da', 'da'],
    ['nb', 'nb'],
    ['sv', 'sv'],
    ['fi', 'fi'],
])

const contentfulLanguages = [
    'en',
    'ja',
    'pt',
    'ru',
    'es',
    'nl',
    'de',
    'it',
    'fr',
    'pl',
    'cs',
    'ko',
    'tr',
]

function getAllLanguages() {
    return [defaultLanguage, ...otherLanguages]
}

module.exports = {
    defaultLanguage,
    otherLanguages,
    contentfulLanguages,
    contentfulLanguageMap,
    localeToContentfulLangCode,
    getAllLanguages,
    allLanguages: [defaultLanguage, ...otherLanguages],
}
