import { WidgetSettings } from '@doist/icons/android';
import { Group } from '@doist/icons/product-ui';
import { Divider } from '@doist/marketist';
import { useTranslation } from 'next-i18next';
import { ProductUIButton } from '../product-ui-button/product-ui-button';
import { ProductUIViewHeaderBase, type ProductUIViewHeaderBaseProps } from './product-ui-view-header-base';
function ProductUIViewHeaderWorkspace({
  breadcrumbs = [],
  titleAvatar = 'team',
  ...rest
}: ProductUIViewHeaderBaseProps) {
  const {
    t
  } = useTranslation('productui');
  return <ProductUIViewHeaderBase breadcrumbs={breadcrumbs} actions={<>
                    <ProductUIButton variant="quinary" startIcon={<Group />}>
                        {t('members')}
                    </ProductUIButton>
                    <ProductUIButton variant="quinary" startIcon={<WidgetSettings />}>
                        {t('settings')}
                    </ProductUIButton>
                </>} titleAvatar={titleAvatar} noBottomPadding={true} {...rest} data-sentry-element="ProductUIViewHeaderBase" data-sentry-component="ProductUIViewHeaderWorkspace" data-sentry-source-file="product-ui-view-header-workspace.tsx">
            <div className="pbs-24">
                <Divider data-sentry-element="Divider" data-sentry-source-file="product-ui-view-header-workspace.tsx" />
            </div>
        </ProductUIViewHeaderBase>;
}
export { ProductUIViewHeaderWorkspace };