import { useContext } from 'react';
import { Image } from '@doist/marketist';
import cn from 'clsx';
import { ProductUIThemeContext } from '../product-ui-theme-context';
import css from './product-ui-frame-background.module.css';
import type { ProductUITheme } from '../product-ui-types';
export type ProductUIFrameBackgroundProps = {
  id?: string;
  className?: string;
  variant?: 'wave-one' | 'wave-two' | 'wave-three' | 'wave-four';
};
function convertThemeCase(theme: ProductUITheme) {
  return theme === 'seaSalt' ? 'sea-salt' : theme;
}
type ThemeNameKebabCase = ReturnType<typeof convertThemeCase>;

/**
 * Maps the theme to the CSS custom properties that are used to set the background colour
 */
function themeToCSSProp(theme: ThemeNameKebabCase) {
  return {
    light: `--productui-background-light-${theme}`,
    dark: `--productui-background-dark-${theme}`
  } as const;
}
export function ProductUIFrameBackground({
  id,
  className,
  variant = 'wave-one'
}: ProductUIFrameBackgroundProps) {
  const theme = useContext(ProductUIThemeContext);
  const themeName = convertThemeCase(theme);
  const {
    light,
    dark
  } = themeToCSSProp(themeName);
  const imagePath = `${process.env.NEXT_PUBLIC_BASE_URL}/static/product-ui/backgrounds/${variant}-${themeName}.png` as const;
  const customProps = {
    '--light-color': `var(${light})`,
    '--dark-color': `var(${dark})`
  };
  return <div id={id} className={cn(css.framebackground, className)} style={customProps} data-sentry-component="ProductUIFrameBackground" data-sentry-source-file="product-ui-frame-background.tsx">
            <Image src={imagePath} width={590} height={590} alt="" className={css.image} data-sentry-element="Image" data-sentry-source-file="product-ui-frame-background.tsx" />
        </div>;
}