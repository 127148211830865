import { useContext } from 'react';
import cn from 'clsx';
import { ProductUIThemeContext } from '../product-ui-theme-context';
import css from './product-ui-box-shadow.module.css';

/*
 * Instead of applying a box-shadow to a ProductUI element such `QuickAdd`, or `AppWindow` with in
 * a ProductUI scene, use `ProductUIBoxShadow`. This extra element allows the use of the `mix-blend-mode`
 * CSS property which blends the shadow across different background colors.
 */
function ProductUIBoxShadow() {
  const theme = useContext(ProductUIThemeContext);
  // There is a bug with Safari where `mix-blend-mode` doesn't work correctly
  // with elements that have 3d transforms (applied by GSAP).
  // Box shadows are turned off for Safari until this is fixed.
  return <div className={cn(css.boxShadow, css[theme], 'display-none-safari-only')} data-sentry-component="ProductUIBoxShadow" data-sentry-source-file="product-ui-box-shadow.tsx" />;
}
export { ProductUIBoxShadow };