import { Children, createContext, type ReactNode } from 'react';
import { ProductUIWeeklyCalendarAllDays, ProductUIWeeklyCalendarDayDates, ProductUIWeeklyCalendarDayTimes, ProductUIWeeklyCalendarFold } from '../product-ui-weekly-calendar-day/product-ui-weekly-calendar-day';
import css from './product-ui-view.module.css';
export type ProductUIWeeklyCalendarViewContextProps = {
  hourHeight: number;
};
export const ProductUIWeeklyCalendarViewContext = createContext<ProductUIWeeklyCalendarViewContextProps>({
  hourHeight: 45
});
export type ProductUIViewWeeklyCalendarViewProps = {
  children: ReactNode;
  currentDayPosition?: number;
  hourOffset: number; /** Decimal (e.g. 8:30 -> 8.5) */
  foldHour?: number; /** Decimal (e.g. 8:30 -> 8.5) */
  allDayTasks?: ReactNode[][];
  abstractedDate?: boolean;
  hourHeight?: number;
};
export function ProductUIViewWeeklyCalendarView({
  children,
  hourOffset,
  foldHour,
  currentDayPosition,
  allDayTasks,
  abstractedDate,
  hourHeight
}: ProductUIViewWeeklyCalendarViewProps) {
  const dayCount = Children.count(children);
  // If no currentDayPosition is provided, put the current day in the center.
  const resolvedDayPosition = currentDayPosition !== undefined ? currentDayPosition : Math.ceil(dayCount / 2) - 1;
  const numberOfDisplayedDays = Children.count(children);
  // The height of an hour is dynamic and based on the number of days we display.
  // For example: when the view is crowed with 5 days, the hour height is 45px,
  // when there is a single day (Today View), the hour height is 66px.
  const hHeight = hourHeight ?? Math.round(-5.25 * numberOfDisplayedDays + 71.25);
  return <ProductUIWeeklyCalendarViewContext.Provider value={{
    hourHeight: hHeight
  }} data-sentry-element="unknown" data-sentry-component="ProductUIViewWeeklyCalendarView" data-sentry-source-file="product-ui-view-weekly-calendar-view.tsx">
            <div className={css.weeklyCalendarView} style={{
      '--hour-anchor': hourOffset,
      '--day-count': dayCount,
      '--current-day-position': resolvedDayPosition,
      '--hour-height': `${hHeight}px`
    }}>
                {numberOfDisplayedDays > 1 ? <ProductUIWeeklyCalendarDayDates dayCount={dayCount} currentDayPosition={resolvedDayPosition} abstractedDate={abstractedDate} /> : null}
                <ProductUIWeeklyCalendarAllDays data-sentry-element="ProductUIWeeklyCalendarAllDays" data-sentry-source-file="product-ui-view-weekly-calendar-view.tsx">
                    {Children.map(children, (_, i) => <div key={`day-${i}`}>{allDayTasks ? allDayTasks[i] : null}</div>)}
                </ProductUIWeeklyCalendarAllDays>
                <div className={css.weeklyCalendarViewDetails}>
                    <div className={css.weeklyCalendarViewAbsoluteContainer}>
                        <div className={css.weeklyCalendarViewContainer}>
                            <ProductUIWeeklyCalendarDayTimes data-sentry-element="ProductUIWeeklyCalendarDayTimes" data-sentry-source-file="product-ui-view-weekly-calendar-view.tsx" />
                            {children}
                        </div>
                        {foldHour !== undefined ? <ProductUIWeeklyCalendarFold foldHour={foldHour} /> : null}
                    </div>
                </div>
            </div>
        </ProductUIWeeklyCalendarViewContext.Provider>;
}