'use client';

import { useContext } from 'react';
import { AlarmClock, Checkbox, Download, ExtensionBase, Group, Help, LightBulbBase, LightningBoltBase, List, QuestionMark, SwatchbookBase } from '@doist/icons/brand';
import { Button, Container, Navbar, NavbarBanner } from '@doist/marketist';
import { useTranslation } from 'app/i18n/client';
import { NavbarItemTracked } from 'components/header/navbar-item-track';
import { usePathname } from 'i18n/navigation';
import { useLocale } from 'next-intl';
import { getLoginUrl, registerUrl } from 'services/authUrls';
import { GTM_ID_NAVBAR_ITEM } from 'services/gtm';
import { useCTAsData } from 'services/hooks/use-ctas-data';
import { useInAppBrowser } from 'services/hooks/use-in-app-browser';
import { useBannerStatus } from 'services/hooks/useBannerStatus';
import css from './header.module.css';
import type { PageBanner } from 'types/contentful';
import { UserContextV2 } from '@/app/services/providers/contexts/user-context';
import { FeatureFlagContext } from '@/services/contexts/feature-flag-context';
export type TDHeaderProps = {
  pageBanner?: PageBanner;
  bottomElement?: React.ReactNode;
};
export default function TDHeader({
  bottomElement,
  pageBanner
}: TDHeaderProps) {
  const locale = useLocale();
  const {
    t
  } = useTranslation(locale, 'common');
  const isInAppBrowser = useInAppBrowser();
  const {
    user
  } = useContext(UserContextV2);
  const upgradeButtonData = useCTAsData('all-pages-nav-bar', usePathname());
  const {
    test_nav_solutions
  } = useContext(FeatureFlagContext);
  const {
    bannerNotSeen,
    onCloseBanner,
    renderBanner
  } = useBannerStatus(pageBanner);
  const resourcesDropdown = [{
    icon: ExtensionBase,
    i18nKey: 'integrations',
    href: '/integrations'
  }, {
    icon: SwatchbookBase,
    i18nKey: 'templates',
    href: '/templates'
  }, {
    icon: LightningBoltBase,
    i18nKey: 'gettingStarted',
    href: '/help/articles/get-started-with-todoist-OgNNJR'
  }, {
    icon: Help,
    i18nKey: 'helpCenter',
    href: '/help'
  }, {
    icon: QuestionMark,
    i18nKey: 'productivityMethods',
    href: '/productivity-methods'
  }, {
    icon: LightBulbBase,
    i18nKey: 'inspiration',
    href: '/inspiration'
  }, {
    icon: Download,
    i18nKey: 'downloads',
    href: '/downloads'
  }];
  return isInAppBrowser ? null : <Navbar banner={pageBanner && bannerNotSeen() ? <NavbarBanner dismissible={pageBanner.dismissible} onCloseClicked={onCloseBanner}>
                        {renderBanner()}
                    </NavbarBanner> : undefined} bottomElement={bottomElement} drawer={<Navbar.Drawer>
                    <Navbar.ItemGroup gap="12">
                        <NavbarItemTracked href="/features" label={t('features')} />
                        {test_nav_solutions ? <Navbar.Item label={t('navTest.madeFor')}>
                                <NavbarItemTracked href="/task-management" label={t('navTest.taskManagement')} />
                                <NavbarItemTracked href="/project-management" label={t('navTest.projectManagement')} />
                                <NavbarItemTracked href="/time-management" label={t('navTest.timeManagement')} />
                                <NavbarItemTracked href="/teamwork" label={t('navTest.teamwork')} />
                            </Navbar.Item> : <NavbarItemTracked href="/teamwork" label={t('forTeams')} />}
                        <Navbar.Item label={t('resources')}>
                            {resourcesDropdown.map(resource => <NavbarItemTracked key={resource.href} href={resource.href} label={t(`header.resourcesDropdown.${resource.i18nKey}.title`)} />)}
                        </Navbar.Item>
                        <NavbarItemTracked href="/pricing" label={t('pricing')} />
                    </Navbar.ItemGroup>
                    <Navbar.Divider spacing="16" />
                    <div style={{
      display: 'flex',
      gap: 'var(--space-12)'
    }}>
                        {user ? <Button style={{
        flex: '1'
      }} variant={upgradeButtonData ? 'quaternary' : 'primary'} size="large" href="/app">
                                {t('openTodoist')}
                            </Button> : <>
                                <Button style={{
          flex: '1'
        }} variant="secondary" size="large" href={getLoginUrl()}>
                                    {t('login')}
                                </Button>
                                <Button style={{
          flex: '1'
        }} variant="primary" size="large" href={registerUrl}>
                                    {t('startForFree')}
                                </Button>
                            </>}
                    </div>

                    {upgradeButtonData && <div style={{
      display: 'flex',
      marginBlockStart: 'var(--space-12)'
    }}>
                            <Button style={{
        flex: '1'
      }} variant={upgradeButtonData.variant} size="large" href={upgradeButtonData.href} {...upgradeButtonData.dataAttributes}>
                                {upgradeButtonData.text}
                            </Button>
                        </div>}
                </Navbar.Drawer>} data-sentry-element="Navbar" data-sentry-component="TDHeader" data-sentry-source-file="header.tsx">
            <Container width="lg" padded={false} className={css.header} data-sentry-element="Container" data-sentry-source-file="header.tsx">
                <Navbar.Logo aria-label={t('home')} data-sentry-element="unknown" data-sentry-source-file="header.tsx" />
                <Navbar.Section alignment="end" visibility="desktop" data-sentry-element="unknown" data-sentry-source-file="header.tsx">
                    <Navbar.ItemGroup gap={2} data-sentry-element="unknown" data-sentry-source-file="header.tsx">
                        <NavbarItemTracked href="/features" label={t('features')} data-sentry-element="NavbarItemTracked" data-sentry-source-file="header.tsx" />
                        {test_nav_solutions ? <Navbar.Item label={t('navTest.madeFor')}>
                                <Navbar.DropdownListItem icon={<Checkbox />} href="/task-management" title={t('navTest.taskManagement')} data-gtm-id={GTM_ID_NAVBAR_ITEM} />
                                <Navbar.DropdownListItem icon={<List />} href="/project-management" title={t('navTest.projectManagement')} data-gtm-id={GTM_ID_NAVBAR_ITEM} />
                                <Navbar.DropdownListItem icon={<AlarmClock />} href="/time-management" title={t('navTest.timeManagement')} data-gtm-id={GTM_ID_NAVBAR_ITEM} />
                                <Navbar.DropdownListItem icon={<Group />} href="/teamwork" title={t('navTest.teamwork')} data-gtm-id={GTM_ID_NAVBAR_ITEM} />
                            </Navbar.Item> : <NavbarItemTracked href="/teamwork" label={t('forTeams')} />}
                        <Navbar.Item label={t('resources')} data-sentry-element="unknown" data-sentry-source-file="header.tsx">
                            {resourcesDropdown.map(resource => <Navbar.DropdownListItem key={resource.href} icon={<resource.icon width={18} height={18} color="var(--display-onlight-tertiary)" />} href={resource.href} title={t(`header.resourcesDropdown.${resource.i18nKey}.title`)} data-gtm-id={GTM_ID_NAVBAR_ITEM} />)}
                        </Navbar.Item>
                        <NavbarItemTracked href="/pricing" label={t('pricing')} data-sentry-element="NavbarItemTracked" data-sentry-source-file="header.tsx" />
                    </Navbar.ItemGroup>
                    <Navbar.Divider spacing="8" data-sentry-element="unknown" data-sentry-source-file="header.tsx" />
                    <Navbar.ItemGroup gap="8" data-sentry-element="unknown" data-sentry-source-file="header.tsx">
                        {user ? <>
                                <Navbar.Item href="/app" label={t('openTodoist')} variant={upgradeButtonData ? 'quaternary' : 'secondary'} />
                                {upgradeButtonData && <Navbar.Item href={upgradeButtonData.href} variant={upgradeButtonData.variant} label={upgradeButtonData.text} {...upgradeButtonData.dataAttributes} />}
                            </> : <>
                                <Navbar.Item href={getLoginUrl()} label={t('login')} />
                                <Navbar.Item href={registerUrl} variant="primary" label={t('startForFree')} />
                            </>}
                    </Navbar.ItemGroup>
                </Navbar.Section>
                <Navbar.Section alignment="end" visibility="mobile" data-sentry-element="unknown" data-sentry-source-file="header.tsx">
                    <Navbar.ItemGroup data-sentry-element="unknown" data-sentry-source-file="header.tsx">
                        <li>
                            <Navbar.DrawControl labelOpen={t('openMenu')} labelClose={t('closeMenu')} data-sentry-element="unknown" data-sentry-source-file="header.tsx" />
                        </li>
                    </Navbar.ItemGroup>
                </Navbar.Section>
            </Container>
        </Navbar>;
}