export type GTMConfiguration = {
    gtmId: string
    environment?: {
        auth: string
        preview: string
    }
}

export function getGTMConfiguration(): GTMConfiguration | null {
    /** GTM configuration */
    const gtmId = process.env.GTM_CONTAINER_ID
    if (!gtmId || gtmId.length === 0) {
        return null
    }
    const gtmAuth = process.env.GTM_AUTH
    const gtmPreview = process.env.GTM_PREVIEW
    let environment
    if (gtmAuth && gtmAuth.length > 0 && gtmPreview && gtmPreview.length > 0) {
        environment = { auth: gtmAuth, preview: gtmPreview }
    }
    return { gtmId, environment }
}

export const GTM_ID_SIGN_UP_BUTTON = 'sign-up-button'
export const GTM_ID_LOG_IN_BUTTON = 'log-in-button'
export const GTM_ID_ADD_A_TEAM_BUTTON = 'add-a-team-button'
export const GTM_ID_UPGRADE_TEAM_BUTTON = 'upgrade-team-button'
export const GTM_ID_NAVBAR_ITEM = 'navbar'
