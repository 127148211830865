import { getUserPlan } from './get-user-plan'

import type { Currency } from '@doist/currency-notations'
import type { BackendContext } from './get-backend-context'

import { Plan } from '@/types/user'

function push(event: Record<string, unknown>) {
    if (typeof window !== 'undefined') {
        window.dataLayer = window.dataLayer ?? []
        window.dataLayer?.push(event)
    }
}

type ItemProps = {
    coupon?: string
    discount?: number
    item_variant?: string
    price?: number
    quantity?: number
}

function createGAEcommerceObject(
    plan: Plan = 'free',
    currency: Currency = 'usd',
    price?: number,
    itemOverrides: ItemProps = {},
) {
    return {
        ...(typeof price === 'number' ? { currency, value: price } : null),
        items: [
            {
                item_id: plan,
                item_name: plan,
                ...itemOverrides,
            },
        ],
    }
}

function analyticsEvent(name: 'search', params: { search_term: string }): void
function analyticsEvent(name: string, params?: Record<string, unknown>): void
function analyticsEvent(name: string, params?: Record<string, unknown>) {
    push({
        event: name,
        ...params,
    })
}

function syntheticPageView(pathway: string, title: string, referrer: string) {
    const pageLocation = new URL(pathway, process.env.NEXT_PUBLIC_BASE_URL)

    analyticsEvent('synthetic_page_view', {
        page_location: pageLocation.toString(),
        page_title: title,
        page_referrer: referrer,
    })
}

function setupPropertiesFromContext(context: BackendContext) {
    if (context.user) {
        const userPlan = getUserPlan(context.user)
        const workspacesCount = context.workspaces.length
        const highestTeamPlan = context.workspaces.some((w) => w.plan === 'BUSINESS')
            ? 'business'
            : 'starter'

        push({
            user_subscription: userPlan,
            workspace_count: workspacesCount,
            workspace_subscription: workspacesCount ? highestTeamPlan : undefined,
            user_id: context.user.id,
        })
    }
}

export {
    analyticsEvent,
    createGAEcommerceObject,
    push,
    setupPropertiesFromContext,
    syntheticPageView,
}
