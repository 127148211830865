import { cloneElement, useContext } from 'react';
import { Text } from '@doist/marketist';
import cn from 'clsx';
import { ProductUIThemeContext } from 'components/product-ui/product-ui-theme-context';
import { useIconRadioTileContext } from './icon-radio-tile-context';
import css from './icon-radio-tile.module.css';
export type IconRadioTileProps = {
  icon?: React.ReactElement;
  children: React.ReactNode;
  value: string;
  variant?: 'seaSalt' | 'green' | 'red' | 'yellow';
};
export function IconRadioTile({
  icon,
  children,
  value,
  variant
}: IconRadioTileProps) {
  const theme = useContext(ProductUIThemeContext);
  const {
    currentValue,
    name,
    onChange
  } = useIconRadioTileContext();
  const isChecked = currentValue === value;
  variant = variant ?? theme ?? 'red';
  const iconComponent = icon ? cloneElement(icon, {
    width: 20,
    height: 20,
    className: css.icon
  }) : null;
  return <Text tag="label" size="minor-base" weight="semibold" className={cn(css.iconRadioTile, css[variant], {
    [css.checked]: isChecked
  })} data-sentry-element="Text" data-sentry-component="IconRadioTile" data-sentry-source-file="icon-radio-tile.tsx">
            <input type="radio" name={`icon-radio-group-${name}`} id={`icon-radio-tile-${value}`} className={css.input} onChange={() => onChange(value)} checked={isChecked} />
            {iconComponent}
            {children}
        </Text>;
}