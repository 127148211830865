import { Fragment, type ReactNode } from 'react';
import { SidebarLeft } from '@doist/icons/product-ui';
import { Image, Text } from '@doist/marketist';
import cn from 'clsx';
import { useTranslation } from 'next-i18next';
import { getDefaultAvatar } from '../consts';
import { ProductUIButton } from '../product-ui-button/product-ui-button';
import { ProductUIIconButton } from '../product-ui-icon-button/product-ui-icon-button';
import { ProductUISkeletonText } from '../product-ui-skeleton-text/product-ui-skeleton-text';
import css from './product-ui-view-header.module.css';
function Breadcrumbs({
  items
}: {
  items: string[];
}) {
  return <div className={css.breadcrumbs} data-sentry-component="Breadcrumbs" data-sentry-source-file="product-ui-view-header-base.tsx">
            {items.map((label, i, {
      length
    }) => {
      return <Fragment key={label}>
                        <ProductUIButton variant="quinary">{label}</ProductUIButton>
                        {i < length - 1 ? <Text tag="span" size="minor-xs" weight="medium" className={css.breadcrumbsSeparator}>
                                /
                            </Text> : null}
                    </Fragment>;
    })}
        </div>;
}
export type ProductUIViewHeaderBaseProps = {
  breadcrumbs?: string[];
  showSidebarToggle?: boolean;
  actions?: ReactNode;
  children?: ReactNode;
  fullWidth?: boolean;
  minPadding?: boolean;
  noBottomPadding?: boolean;
  title?: string | number;
  titleAvatar?: 'team' | 'personal';
  titleAction?: ReactNode;
  contextMenu?: ReactNode;
};
export function ProductUIViewHeaderBase({
  breadcrumbs,
  showSidebarToggle = false,
  actions,
  children,
  fullWidth = false,
  minPadding = false,
  noBottomPadding = false,
  title,
  titleAvatar,
  titleAction,
  contextMenu
}: ProductUIViewHeaderBaseProps) {
  const {
    t
  } = useTranslation('productui');
  if (typeof breadcrumbs === 'undefined') {
    breadcrumbs = [t('team'), ''];
  }
  return <div className={css.header} data-sentry-component="ProductUIViewHeaderBase" data-sentry-source-file="product-ui-view-header-base.tsx">
            {contextMenu ? contextMenu : null}

            <div className={css.top}>
                <div className={css.breadcrumbsWrapper}>
                    {showSidebarToggle ? <ProductUIIconButton icon={<SidebarLeft />} /> : null}{' '}
                    {breadcrumbs ? <Breadcrumbs items={breadcrumbs} /> : null}
                </div>
                <div className={css.actions}>{actions ? actions : null}</div>
            </div>

            <div className={cn(css.bottom, {
      [css.fullWidth]: fullWidth,
      [css.minPadding]: minPadding,
      [css.noBottomPadding]: noBottomPadding
    })}>
                <div className={css.title}>
                    {titleAvatar ? <Image src={getDefaultAvatar(titleAvatar === 'team')} width={34} height={34} alt="" /> : null}
                    {title ? typeof title === 'string' ? <Text tag="span" size="major-base" weight="bold">
                                {title}
                            </Text> : <ProductUISkeletonText height="15" width={title} /> : null}
                    {titleAction ? titleAction : null}
                </div>
                {children ? children : null}
            </div>
        </div>;
}