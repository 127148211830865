import { cloneElement, type ReactElement } from 'react';
import { Text } from '@doist/marketist';
import cn from 'clsx';
import css from './product-ui-button.module.css';
export type ProductUIButtonProps = {
  id?: string;
  variant?: 'primary' | 'secondary' | 'quaternary' | 'quinary' | 'outline';
  children?: React.ReactNode;
  startIcon?: ReactElement;
  endIcon?: ReactElement;
  className?: string;
  hovered?: boolean;
};
export function ProductUIButton({
  id,
  variant = 'primary',
  children,
  startIcon,
  endIcon,
  hovered,
  className,
  ...rest
}: ProductUIButtonProps) {
  const iconSize = variant !== 'outline' ? 24 : 18;
  const iconProps = {
    className: css.icon,
    width: iconSize,
    height: iconSize
  };
  const StartIcon = startIcon ? cloneElement(startIcon, iconProps) : null;
  const EndIcon = endIcon ? cloneElement(endIcon, iconProps) : null;
  const isSmallVariant = ['quinary', 'outline'].includes(variant);
  const size = isSmallVariant ? 'minor-xs' : 'minor-sm';
  const weight = isSmallVariant ? 'medium' : 'semibold';
  return <div id={id} className={cn(css.button, className, css[variant], {
    [css.hovered]: hovered
  })} {...rest} data-sentry-component="ProductUIButton" data-sentry-source-file="product-ui-button.tsx">
            {StartIcon}
            {children ? <Text tag="div" size={size} weight={weight}>
                    {children}
                </Text> : null}
            {EndIcon}
        </div>;
}