export function getLoginUrl(successPath?: string, searchParams: { [key: string]: string } = {}) {
    const webAppBaseURL = process.env.NEXT_PUBLIC_WEBAPP_URL
    const landingPagesBaseURL = process.env.NEXT_PUBLIC_BASE_URL

    let baseURL = webAppBaseURL
    let path = '/auth/login'

    const APIMocking = process.env.NEXT_PUBLIC_API_MOCKING === 'enabled'

    if (APIMocking) {
        baseURL = landingPagesBaseURL
        path = '/mock-login'
    }

    if (successPath) {
        if (successPath.startsWith('/app/')) {
            if (webAppBaseURL.startsWith('http')) {
                successPath = new URL(successPath, webAppBaseURL).toString()
            }
        }

        const params = new URLSearchParams(searchParams)
        if (params) {
            successPath = `${successPath}?${params.toString()}`
        }

        successPath = new URL(successPath, landingPagesBaseURL).toString()
        path += `?success_page=${encodeURIComponent(successPath)}`
    }

    return `${baseURL}${path}`
}

export const registerUrl = `${process.env.NEXT_PUBLIC_WEBAPP_URL}/auth/signup` as const

export function getLogoutUrl(successPath?: string) {
    let path = '/logout'
    if (successPath) {
        path += '?redirect_uri=' + encodeURIComponent(successPath)
    }
    return path
}
