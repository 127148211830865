import { useTranslation } from 'next-i18next';
import { CLOUDINARY_BASE_URL } from 'services/cloudinary/company-logos';
export function CompanyLogo({
  logo
}: {
  logo: CompanyLogoData;
}) {
  const {
    t
  } = useTranslation('common');
  const company = logo.id.split('/')[1].split('_')[0];
  return <img src={`${CLOUDINARY_BASE_URL}/${logo.id}.png`} alt={t('customerLogoImageAlt', {
    company
  })} width={logo.width} height={logo.height} loading="lazy" data-sentry-component="CompanyLogo" data-sentry-source-file="company-logo.tsx" />;
}