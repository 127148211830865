import React from 'react';
import { Divider, Text } from '@doist/marketist';
import cn from 'clsx';
import { ProductUISkeletonText } from '../product-ui-skeleton-text/product-ui-skeleton-text';
import css from './product-ui-view.module.css';
export type ProductUIUpcomingViewDateProps = {
  weekday?: string;
  date: string;
  abstracted: boolean;
  variant: 'today' | 'upcoming' | 'past';
};
export function ProductUIUpcomingViewDate({
  weekday,
  date,
  abstracted,
  variant
}: ProductUIUpcomingViewDateProps) {
  return <div className={cn(css.upcomingViewDate, css[variant])} data-sentry-component="ProductUIUpcomingViewDate" data-sentry-source-file="product-ui-view-upcoming-view.tsx">
            <div>
                <div className={css.weekday}>
                    {abstracted ? <ProductUISkeletonText width="100" /> : <Text size="body-2xs" weight="medium">
                            {weekday}
                        </Text>}
                </div>
                <Text className={css.date} size="body-xs" weight="medium" data-sentry-element="Text" data-sentry-source-file="product-ui-view-upcoming-view.tsx">
                    {date}
                </Text>
            </div>
            <div>{variant === 'today' ? <Divider className={css.divider} /> : null}</div>
        </div>;
}
export type ProductUIUpcomingViewSubheaderProps = {
  children: React.ReactNode;
};
export function ProductUIUpcomingViewSubheader({
  children
}: ProductUIUpcomingViewSubheaderProps) {
  return <div className={css.subheader} data-sentry-component="ProductUIUpcomingViewSubheader" data-sentry-source-file="product-ui-view-upcoming-view.tsx">
            <div className={css.viewDates}>{children}</div>
        </div>;
}