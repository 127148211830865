/**
 * When using SSG and ISG rendering modes, we can't pass login information to the HTML page because we don't call `getServerSideProps`.
 * Instead, we fill the `Server-Timing` response header with `likelyLoggedIn` if the request contains `tduser` cookie, which means the user is likely logged in.
 * via `performance.getEntriesByType('navigation')`.
 * See: middleware.ts
 * See: https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Server-Timing
 */
export function isUserLikelyLoggedIn() {
  try {
    const [entry] = performance.getEntriesByType('navigation');
    if (entry) {
      const json = entry.toJSON();
      const serverTiming = json.serverTiming as {
        name: string;
      }[];
      return serverTiming.some(st => st.name === 'likelyLoggedIn');
    } else {
      return false;
    }
  } catch (err) {
    return false;
  }
}