import { useContext } from 'react';
import { useTranslation } from 'next-i18next';
import type { Currency } from '@doist/currency-notations';
import type { Plan, User } from 'types/user';
import type { Workspace } from 'types/workspace';
import { PricingContext } from '@/app/services/providers/contexts/pricing-context';
import { UserContextV2 } from '@/app/services/providers/contexts/user-context';
import { BillingCycles } from '@/types/pricing';
export type CTAData = {
  text: string;
  href: string;
  className?: string;
  variant?: 'primary' | 'secondary';
  dataAttributes: {
    'data-gtm-id'?: string;
  };
};

// <page name(s)>-<section name>
type Placement = 'all-pages-nav-bar' | 'team-workspaces-page-hero' | 'teams-page-prefooter' | 'teams-page-intro' | 'home-page-intro' | 'pricing-page-intro' | 'pricing-page-showcase-pro' | 'pricing-page-showcase-business' | 'pricing-page-prefooter' | 'custom-page-pricing-box';

/**
 * Compute CTA behavior based on its placement and user plan and workspace.
 *
 * The conditional checks are explicit and could be dried out but, when changes
 * are no doubt requested, it's easier to understand and change with this level of explicitness.
 */
export function useCTAsData(placement: Placement, pathname?: string): CTAData | undefined {
  const {
    t
  } = useTranslation('common');
  const userContext = useContext(UserContextV2);
  const {
    currency,
    billingCycle
  } = useContext(PricingContext);
  const ctaEngine = getCTAEngine(userContext);
  if (!userContext.user) {
    return;
  }
  switch (placement) {
    case 'teams-page-prefooter':
    case 'teams-page-intro':
    case 'team-workspaces-page-hero':
    case 'custom-page-pricing-box':
      if (ctaEngine.noWorkspace) {
        return {
          href: '/app/teams/create',
          text: t('addYourTeam'),
          dataAttributes: {}
        };
      }
      if (!ctaEngine.businessAccountAdmin && !ctaEngine.businessAccountMember) {
        return {
          href: getUpgradeLink('business', currency, billingCycle, ctaEngine),
          text: t('upgradeToBusiness'),
          className: 'ga-try-free-30-days-button',
          dataAttributes: {}
        };
      }
      if ((ctaEngine.isFree || ctaEngine.isPro) && !ctaEngine.businessWorkspaceAdmin && ctaEngine.starterWorkspace) {
        return {
          href: '/pricing',
          text: t('upgradeToBusiness'),
          dataAttributes: {}
        };
      }
      return {
        href: '/app',
        text: t('openTodoist'),
        dataAttributes: {}
      };
    case 'all-pages-nav-bar':
      if (ctaEngine.businessWorkspaceAdmin) {
        return {
          href: `/app/settings/workspaces/${ctaEngine.businessWorkspaceAdmin.id}/billing`,
          text: t('manageSubscription'),
          variant: 'secondary',
          dataAttributes: {}
        };
      }
      if (ctaEngine.businessWorkspaceMember) {
        return;
      }
      if (ctaEngine.isFree && ctaEngine.noWorkspace) {
        const isPricingPage = pathname?.includes('/pricing');
        return {
          text: t('upgradeToPro'),
          variant: 'primary',
          href: isPricingPage ? getUpgradeLink('pro', currency, billingCycle, ctaEngine) : '/pricing',
          dataAttributes: {}
        };
      }
      if (ctaEngine.isFree && ctaEngine.starterWorkspace) {
        return {
          text: t('upgradeYourTeam'),
          href: getUpgradeLink('business', currency, billingCycle, ctaEngine),
          variant: 'primary',
          dataAttributes: {}
        };
      }
      if (ctaEngine.isPro && ctaEngine.noWorkspace) {
        return {
          text: t('addATeam'),
          href: '/app/teams/create',
          variant: 'primary',
          dataAttributes: {}
        };
      }
      if (ctaEngine.isPro && ctaEngine.starterWorkspace) {
        return {
          text: t('upgradeYourTeam'),
          href: getUpgradeLink('business', currency, billingCycle, ctaEngine),
          variant: 'primary',
          dataAttributes: {}
        };
      }
      return;
    case 'pricing-page-showcase-pro':
      if (ctaEngine.businessWorkspaceAdmin) {
        return;
      }
      if (ctaEngine.businessWorkspaceMember) {
        return;
      }
      if (ctaEngine.isPro && ctaEngine.noWorkspace) {
        return {
          href: getUpgradeLink('business', currency, billingCycle, ctaEngine),
          text: t('manageSubscription'),
          variant: 'secondary',
          dataAttributes: {}
        };
      }
      if (ctaEngine.isPro && ctaEngine.starterWorkspace) {
        return {
          href: getUpgradeLink('pro', currency, billingCycle, ctaEngine),
          text: t('manageSubscription'),
          variant: 'secondary',
          dataAttributes: {}
        };
      }
      if (ctaEngine.isFree && ctaEngine.noWorkspace) {
        return {
          href: getUpgradeLink('pro', currency, billingCycle, ctaEngine),
          text: t('upgradeToPro'),
          variant: 'primary',
          dataAttributes: {}
        };
      }
      if (ctaEngine.isFree && ctaEngine.starterWorkspace) {
        return {
          href: getUpgradeLink('pro', currency, billingCycle, ctaEngine),
          text: t('upgradeToPro'),
          variant: 'secondary',
          dataAttributes: {}
        };
      }
      return;
    case 'pricing-page-showcase-business':
      if (ctaEngine.businessWorkspaceAdmin) {
        return {
          href: getUpgradeLink('business', currency, billingCycle, ctaEngine),
          text: t('manageSubscription'),
          variant: 'secondary',
          dataAttributes: {}
        };
      }
      if (ctaEngine.businessWorkspaceMember) {
        return;
      }
      if (ctaEngine.isFree && ctaEngine.noWorkspace) {
        return {
          href: '/app/teams/create',
          text: t('addATeamForFree'),
          variant: 'secondary',
          dataAttributes: {}
        };
      }
      if (ctaEngine.isFree && ctaEngine.starterWorkspace) {
        return {
          text: t('upgradeYourTeam'),
          href: getUpgradeLink('business', currency, billingCycle, ctaEngine),
          variant: 'primary',
          dataAttributes: {}
        };
      }
      if (ctaEngine.isPro && ctaEngine.noWorkspace) {
        return {
          href: '/app/teams/create',
          text: t('addATeamForFree'),
          variant: 'primary',
          dataAttributes: {}
        };
      }
      if (ctaEngine.isPro && ctaEngine.starterWorkspace) {
        return {
          text: t('upgradeYourTeam'),
          href: getUpgradeLink('business', currency, billingCycle, ctaEngine),
          variant: 'primary',
          dataAttributes: {}
        };
      }
      return;
    case 'pricing-page-intro':
    case 'pricing-page-prefooter':
      if (ctaEngine.businessWorkspaceMember) {
        return;
      }
      if (ctaEngine.businessWorkspaceAdmin) {
        return;
      }
      if (ctaEngine.isFree && ctaEngine.noWorkspace) {
        return {
          text: t('upgradeToPro'),
          href: getUpgradeLink('pro', currency, billingCycle, ctaEngine),
          variant: 'primary',
          dataAttributes: {}
        };
      }
      if (ctaEngine.isFree && ctaEngine.starterWorkspace) {
        return {
          href: getUpgradeLink('business', currency, billingCycle, ctaEngine),
          text: t('upgradeYourTeam'),
          variant: 'primary',
          dataAttributes: {}
        };
      }
      if (ctaEngine.isPro && ctaEngine.noWorkspace) {
        return {
          href: '/app/teams/create',
          text: t('addATeamForFree'),
          variant: 'primary',
          dataAttributes: {}
        };
      }
      if (ctaEngine.isPro && ctaEngine.starterWorkspace) {
        return {
          href: getUpgradeLink('business', currency, billingCycle, ctaEngine),
          text: t('upgradeYourTeam'),
          variant: 'primary',
          dataAttributes: {}
        };
      }
      return;
    case 'home-page-intro':
      if (ctaEngine.isFree && ctaEngine.noWorkspace) {
        return {
          text: t('upgradeToPro'),
          href: '/pricing',
          variant: 'primary',
          dataAttributes: {}
        };
      }
      if (ctaEngine.isPro && ctaEngine.noWorkspace) {
        return {
          href: '/app',
          text: t('openTodoist'),
          variant: 'primary',
          dataAttributes: {}
        };
      }
      if (ctaEngine.starterWorkspace) {
        return {
          text: t('upgradeToBusiness'),
          href: '/pricing',
          variant: 'primary',
          dataAttributes: {}
        };
      }
      return {
        href: '/app',
        text: t('openTodoist'),
        variant: 'primary',
        dataAttributes: {}
      };
  }
}
export type CTAEngine = {
  isFree: boolean;
  isPro: boolean;
  isBusiness: boolean;
  noWorkspace: boolean;
  starterWorkspace?: Workspace;
  businessWorkspaceAdmin?: Workspace;
  businessWorkspaceMember?: Workspace;
  businessWorkspaceGuest?: Workspace;
  businessAccountMember: boolean;
  businessAccountAdmin: boolean;
};
export function getCTAEngine(userContext: {
  user?: User;
  userPlan?: Plan;
  workspaces: Workspace[];
}): CTAEngine {
  const {
    user,
    userPlan,
    workspaces
  } = userContext;
  return {
    isFree: userPlan === 'free' || userPlan === null,
    isPro: userPlan === 'pro',
    isBusiness: userPlan === 'business',
    noWorkspace: workspaces.filter(w => w.role !== 'GUEST').length === 0,
    starterWorkspace: workspaces.find(w => w.role === 'ADMIN' && w.plan === 'STARTER'),
    businessWorkspaceAdmin: workspaces.find(w => w.role === 'ADMIN' && w.plan === 'BUSINESS'),
    businessWorkspaceMember: workspaces.find(w => w.role === 'MEMBER' && w.plan === 'BUSINESS'),
    businessWorkspaceGuest: workspaces.find(w => w.role === 'GUEST' && w.plan === 'BUSINESS'),
    businessAccountMember: user?.premium_status === 'teams_business_member',
    businessAccountAdmin: Boolean(user?.is_biz_admin)
  };
}
function getUpgradeLink(plan: Omit<Plan, 'free'>, currency: Currency, billingCycle: BillingCycles, ctaEngine: CTAEngine) {
  const searchParams = new URLSearchParams();
  searchParams.set('billing_cycle', billingCycle);
  searchParams.set('currency', currency);
  if (ctaEngine.starterWorkspace) {
    searchParams.set('workspace_id', ctaEngine.starterWorkspace.id.toString());
  }
  const pathComponent = plan === 'pro' ? 'pro' : 'workspaces';
  return `${process.env.NEXT_PUBLIC_BASE_API_URL}/${pathComponent}/upgrade?${searchParams.toString()}`;
}