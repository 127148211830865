/**
 * Generator function for series of dates with daily increment.
 *
 * Usage:
 *   const dates = dateGenerator(new Date())
 *   const today = dates.next().value
 *   const tomorrow = dates.next().value
 *
 * @param startDate Starting point Date object
 */
function* dateGenerator(startDate: Date, endDate?: Date): Generator<Date> {
    const date = new Date(startDate)
    date.setHours(12, 0, 0, 0) // avoid DST problems

    if (!endDate) {
        endDate = new Date(startDate)
        // if endDate not defined, set to day before to keep generating
        endDate.setDate(endDate.getDate() - 1)
    }

    endDate.setHours(12, 0, 0, 0)

    yield new Date(date)

    // keep adding one day
    while (date.toDateString() !== endDate.toDateString()) {
        date.setDate(date.getDate() + 1)
        yield new Date(date)
    }
}

export { dateGenerator }
