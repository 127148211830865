import { Children, isValidElement, useId } from 'react';
import { Box } from '@doist/marketist';
import cn from 'clsx';
import { FeatureRadioTile, type FeatureRadioTileProps } from './feature-radio-tile';
import { FeatureRadioTileContext } from './feature-radio-tile-context';
import css from './feature-radio-tile.module.css';
type FeatureRadioTileGroupProps = {
  children: React.ReactNode;
  onChange: (value: string) => void;
  value: string;
  className?: string;
};
export function FeatureRadioTileGroup({
  children,
  onChange,
  value,
  className
}: FeatureRadioTileGroupProps) {
  const id = useId();
  return <FeatureRadioTileContext.Provider value={{
    onChange,
    currentValue: value,
    name: id
  }} data-sentry-element="unknown" data-sentry-component="FeatureRadioTileGroup" data-sentry-source-file="feature-radio-tile-group.tsx">
            <Box tag="ul" className={cn(css.radioTileGroup, className)} data-sentry-element="Box" data-sentry-source-file="feature-radio-tile-group.tsx">
                {Children.map(children, (child, n) => {
        let key = n.toString();
        if (isValidElement<FeatureRadioTileProps>(child) && child.type === FeatureRadioTile) {
          key = child.props.value ?? n;
        }
        return <li key={key}>{child}</li>;
      })}
            </Box>
        </FeatureRadioTileContext.Provider>;
}