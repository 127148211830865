import React, { cloneElement, type ReactElement, useContext, useEffect, useState } from 'react';
import { Repeat } from '@doist/icons/brand';
import { Text } from '@doist/marketist';
import cn from 'clsx';
import { ProductUIWeeklyCalendarViewContext } from 'components/product-ui/product-ui-view/product-ui-view-weekly-calendar-view';
import { ProductUITaskCircle, type ProductUITaskCircleProps } from '../product-ui-task-circle/product-ui-task-circle';
import css from './product-ui-weekly-calendar-task.module.css';
import type { Priority } from '../product-ui-types';
export const BUBBLE_HEIGHT_HOUR = 45;
function getNumberOfMinutesSinceMidnight(date: Date) {
  const midnight = new Date(date.getTime());
  midnight.setHours(0);
  midnight.setMinutes(0);
  return (date.getTime() - midnight.getTime()) / 60_000;
}
export type ProductUIWeeklyCalendarTaskProps = {
  priority?: Priority;
  taskCircle?: ReactElement<typeof ProductUIWeeklyCalendarTask.TaskCircle>;
  text?: ReactElement<typeof ProductUIWeeklyCalendarTask.Text>;
  description?: React.ReactNode;
  repeat?: boolean;
  startTime?: Date;
  durationMinutes?: number; /** Set it to undefined if it's an "All day" event. */
  dragging?: boolean;
};
/**
 * Represent a Task in a Weekly Calendar View.
 * This component is meant to be used in a ProductUIWeeklyCalendarDay.
 */
export function ProductUIWeeklyCalendarTask({
  priority = 'p4',
  taskCircle,
  text,
  description,
  repeat = false,
  startTime,
  durationMinutes = 0,
  dragging = false,
  ...rest
}: ProductUIWeeklyCalendarTaskProps) {
  // Since we localize the time, we must compute it from the client-side.
  const [repeatText, setRepeatText] = useState('');
  useEffect(() => {
    if (!startTime) {
      setRepeatText('');
      return;
    }
    const endTime = new Date(startTime.getTime());
    endTime.setSeconds(endTime.getSeconds() + durationMinutes * 60);
    setRepeatText([startTime, endTime].map(time => time.toLocaleTimeString([], {
      hour: '2-digit',
      minute: '2-digit'
    })).join('-'));
  }, [startTime, durationMinutes]);
  const {
    hourHeight
  } = useContext(ProductUIWeeklyCalendarViewContext);
  // Compute the offset from the top based on the start time.
  let offsetTop = 0;
  if (startTime) {
    const minutesSinceMidnight = getNumberOfMinutesSinceMidnight(startTime);
    offsetTop = minutesSinceMidnight / 60 * hourHeight;
  }
  // Show a compact version if the duration is short.
  const isCompact = durationMinutes < 75;
  // The repeat icon is shown on the same line as the title in the compact variant.
  const isCompactWithRepeat = isCompact && repeat;
  return <div className={cn(css.weeklyCalendarTask, {
    [css.compact]: isCompact,
    [css.inContext]: Boolean(startTime),
    [css.notCompletable]: !taskCircle,
    [css.dragging]: dragging
  }, css[priority])} style={{
    '--weekly-calendar-height': Math.round(durationMinutes * hourHeight / 60) - 2 + 'px',
    '--weekly-calendar-start-time-offset': offsetTop + 'px'
  }} {...rest} data-sentry-component="ProductUIWeeklyCalendarTask" data-sentry-source-file="product-ui-weekly-calendar-task.tsx">
            <div className={css.taskCircle}>
                {taskCircle ? cloneElement(taskCircle as ReactElement<ProductUITaskCircleProps>, {
        priority
      }) : null}
            </div>
            <div className={cn(css.right, {
      [css.withRepeat]: isCompactWithRepeat
    })}>
                {text}
                {repeat ? isCompact ? <Repeat /> : <Text className={css.repeat} size="body-2xs">
                            {repeatText} <Repeat />
                        </Text> : null}
                {description ? description : null}
            </div>
        </div>;
}
ProductUIWeeklyCalendarTask.TaskCircle = function (props: Omit<ProductUITaskCircleProps, 'variant' | 'priority'>) {
  return <ProductUITaskCircle variant="calendar" {...props} />;
};
ProductUIWeeklyCalendarTask.Text = function ({
  children
}: {
  children: React.ReactNode;
}) {
  return <Text className={css.text} size="body-2xs">
            {children}
        </Text>;
};
ProductUIWeeklyCalendarTask.StartTime = function (hours: number, minutes: number) {
  const date = new Date();
  date.setHours(hours);
  date.setMinutes(minutes);
  return date;
};