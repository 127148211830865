'use client';

import { createContext, useEffect } from 'react';
import { FlagSession } from '@/app/services/feature-flags/flags';
import { flagsTracking } from '@/services/feature-flags/flags-tracking';

/**
 * FeatureFlag is a context where all the information about feature flags.
 */
export const FeatureFlagContext = createContext({} as FlagSession);
export function FeatureFlagContextProvider({
  children,
  session
}: {
  session: FlagSession;
  children: React.ReactNode;
}) {
  useEffect(() => {
    if (session) {
      flagsTracking(session);
    }
  }, [session]);
  return <FeatureFlagContext.Provider value={session} data-sentry-element="unknown" data-sentry-component="FeatureFlagContextProvider" data-sentry-source-file="feature-flag-context.tsx">{children}</FeatureFlagContext.Provider>;
}