import type { Plan, User } from 'types/user'

function getUserPlan(user?: User | null): Plan | undefined {
    if (user) {
        if (user.premium_status === 'teams_business_member') {
            return 'business'
        } else if (user.premium_status === 'current_personal_plan') {
            return 'pro'
        } else {
            return 'free'
        }
    }
    return undefined
}

export { getUserPlan }
