import { createContext } from 'react';
import type { ProductUITheme } from './product-ui-types';

// Used to apply the theme to all the ProductUI components within
// the same scene. See `ProductUISceneContainer` for use.
export const ProductUIThemeContext = createContext<ProductUITheme>('red');
type ProductUIThemeProviderProps = {
  children: React.ReactNode;
  theme?: ProductUITheme;
};
function mapThemeToCustomProperties(theme: ProductUITheme) {
  const t = theme === 'seaSalt' ? 'sea-salt' : theme;
  return {
    '--productui-primary-theme-color': `var(--${t}-500)`,
    '--productui-background-light': `var(--${t}-200)`,
    '--productui-background-dark': `var(--${t}-400)`,
    '--productui-actionable-primary-bg-onhover': `var(--${t}-600)`,
    '--productui-display-skeleton-color': `var(--${t}-300)`,
    '--productui-sidebar-actionable-quaternary-background-active': `var(--${t}-200)`,
    '--productui-display-color': `var(--${t}-600)`,
    '--productui-keyword-highlight-background': `var(--${t}-300)`,
    '--productui-themed-box-background': `var(--${t}-100)`,
    '--productui-shadow-elevated': `var(--shadow-elevated-${t})`
  };
}
export function ProductUIThemeProvider({
  children,
  theme = 'red'
}: ProductUIThemeProviderProps) {
  const themeCustomProperties = mapThemeToCustomProperties(theme);
  return <ProductUIThemeContext.Provider value={theme} data-sentry-element="unknown" data-sentry-component="ProductUIThemeProvider" data-sentry-source-file="product-ui-theme-context.tsx">
            <div style={themeCustomProperties}>{children}</div>
        </ProductUIThemeContext.Provider>;
}