'use client'

import { useContext } from 'react'

import cn from 'clsx'
import { Box } from '@/components/Box/Box'
import { ThemeContext } from '@/utils/themeContext'

import { Button } from '@/components/Button/Button'
import { Text } from '@/components/Text/Text'
import { X } from '@doist/icons/brand'
import css from './NavbarBanner.module.css'

export type NavbarBannerProps = {
    children: React.ReactNode
    onCloseClicked?: () => void
    closeButtonAriaLabel?: string
    dismissible?: boolean
}

export function NavbarBanner({
    children,
    onCloseClicked,
    closeButtonAriaLabel = 'Close',
    dismissible = true,
}: NavbarBannerProps) {
    const { theme } = useContext(ThemeContext)

    return (
        <Box className={css.container}>
            <Box
                tag="aside"
                className={cn(css.banner, { [css.dismissible]: dismissible }, 'box-highlight')}
            >
                <Text tag="div" className={css.content} size="body-sm">
                    {children}
                </Text>
                {dismissible ? (
                    <Button
                        data-testid="close-btn"
                        aria-label={closeButtonAriaLabel}
                        variant={theme === 'twist' ? 'quaternary' : 'secondary'}
                        size="small"
                        onClick={onCloseClicked}
                        startIcon={X}
                    />
                ) : null}
            </Box>
        </Box>
    )
}
