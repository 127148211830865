import { type Dispatch, type ReactNode, useReducer } from 'react';
import { Repeat } from '@doist/icons/product-ui';
import { useGSAP } from '@gsap/react';
import { ProductUIAppWindow } from 'components/product-ui/product-ui-app-window/product-ui-app-window';
import { ProductUIButton } from 'components/product-ui/product-ui-button/product-ui-button';
import { ProductUIContextProvider, useProductUIContext } from 'components/product-ui/product-ui-context';
import { ProductUICursor } from 'components/product-ui/product-ui-cursor/product-ui-cursor';
import { ProductUISceneContainer, ProductUISceneLayer } from 'components/product-ui/product-ui-scene-container/product-ui-scene-container';
import { TemplateSidebarStandard } from 'components/product-ui/product-ui-sidebar/product-ui-sidebar-templates';
import { ProductUITaskAttributePill, type ProductUITaskAttributePillProps, type VariantAssignee, type VariantDueDates, type VariantPriority } from 'components/product-ui/product-ui-task-attribute-pill/product-ui-task-attribute-pill';
import { TemplateTodayView } from 'components/product-ui/product-ui-view/product-ui-view-templates';
import { QuickAdd, QuickAddAttributeContainer } from 'components/product-ui/quick-add/quick-add';
import gsap from 'gsap';
import { useTranslation } from 'next-i18next';
import { EaseBase } from '../ease';
import { cursorClick, moveElementTo, TweenTiming } from '../tweens';
import css from './quick-add-sequence.module.css';
export function QuickAddSequence() {
  return <ProductUIContextProvider data-sentry-element="ProductUIContextProvider" data-sentry-component="QuickAddSequence" data-sentry-source-file="quick-add-sequence.tsx">
            <QuickAddAnimation data-sentry-element="QuickAddAnimation" data-sentry-source-file="quick-add-sequence.tsx" />
        </ProductUIContextProvider>;
}
type AnimationState = {
  dueDateVariant: VariantDueDates;
  dueDateLabel?: string;
  dueDateSuffixIcon?: ProductUITaskAttributePillProps['suffixIcon'];
  dueDateColor?: ProductUITaskAttributePillProps['color'];
  priorityVariant: VariantPriority;
  assigneeVariant: VariantAssignee;
  assigneeLabel?: ReactNode | string;
  avatar?: ProductUITaskAttributePillProps['avatar'];
};
const animationState: AnimationState = {
  dueDateVariant: 'dueDatePlaceHolder',
  dueDateLabel: undefined,
  priorityVariant: 'priorityPlaceholder',
  assigneeVariant: 'assigneePlaceholder'
};
type AnimationAction = {
  type: 'dueDateVariant';
  variant: VariantDueDates;
  label?: string;
  suffixIcon?: ProductUITaskAttributePillProps['suffixIcon'];
  color?: ProductUITaskAttributePillProps['color'];
} | {
  type: 'assigneeVariant';
  variant: VariantAssignee;
  label?: string;
  avatar?: ProductUITaskAttributePillProps['avatar'];
} | {
  type: 'priorityVariant';
  variant: VariantPriority;
} | {
  type: 'reset';
};
function animationReducer(state: typeof animationState, action: AnimationAction): AnimationState {
  switch (action.type) {
    case 'dueDateVariant':
      return {
        ...state,
        dueDateVariant: action.variant,
        dueDateLabel: action.label,
        dueDateSuffixIcon: action.suffixIcon,
        dueDateColor: action.color
      };
    case 'priorityVariant':
      return {
        ...state,
        priorityVariant: action.variant
      };
    case 'assigneeVariant':
      return {
        ...state,
        assigneeVariant: action.variant,
        assigneeLabel: action.label,
        avatar: action.avatar
      };
    case 'reset':
      return {
        ...state,
        dueDateVariant: 'dueDatePlaceHolder',
        dueDateLabel: undefined,
        dueDateSuffixIcon: undefined,
        dueDateColor: undefined,
        priorityVariant: 'priorityPlaceholder',
        assigneeVariant: 'assigneePlaceholder',
        assigneeLabel: undefined,
        avatar: undefined
      };
    default:
      return state;
  }
}
const cursorSelector = '#productUICursor';
function QuickAddAnimation() {
  const {
    timeline,
    scope
  } = useProductUIContext();
  const {
    t
  } = useTranslation('productui');
  const [state, dispatch] = useReducer(animationReducer, animationState);
  useGSAP(() => {
    if (!timeline) {
      return;
    }
    const ctaSelector = '[data-productui="cta"]';
    timeline.add(['Reset', resetView(dispatch)]).add('Start: Call Alex').add(typeOut(t('sequence.quickadd.callAlex'), {
      value: t('sequence.quickadd.tomorrow'),
      callback: () => {
        dispatch({
          type: 'dueDateVariant',
          variant: 'dueDateTomorrow'
        });
      }
    })).add('Mouse Move: Call Alex').to(cursorSelector, {
      opacity: 1,
      duration: 0.5
    }).call(moveElementTo, [timeline, scope, cursorSelector, ctaSelector], '<').add(cursorClick(cursorSelector, true)).add(['Quick Add reset', resetView(dispatch)]).add(pop(), '<').add(gsap.timeline({
      delay: 1
    })).addLabel('Start: Assign').add(typeOut(t('sequence.quickadd.listProperty'), {
      value: t('sequence.quickadd.plusPaul'),
      callback: () => {
        dispatch({
          type: 'assigneeVariant',
          variant: 'assigneeCustom',
          label: t('sequence.quickadd.paul'),
          avatar: 'paul'
        });
      }
    })).add('Mouse Move: Assign Paul').to(cursorSelector, {
      opacity: 1,
      duration: 0.5
    }).call(moveElementTo, [timeline, scope, cursorSelector, ctaSelector], '<').add(cursorClick(cursorSelector, true)).add(['Assign Paul reset', resetView(dispatch)]).add(pop(), '<').add(gsap.timeline({
      delay: 1
    })).addLabel('Start: Review Traffic').add(typeOut(t('sequence.quickadd.reviewTraffic'), {
      value: t('sequence.quickadd.everyMondayEightWeeks'),
      callback() {
        dispatch({
          type: 'dueDateVariant',
          variant: 'dueDateCustom',
          label: t('sequence.quickadd.everyMondayPill'),
          suffixIcon: <Repeat />,
          color: 'purple'
        });
      }
    })).addLabel('Mouse Move: Review trafic').to(cursorSelector, {
      opacity: 1,
      duration: 0.5
    }).call(moveElementTo, [timeline, scope, cursorSelector, ctaSelector], '<').add(cursorClick(cursorSelector, true)).add(['Review Traffic reset', resetView(dispatch)]).add(pop()).add(gsap.timeline({
      delay: 1
    })).addLabel('Start: Read Work Emails').add(typeOut(t('sequence.quickadd.readEmail'), {
      value: t('sequence.quickadd.everyDay'),
      callback() {
        dispatch({
          type: 'dueDateVariant',
          variant: 'dueDateToday',
          suffixIcon: <Repeat />
        });
      }
    }, {
      value: t('sequence.quickadd.p1'),
      callback() {
        dispatch({
          type: 'priorityVariant',
          variant: 'priorityP1'
        });
      }
    })).addLabel('Mouse Move: Read Work Emails').to(cursorSelector, {
      opacity: 1,
      duration: 0.5
    }).call(moveElementTo, [timeline, scope, cursorSelector, ctaSelector], '<').add(cursorClick(cursorSelector, true)).add(['Read work emails reset', resetView(dispatch)]).add(pop()).add(gsap.timeline({
      delay: 1
    })).addLabel('Start: Expense reports').add(typeOut(`${t('sequence.quickadd.expenseReport')} `, {
      value: t('sequence.quickadd.expenseReportWhen'),
      callback() {
        dispatch({
          type: 'dueDateVariant',
          variant: 'dueDateCustom',
          label: t('sequence.quickadd.expenseReportWhenPill'),
          suffixIcon: <Repeat />
        });
      }
    })).addLabel('Mouse Move: expense report').to(cursorSelector, {
      opacity: 1,
      duration: 0.5
    }).call(moveElementTo, [timeline, scope, cursorSelector, ctaSelector], '<').add(cursorClick(cursorSelector, true)).add(['expense report reset', resetView(dispatch)]).add(pop());
  }, {
    dependencies: [timeline],
    scope
  });
  return <ProductUISceneContainer className={css.sequenceContainer} backgroundVariant="wave-two" data-sentry-element="ProductUISceneContainer" data-sentry-component="QuickAddAnimation" data-sentry-source-file="quick-add-sequence.tsx">
            <ProductUISceneLayer position="modalOpenLeft" data-sentry-element="ProductUISceneLayer" data-sentry-source-file="quick-add-sequence.tsx">
                <ProductUIAppWindow sidebar={<TemplateSidebarStandard />} view={<TemplateTodayView />} inlineSize={800} blockSize="calc(100% + 7px)" showOverlay data-sentry-element="ProductUIAppWindow" data-sentry-source-file="quick-add-sequence.tsx" />
            </ProductUISceneLayer>
            <ProductUISceneLayer position="center" data-sentry-element="ProductUISceneLayer" data-sentry-source-file="quick-add-sequence.tsx">
                <QuickAdd data-productui="quickadd" className={css.quickAdd} taskSection={<QuickAdd.TaskText>
                            <QuickAdd.Intent id="quickAddPlaceholder" variant="placeholder" data-productui="placeholder">
                                {t('quickAdd.placeholders.taskName')}
                            </QuickAdd.Intent>{' '}
                            <QuickAdd.Intent id="taskText" data-productui="quickAddText" />{' '}
                            <QuickAdd.Intent id="taskTime" data-productui="quickAddText" />{' '}
                            <QuickAdd.Intent id="taskPriority" data-productui="quickAddText" />
                        </QuickAdd.TaskText>} attributeSection={<QuickAddAttributeContainer>
                            {/* Due Date Pill */}
                            <ProductUITaskAttributePill variant={state.dueDateVariant} suffixIcon={state.dueDateSuffixIcon} color={state.dueDateColor ? state.dueDateColor : undefined}>
                                {state.dueDateLabel}
                            </ProductUITaskAttributePill>
                            {/* Assignee Pill */}
                            <ProductUITaskAttributePill variant={state.assigneeVariant} avatar={state.avatar}>
                                {state.assigneeLabel}
                            </ProductUITaskAttributePill>
                            {/* Priority Pill */}
                            <ProductUITaskAttributePill variant={state.priorityVariant} />
                        </QuickAddAttributeContainer>} footerSection={<QuickAdd.FooterContainer>
                            <QuickAdd.FooterProjectButton />
                            <QuickAdd.FooterSpacer />
                            <ProductUIButton className="addTaskButton" variant="primary" data-productui="cta">
                                {t('quickAdd.addTask')}
                            </ProductUIButton>
                        </QuickAdd.FooterContainer>} data-sentry-element="QuickAdd" data-sentry-source-file="quick-add-sequence.tsx" />
            </ProductUISceneLayer>

            <ProductUICursor id="productUICursor" data-productui="cursor" data-sentry-element="ProductUICursor" data-sentry-source-file="quick-add-sequence.tsx" />
        </ProductUISceneContainer>;
}
type TypeOutValue = string | {
  value: string;
  callback?: () => void;
};
function typeOut(text: TypeOutValue, dueDate: TypeOutValue, priority?: TypeOutValue) {
  function durationPerCharacter(value: string) {
    return value.length * TweenTiming.typingSpeed;
  }
  const taskText = typeof text === 'string' ? text : text.value;
  const dueDateText = typeof dueDate === 'string' ? dueDate : dueDate.value;
  const taskTextSelector = '#taskText';
  const taskTimeSelector = '#taskTime';
  const timeline = gsap.timeline().set('#quickAddPlaceholder', {
    display: 'none'
  }).set(taskTextSelector, {
    display: 'inline',
    text: ''
  }).to(taskTextSelector, {
    text: taskText,
    duration() {
      return durationPerCharacter(taskText);
    },
    onComplete() {
      if (typeof text !== 'string' && text.callback) {
        text.callback();
      }
    }
  }, '<').set(taskTimeSelector, {
    display: 'inline',
    text: ''
  }).to(taskTimeSelector, {
    text: dueDateText,
    duration() {
      return durationPerCharacter(dueDateText);
    }
  }).add(highlightIntention(taskTimeSelector, () => {
    if (typeof dueDate !== 'string' && dueDate.callback) {
      dueDate.callback();
    }
  }));
  if (priority) {
    const priorityText = typeof priority === 'string' ? priority : priority?.value;
    const taskPrioritySelector = '#taskPriority';
    timeline.set(taskPrioritySelector, {
      display: 'inline'
    }).to(taskPrioritySelector, {
      text: priorityText,
      duration() {
        return durationPerCharacter(priorityText);
      }
    }, '<').add(highlightIntention(taskPrioritySelector, () => {
      if (typeof priority !== 'string' && priority.callback) {
        priority.callback();
      }
    }));
  }
  return timeline;
}
function highlightIntention(id: string, callback: () => void = () => {
  /* noop */
}) {
  return gsap.timeline().fromTo(id, {
    // Starting at opacity 0 to avoid a flash of black during color transition.
    // Future optimization: Store channels as variables or use a function to return desired opacity.
    backgroundColor: 'rgba(246, 216, 213, 0)'
  }, {
    backgroundColor: 'var(--productui-keyword-highlight-background)',
    padding: '0 8px',
    immediateRender: false,
    ease: EaseBase,
    display: 'inline-flex',
    duration: TweenTiming.intentionHighlight,
    onStart() {
      callback();
    }
  });
}
function pop() {
  return gsap.timeline().to('[data-productui="quickadd"]', {
    scale: 0.9,
    duration: 0.1,
    ease: EaseBase,
    yoyo: true,
    repeat: 1,
    onComplete: function () {
      // remove GSAPs animation inline styles as they interfere with the scenes container styles.
      gsap.set(this.targets(), {
        clearProps: 'all'
      });
    }
  });
}
function resetView(dispatch: Dispatch<AnimationAction>) {
  return gsap.timeline().call(() => {
    dispatch({
      type: 'reset'
    });
  }).set(['[data-productui="quickAddText"]', '[data-pill]:not([data-pill="placeholder"]'], {
    clearProps: true
  }).set(['[data-productui="placeholder"]', cursorSelector], {
    clearProps: true
  }).set(cursorSelector, {
    left: '50%',
    top: '50%',
    xPercent: -50,
    yPercent: -50
  });
}