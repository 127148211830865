import { createContext, useContext } from 'react';
type FeatureRadioTileContext = {
  name: string;
  currentValue: string;
  onChange: (value: string) => void;
};
export const FeatureRadioTileContext = createContext<FeatureRadioTileContext | undefined>(undefined);
export function useFeatureRadioTileContext() {
  const ctx = useContext(FeatureRadioTileContext);
  if (!ctx) {
    throw new Error('useFeatureRadioTileContext must be used within a FeatureRadioTileGroup');
  }
  return ctx;
}