const localStorageKey = 'td-banners'

function getLocalStorageStatus() {
    if (localStorage) {
        const payload = localStorage.getItem(localStorageKey)
        if (payload) {
            return JSON.parse(payload)
        }
    }
    return {}
}

export enum Status {
    Unknown = 'Unknown',
    Viewed = 'Viewed',
    NotSeen = 'NotSeen',
}

export function getStatus(name: string): Status {
    if (!localStorage) {
        return Status.Unknown
    }

    const status = getLocalStorageStatus()
    return status[name] ?? Status.NotSeen
}

export function markAsViewed(name: string) {
    if (!localStorage) {
        return
    }

    const status = getLocalStorageStatus()
    const payload = JSON.stringify({ ...status, [name]: Status.Viewed })
    localStorage.setItem(localStorageKey, payload)
}
