import { dateGenerator } from 'services/date-generator';
import { ProductUIView } from '../product-ui-view/product-ui-view';
import { ProductUIViewHeaderList } from './product-ui-view-header-list';
import type { ProductUIViewHeaderBaseProps } from './product-ui-view-header-base';
function ProductUIViewHeaderUpcoming({
  children,
  ...rest
}: ProductUIViewHeaderBaseProps) {
  const yesterday = new Date();
  yesterday.setDate(yesterday.getDate() - 1);
  const dates = dateGenerator(yesterday);
  return <ProductUIViewHeaderList {...rest} data-sentry-element="ProductUIViewHeaderList" data-sentry-component="ProductUIViewHeaderUpcoming" data-sentry-source-file="product-ui-view-header-upcoming.tsx">
            {children ? children : <ProductUIView.UpcomingViewSubheader>
                    <ProductUIView.UpcomingViewDate key="past" date={dates.next().value.getDate().toString()} abstracted={true} variant="past" />
                    <ProductUIView.UpcomingViewDate key="today" date={dates.next().value.getDate().toString()} abstracted={true} variant="today" />
                    {[...Array(5)].map((_, i) => <ProductUIView.UpcomingViewDate key={i} date={dates.next().value.getDate().toString()} abstracted={true} variant="upcoming" />)}
                </ProductUIView.UpcomingViewSubheader>}
        </ProductUIViewHeaderList>;
}
export { ProductUIViewHeaderUpcoming };