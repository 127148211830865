import { createContext, type ReactNode, useContext } from 'react';
import { ChevronDown, Notification, SidebarLeft } from '@doist/icons/product-ui';
import { Avatar, Box } from '@doist/marketist';
import cn from 'clsx';
import { useTranslation } from 'next-i18next';
import { ProductUISidebarHeaderItem } from '../product-ui-list-item';
import { ProductUISkeletonText } from '../product-ui-skeleton-text/product-ui-skeleton-text';
import { ProductUITaskAttributeButton } from '../product-ui-task-attribute-button/product-ui-task-attribute-button';
import css from './product-ui-sidebar.module.css';
const SidebarContext = createContext<{
  withinSidebar: boolean;
}>({
  withinSidebar: false
});
export function useSidebarContext() {
  const ctx = useContext(SidebarContext);
  return ctx ? ctx.withinSidebar : false;
}
type SidebarProps = {
  id?: string;
  profileMenu?: ReactNode;
  views?: ReactNode;
  favorites?: ReactNode;
  workspaces?: ReactNode;
  className?: string;
};
function SideBarSection({
  children,
  ...rest
}: {
  children: ReactNode;
}) {
  return <div className={css.section} {...rest} data-sentry-component="SideBarSection" data-sentry-source-file="product-ui-sidebar.tsx">
            {children}
        </div>;
}
function SideBarFavorites({
  children
}: {
  children: ReactNode;
}) {
  const {
    t
  } = useTranslation('productui');
  return <div className={css.section} data-sentry-component="SideBarFavorites" data-sentry-source-file="product-ui-sidebar.tsx">
            <ProductUISidebarHeaderItem label={t('sideBar.favorites')} data-sentry-element="ProductUISidebarHeaderItem" data-sentry-source-file="product-ui-sidebar.tsx" />
            {children}
        </div>;
}
type ProfileMenuProps = {
  /**
   * The user's progress on their daily goal
   * 0 >= dailyProgress <= 1
   * @default 0
   *
   */
  dailyProgress?: number;
  avatarSrc?: string;
  hovered?: boolean;
};
function ProfileMenu({
  dailyProgress = 0,
  avatarSrc = `${process.env.NEXT_PUBLIC_BASE_URL}/static/product-ui/user-avatars/denise.png`,
  hovered
}: ProfileMenuProps) {
  const size = 26;
  const halfSize = size / 2;
  const strokeWidth = 2;
  const radius = (size - strokeWidth) / 2;
  const circumference = 2 * Math.PI * radius;
  const offset = (1 - dailyProgress) * circumference;
  return <Box className={css.profileMenu} data-sentry-element="Box" data-sentry-component="ProfileMenu" data-sentry-source-file="product-ui-sidebar.tsx">
            <Box className={cn(css.avatarContainer, {
      [css.hovered]: hovered
    })} data-sentry-element="Box" data-sentry-source-file="product-ui-sidebar.tsx">
                <div className={css.profileMenuAvatar}>
                    <svg width={size} height={size} data-sentry-element="svg" data-sentry-source-file="product-ui-sidebar.tsx">
                        <circle cx={halfSize} cy={halfSize} r={radius} className={css.progressBackground} data-sentry-element="circle" data-sentry-source-file="product-ui-sidebar.tsx" />
                        <circle cx={halfSize} cy={halfSize} r={radius} className={css.progressArc} strokeDasharray={circumference} strokeDashoffset={offset} data-sentry-element="circle" data-sentry-source-file="product-ui-sidebar.tsx" />
                    </svg>
                    <Avatar size={20} src={avatarSrc} alt="" data-sentry-element="Avatar" data-sentry-source-file="product-ui-sidebar.tsx" />
                </div>
                <ProductUISkeletonText width="60" data-sentry-element="ProductUISkeletonText" data-sentry-source-file="product-ui-sidebar.tsx" />
                <ChevronDown width={18} height={18} className={css.menuChevron} data-sentry-element="ChevronDown" data-sentry-source-file="product-ui-sidebar.tsx" />
            </Box>
            <Box className={css.profileIcons} data-sentry-element="Box" data-sentry-source-file="product-ui-sidebar.tsx">
                <ProductUITaskAttributeButton size="lg" data-sentry-element="ProductUITaskAttributeButton" data-sentry-source-file="product-ui-sidebar.tsx">
                    <Notification data-sentry-element="Notification" data-sentry-source-file="product-ui-sidebar.tsx" />
                </ProductUITaskAttributeButton>
                <ProductUITaskAttributeButton size="lg" data-sentry-element="ProductUITaskAttributeButton" data-sentry-source-file="product-ui-sidebar.tsx">
                    <SidebarLeft data-sentry-element="SidebarLeft" data-sentry-source-file="product-ui-sidebar.tsx" />
                </ProductUITaskAttributeButton>
            </Box>
        </Box>;
}
function ProductUISidebar({
  id,
  profileMenu,
  views,
  favorites,
  workspaces,
  className
}: SidebarProps) {
  return <SidebarContext.Provider value={{
    withinSidebar: true
  }} data-sentry-element="unknown" data-sentry-component="ProductUISidebar" data-sentry-source-file="product-ui-sidebar.tsx">
            <div id={id} className={cn(css.container, className)}>
                {profileMenu}
                {views}
                {favorites}
                {workspaces}
            </div>
        </SidebarContext.Provider>;
}
export { ProductUISidebar, ProfileMenu, SideBarFavorites, SideBarSection };