import { Checkmark } from '@doist/icons/product-ui';
import { Text } from '@doist/marketist';
import cn from 'clsx';
import { ProductUISkeletonText } from '../product-ui-skeleton-text/product-ui-skeleton-text';
import css from './product-ui-list-item.module.css';
import type { ReactElement } from 'react';
type RoleListItemProps = {
  id?: string;
  icon?: ReactElement;
  label?: string;
  subtitle?: string;
  hovered?: boolean;
  checked?: boolean;
};
function ProductUIRoleListItem({
  id,
  label = '',
  subtitle,
  hovered = false,
  checked = false,
  icon
}: RoleListItemProps) {
  return <div id={id} className={cn(css.container, css.roleType, {
    [css.hovered]: hovered
  })} data-sentry-component="ProductUIRoleListItem" data-sentry-source-file="product-ui-role-list-item.tsx">
            {icon ? <div className={cn(css.left, css.icon)}>{icon}</div> : null}
            <div className={css.middle}>
                <div className={css.stack}>
                    <Text size="minor-xs" data-sentry-element="Text" data-sentry-source-file="product-ui-role-list-item.tsx">{label}</Text>
                    {subtitle ? <Text size="body-2xs" className={css.subtitle}>
                            {subtitle}
                        </Text> : <>
                            <ProductUISkeletonText width="100" />
                            <ProductUISkeletonText width="80" />
                        </>}
                </div>
            </div>
            <div className={css.right}>
                {checked ? <Checkmark width="24" height="24" color="var(--productui-display-color)" /> : null}
            </div>
        </div>;
}
export { ProductUIRoleListItem };