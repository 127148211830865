import type { NextApiRequest, NextApiResponse } from 'next'

const BLOCKED_METHODS = ['GET', 'OPTIONS', 'HEAD']

const CSRFKEY = 'x-csrf'
const CSRFVAL = 'true'

// Include this header in your request to pass validation
export const CSRFHEADER = { [CSRFKEY]: CSRFVAL }

export function withCSRFValidation(
    handler: (request: NextApiRequest, response: NextApiResponse) => Promise<void>,
) {
    return async function (req: NextApiRequest, res: NextApiResponse) {
        if (BLOCKED_METHODS.includes(req.method || '')) {
            res.setHeader('Allow', 'POST')
            res.status(405).send({})
            return
        }

        if (req.headers[CSRFKEY] !== CSRFVAL) {
            return res.status(403).send(new Error('CSRF error'))
        }

        return handler(req, res)
    }
}
