const Currencies = [
  "usd",
  "aud",
  "cad",
  "gbp",
  "eur",
  "jpy",
  "dkk",
  "sek",
  "nok",
  "pln",
  "czk",
  "inr",
  "chf"
];
const LegacyCurrencies = ["brl", "rub"];
function getCurrencyNotations(currency) {
  if (!isSupportedCurrency(currency)) {
    throw new Error(`Unsupported currency: ${currency}`);
  }
  return CurrencySymbolMap[currency];
}
function isSupportedCurrency(currency) {
  return Currencies.includes(currency);
}
const CurrencySymbolMap = {
  usd: {
    // United States Dollar
    shortName: "US",
    symbol: "US$",
    shortSymbol: "$"
  },
  aud: {
    // Australian Dollar
    shortName: "A",
    symbol: "A$",
    shortSymbol: "$"
  },
  cad: {
    // Canadian Dollar
    shortName: "C",
    symbol: "C$",
    shortSymbol: "$"
  },
  gbp: {
    // British Pound
    shortName: "",
    symbol: "£",
    shortSymbol: "£"
  },
  eur: {
    // Euro
    shortName: "",
    symbol: "€",
    shortSymbol: "€"
  },
  jpy: {
    // Japanese Yen
    shortName: "",
    symbol: "¥",
    shortSymbol: "¥"
  },
  dkk: {
    // Danish Krone
    shortName: "",
    symbol: "kr",
    shortSymbol: "kr"
  },
  sek: {
    // Swedish Krona
    shortName: "",
    symbol: "kr",
    shortSymbol: "kr"
  },
  nok: {
    // Norwegian Krone
    shortName: "",
    symbol: "kr",
    shortSymbol: "kr"
  },
  pln: {
    // Polish Zloty
    shortName: "",
    symbol: "zł",
    shortSymbol: "zł"
  },
  czk: {
    // Czech Koruna
    shortName: "",
    symbol: "Kč",
    shortSymbol: "Kč"
  },
  inr: {
    // Indian Rupee
    shortName: "",
    symbol: "₹",
    shortSymbol: "₹"
  },
  chf: {
    // Swiss Franc
    shortName: "",
    symbol: "Fr",
    shortSymbol: "Fr"
  },
  /** Legacy prices. They were deprecated but some user still have them. */
  brl: {
    // Brazilian Real
    shortName: "Brazilian Real",
    symbol: "R$",
    shortSymbol: "R$"
  },
  rub: {
    // Russian Ruble
    shortName: "",
    symbol: "₽",
    shortSymbol: "₽"
  }
};
function getPriceAmount(originalAmountInCents, currency) {
  return originalAmountInCents / (currency === "jpy" ? 1 : 100);
}
function calculateYearlyPricePerMonth(yearlyAmount) {
  return yearlyAmount / 12;
}
function priceToFixed(price) {
  if (Number.isInteger(price)) {
    return price.toFixed(0);
  }
  return price.toFixed(2);
}
function getCurrencyForCountry(country) {
  const currency = countryToCurrency[country.toUpperCase()];
  if (currency && isSupportedCurrency(currency)) {
    return currency;
  }
  return "usd";
}
const countryToCurrency = {
  AF: "usd",
  AL: "usd",
  DZ: "dzd",
  AS: "usd",
  AD: "eur",
  AO: "usd",
  AI: "usd",
  AQ: "usd",
  AG: "xcd",
  AR: "ars",
  AM: "amd",
  AW: "awg",
  AC: "usd",
  AU: "aud",
  AT: "eur",
  AZ: "usd",
  BS: "usd",
  BH: "bhd",
  BD: "bdt",
  BB: "bbd",
  BY: "usd",
  BE: "eur",
  BZ: "bzd",
  BJ: "usd",
  BM: "usd",
  BT: "btn",
  BO: "usd",
  BA: "usd",
  BW: "bwp",
  BV: "usd",
  BR: "brl",
  IO: "usd",
  BN: "bnd",
  BG: "bgn",
  BF: "usd",
  BI: "usd",
  KH: "usd",
  CM: "xaf",
  CA: "cad",
  CV: "usd",
  KY: "usd",
  CF: "usd",
  TD: "usd",
  CL: "usd",
  CN: "cny",
  CX: "usd",
  CC: "usd",
  CO: "cop",
  KM: "usd",
  CG: "usd",
  CD: "usd",
  CK: "nzd",
  CR: "crc",
  CI: "xof",
  HR: "hrk",
  CU: "usd",
  CY: "eur",
  CZ: "czk",
  DK: "dkk",
  DJ: "usd",
  DM: "xcd",
  DO: "dop",
  TP: "usd",
  EC: "usd",
  EG: "egp",
  SV: "usd",
  GQ: "usd",
  ER: "ern",
  EE: "eur",
  ET: "etb",
  FK: "fpk",
  FO: "dkk",
  FJ: "fjd",
  FI: "eur",
  FR: "eur",
  GF: "eur",
  PF: "usd",
  TF: "eur",
  GA: "usd",
  GM: "gmd",
  GE: "usd",
  DE: "eur",
  GH: "ghs",
  GI: "gbp",
  GR: "eur",
  GL: "dkk",
  GD: "xcd",
  GP: "usd",
  GU: "usd",
  GT: "gtq",
  GG: "gbp",
  GN: "usd",
  GW: "usd",
  GY: "gyd",
  HT: "usd",
  HM: "usd",
  HN: "hnl",
  HK: "hkd",
  HU: "huf",
  IS: "isk",
  IN: "inr",
  ID: "idr",
  IR: "irr",
  IQ: "usd",
  IE: "eur",
  IM: "gbp",
  IL: "ils",
  IT: "eur",
  JM: "jmd",
  JP: "jpy",
  JE: "gbp",
  JO: "jod",
  KZ: "usd",
  KE: "kes",
  KI: "usd",
  KP: "usd",
  KR: "usd",
  KW: "kwd",
  KG: "usd",
  LA: "usd",
  LV: "usd",
  LB: "lbp",
  LS: "zar",
  LR: "usd",
  LY: "usd",
  LI: "chf",
  LT: "usd",
  LU: "eur",
  MO: "mop",
  MK: "mkd",
  MG: "usd",
  MW: "mwk",
  MY: "myr",
  MV: "usd",
  ML: "usd",
  MT: "eur",
  MH: "usd",
  MQ: "eur",
  MR: "usd",
  MU: "mur",
  YT: "eur",
  MX: "mxn",
  FM: "usd",
  MD: "usd",
  MC: "eur",
  MN: "usd",
  MS: "xcd",
  MA: "mad",
  MZ: "usd",
  MM: "usd",
  NA: "nad",
  NR: "aud",
  NP: "npr",
  NL: "eur",
  AN: "ang",
  NC: "usd",
  NZ: "nzd",
  NI: "nio",
  NE: "usd",
  NG: "ngn",
  NU: "nzd",
  NF: "aud",
  MP: "usd",
  NO: "nok",
  OM: "omr",
  PK: "pkr",
  PW: "usd",
  PS: "usd",
  PA: "pab",
  PG: "pgk",
  PY: "pyg",
  PE: "usd",
  PH: "php",
  PN: "usd",
  PL: "pln",
  PT: "eur",
  PR: "usd",
  QA: "qar",
  RE: "usd",
  RO: "usd",
  /**
   * Will fallback to usd
   * see: https://twist.com/a/1585/ch/266980/t/3289585/
   */
  // RU: 'RUB',
  RW: "usd",
  KN: "xcd",
  LC: "xcd",
  VC: "xcd",
  WS: "wst",
  SM: "eur",
  ST: "usd",
  SA: "sar",
  SN: "usd",
  CS: "eur",
  SC: "usd",
  SL: "usd",
  SG: "sgd",
  SK: "usd",
  SI: "eur",
  SB: "sbd",
  SO: "usd",
  ZA: "zar",
  GS: "usd",
  ES: "eur",
  LK: "lkr",
  SH: "usd",
  PM: "usd",
  SD: "usd",
  SR: "usd",
  SJ: "usd",
  SZ: "szl",
  SE: "sek",
  CH: "chf",
  SY: "syp",
  TW: "twd",
  TJ: "usd",
  TZ: "tzs",
  TH: "thb",
  TG: "usd",
  TK: "usd",
  TO: "top",
  TT: "ttd",
  TN: "tnd",
  TR: "usd",
  TM: "usd",
  TC: "usd",
  TV: "usd",
  UM: "usd",
  UG: "ugx",
  UA: "usd",
  AE: "aed",
  UK: "gbp",
  US: "usd",
  UY: "usd",
  UZ: "usd",
  VU: "vuv",
  VA: "usd",
  VE: "veb",
  VN: "usd",
  VG: "usd",
  VI: "usd",
  WF: "usd",
  EH: "usd",
  YE: "usd",
  ZM: "usd",
  ZW: "usd"
};
export {
  Currencies,
  LegacyCurrencies,
  calculateYearlyPricePerMonth,
  getCurrencyForCountry,
  getCurrencyNotations,
  getPriceAmount,
  isSupportedCurrency,
  priceToFixed
};
