import { Text } from '@doist/marketist';
import cn from 'clsx';
import { ProductUISkeletonText } from '../product-ui-skeleton-text/product-ui-skeleton-text';
import css from './product-ui-list-item.module.css';
import type { ReactNode } from 'react';
import type { ProductUISkeletonWidth } from '../product-ui-types';
type ActivityListItemProps = {
  id?: string;
  label?: ReactNode;
  subLabel?: string;
  hovered?: boolean;
  abstracted?: boolean;
  skeletonWidth?: ProductUISkeletonWidth;
  avatar: ReactNode;
};
function ProductUIActivityListItem({
  id,
  label = '',
  subLabel = '',
  hovered = false,
  abstracted = false,
  skeletonWidth = '100',
  avatar = null
}: ActivityListItemProps) {
  return <div id={id} className={cn(css.container, css.activityType, {
    [css.hovered]: hovered
  })} data-sentry-component="ProductUIActivityListItem" data-sentry-source-file="product-ui-activity-list-item.tsx">
            <div className={css.left}>
                {avatar ? avatar : null}
                {abstracted ? <div className={css.abstract}>
                        <ProductUISkeletonText width={skeletonWidth} />
                    </div> : <div className={cn([css.stack, css.row])}>
                        <Text size="minor-base">{label}</Text>
                        {subLabel ? <Text className={css.subLabel} size="minor-base">
                                {subLabel}
                            </Text> : null}
                    </div>}
            </div>
        </div>;
}
export { ProductUIActivityListItem };