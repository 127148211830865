import { createContext, useContext, useEffect, useReducer, useRef } from 'react';
import { Pause, Play, Repeat } from '@doist/icons/brand';
import { Box, OverlayButton } from '@doist/marketist';
import cn from 'clsx';
import { gsap } from 'gsap';
import { CustomEase } from 'gsap/dist/CustomEase';
import { Flip } from 'gsap/dist/Flip';
import { MotionPathPlugin } from 'gsap/dist/MotionPathPlugin';
import { TextPlugin } from 'gsap/dist/TextPlugin';
import { useTranslation } from 'next-i18next';
import css from './product-ui-context.module.css';
type ProductUIContext = {
  timeline: gsap.core.Timeline | null;
  scope: React.RefObject<HTMLDivElement>;
};
gsap.registerPlugin(TextPlugin, MotionPathPlugin, Flip, CustomEase);
const productUIContext = createContext<ProductUIContext | undefined>(undefined);
type ReducerState = {
  timeline: gsap.core.Timeline | null;
  innerTimeline: gsap.core.Timeline | null;
  isComplete: boolean;
  isPaused: boolean;
  rerenderKey: number;
};
type ReducerAction = {
  type: 'createTimeline';
  timeline: gsap.core.Timeline;
} | {
  type: 'createInnerTimeline';
  timeline: gsap.core.Timeline;
} | {
  type: 'rerenderSequence';
} | {
  type: 'markComplete';
} | {
  type: 'markIncomplete';
} | {
  type: 'pause';
} | {
  type: 'play';
};
const sequenceState: ReducerState = {
  timeline: null,
  innerTimeline: null,
  isComplete: false,
  isPaused: true,
  rerenderKey: 1
};
function reducer(state: ReducerState, action: ReducerAction) {
  switch (action.type) {
    case 'createTimeline':
      return {
        ...state,
        timeline: action.timeline
      };
    case 'createInnerTimeline':
      return {
        ...state,
        innerTimeline: action.timeline
      };
    case 'rerenderSequence':
      return {
        ...state,
        rerenderKey: state.rerenderKey + 1
      };
    case 'markComplete':
      return {
        ...state,
        isComplete: true
      };
    case 'markIncomplete':
      return {
        ...state,
        isComplete: false
      };
    case 'pause':
      state.timeline?.pause();
      return {
        ...state,
        isPaused: true
      };
    case 'play':
      state.timeline?.play();
      return {
        ...state,
        isPaused: false
      };
    default:
      return state;
  }
}
export function ProductUIContextProvider({
  children
}: {
  children: React.ReactNode;
}) {
  const {
    t
  } = useTranslation('productui');
  const scope = useRef<HTMLDivElement>(null);
  const [state, dispatch] = useReducer(reducer, sequenceState);
  function createTimelines() {
    /**
     * Sequences use two timelines - a main "timeline" and an "internal timeline".
     *
     * The main timeline controls the overall animations within our scene.
     *
     * The "internal timeline", on the other hand, is used for animating individual elements within the scene. This allows us to manage
     * complex animations on a per-element basis, independently of the main timeline.
     *
     * This two-tiered approach gives us control over playback. We can pause, resume, or manipulate the main timeline without affecting the
     * progress of the internal timeline, and vice versa.
     *
     * It's important to note that we are not using GSAP's global timeline (`gsap.globalTimeline`) for this. The global timeline affects all GSAP
     * animations on the page, and using it would mean stopping all sequences whenever we pause or resume our animation. By using our own,
     * scene-specific timelines, we avoid this issue and ensure that only the relevant animations are controlled.
     */
    const itl = gsap.timeline();
    const tl = gsap.timeline({
      paused: true,
      onComplete() {
        dispatch({
          type: 'markComplete'
        });
      }
    });
    tl.add(itl);
    dispatch({
      type: 'createTimeline',
      timeline: tl
    });
    dispatch({
      type: 'createInnerTimeline',
      timeline: itl
    });
  }
  function reset() {
    // In order to reset all the styling changes applied using GSAP and to restart the animation,
    // we need to create a new timeline and rerender the sequence.
    createTimelines();
    dispatch({
      type: 'markIncomplete'
    });
    dispatch({
      type: 'rerenderSequence'
    }); // force a rerender
  }
  useEffect(() => {
    const ctx = gsap.context(() => {
      createTimelines();
    }, scope);
    return () => {
      ctx.revert();
    };
  }, []);
  useEffect(() => {
    const el = scope;
    function onIntersectionHandler(entries: IntersectionObserverEntry[]) {
      // Very quick changes may be grouped, so we're only interested in the last entry of this cycle
      const entry = entries[entries.length - 1];
      if (entry.isIntersecting) {
        if (state.timeline?.paused()) {
          dispatch({
            type: 'play'
          });
        }
      } else {
        if (!state.timeline?.paused()) {
          dispatch({
            type: 'pause'
          });
        }
      }
    }
    const observer = new IntersectionObserver(onIntersectionHandler, {
      root: null,
      threshold: 0.8
    });
    if (scope.current) {
      observer.observe(scope.current);
    }
    return () => {
      if (el.current) {
        observer.disconnect();
        observer.unobserve(el.current);
      }
    };
  }, [state.timeline]);
  function onClickHandler() {
    if (!state.timeline) return;
    dispatch({
      type: state.isPaused ? 'play' : 'pause'
    });
  }
  return <productUIContext.Provider value={{
    timeline: state.innerTimeline,
    scope
  }} data-sentry-element="unknown" data-sentry-component="ProductUIContextProvider" data-sentry-source-file="product-ui-context.tsx">
            <Box className={cn(css.container, {
      [css.completed]: state.isComplete
    })} ref={scope} data-sentry-element="Box" data-sentry-source-file="product-ui-context.tsx">
                <Box key={state.rerenderKey} data-sentry-element="Box" data-sentry-source-file="product-ui-context.tsx">{children}</Box>
                <Box className={css.controls} data-sentry-element="Box" data-sentry-source-file="product-ui-context.tsx">
                    {state.isComplete ? <OverlayButton startIcon={Repeat} onClick={reset}>
                            {t('replay')}
                        </OverlayButton> : <OverlayButton startIcon={state.isPaused ? Play : Pause} onClick={onClickHandler}>
                            {state.isPaused ? t('play') : t('pause')}
                        </OverlayButton>}
                </Box>
            </Box>
        </productUIContext.Provider>;
}
export function useProductUIContext() {
  const ctx = useContext(productUIContext);
  if (!ctx) {
    throw new Error('useProductUIContext must be used within a ProductUIContextProvider');
  }
  return ctx;
}